import { StyleSheet, View } from "react-native";

import { AppText } from "./AppText";
import { ThemeColor } from "../../theme";
import { ErrorFillIcon } from "../icons/ErrorFillIcon";

export const UserNotFoundChip = () => {
  return (
    <View style={styles.container}>
      <ErrorFillIcon />
      <View style={{ flexDirection: "column", gap: 4 }}>
        <AppText weight={600}>Пользователь не найден</AppText>
        <AppText size={12}>Обратитесь к официанту</AppText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    borderRadius: 20,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: ThemeColor.RED_TRANSPARENT,
    backgroundColor: ThemeColor.RED_TRANSPARENT_2,
    alignItems: "center",
    marginBottom: 8,
    display: "flex",
    flexDirection: "row",
    gap: 10,
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
});
