import { useEffect } from "react";
import { StyleSheet, View } from "react-native";

import { FeedbackBlock } from "../blocks/FeedbackBlock";
import TipsBlock from "../blocks/TipsBlock";
import { WaiterBlock } from "../blocks/WaiterBlock";
import { useAppDispatch, useAppSelector } from "../store";
import { setProductsSum } from "../store/orderSlice";

export const TipsScreen = () => {
  const { tips, tipsValues, total, waiterName, fake, modelService } =
    useAppSelector((state) => state.order);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setProductsSum(0));
  }, []);
  return (
    <View style={styles.container}>
      {waiterName && <WaiterBlock />}
      {modelService !== "TipsInPays" && (
        <TipsBlock
          totalSum={total}
          initialTip={fake ? tipsValues[2] : tips[2]}
          tips={fake ? tipsValues : tips}
        />
      )}
      <FeedbackBlock title="Оценить обслуживание" />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
