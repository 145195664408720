import { useEffect, useRef, useState } from "react";
import { TextInput, View } from "react-native";
import { MaskedText } from "react-native-mask-text";

import { TipsBlockProps, styles } from "./TipsBlock";
import { Section } from "../../components/ui/Section";
import { SectionHeader } from "../../components/ui/SectionHeader";
import { SpecialButton } from "../../components/ui/SpecialButton";
import { TipsButton } from "../../components/ui/TipsButton";
import { useAppDispatch, useAppSelector } from "../../store";
import esApi from "../../store/esApi";
import { setTipsSum } from "../../store/orderSlice";
import { currencyFormat } from "../../utils/currency-format";
import { useHelper } from "../../utils/useHelper";

function Default({ totalSum, initialTip = null, tips }: TipsBlockProps) {
  const { getSearch } = useHelper();

  const [updateTips, { isLoading }] = esApi.useUpdateTipsMutation();

  const dispatch = useAppDispatch();

  const [activeTip, setActiveTip] = useState<number>(
    initialTip ? initialTip : tips[0]
  );

  const { tipsSum } = useAppSelector((state) => state.order);

  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState("");

  const inputRef = useRef<TextInput>(null);

  const calculateTips = (percent: number, sum: number): number => {
    return Math.ceil((sum / 100) * percent);
  };

  const handlePercentChange = (percent: number): void => {
    setActiveTip(percent);
    let value = calculateTips(percent, totalSum);
    if (value < 0) {
      value = 0;
    }

    updateTips({
      percentage: true,
      value: percent,
      order: getSearch(),
    });

    dispatch(setTipsSum(value));
  };

  const handleSubmitText = () => {
    let value = parseInt(editValue ? editValue : "0", 10);
    if (value < 0) {
      value = 0;
    }
    dispatch(setTipsSum(value));
    setIsEditing(false);
    updateTips({
      percentage: false,
      value,
      order: getSearch(),
    });
  };

  const handleEditOnPress = () => {
    if (isEditing) {
      handleSubmitText();
    } else {
      setIsEditing(true);
      setEditValue("");
      setActiveTip(0);
    }
  };

  useEffect(() => {
    const value = calculateTips(activeTip, totalSum);

    dispatch(setTipsSum(value));
  }, [totalSum]);

  useEffect(() => {
    let value = calculateTips(activeTip, totalSum);
    if (value < 0) {
      value = 0;
    }

    updateTips({
      percentage: true,
      value: activeTip,
      order: getSearch(),
    });

    dispatch(setTipsSum(value));
  }, []);

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  return (
    <Section style={styles.section}>
      <SectionHeader
        src={require("../../../assets/img/cup.svg")}
        title="Чаевые"
        text={
          totalSum > 0
            ? `От суммы ${currencyFormat(totalSum)}`
            : "Отблагодарить сотрудника"
        }
      />
      <View style={styles.editBox}>
        {isEditing ? (
          <TextInput
            value={editValue}
            ref={inputRef}
            style={styles.editInput}
            keyboardType="numeric"
            inputMode="numeric"
            maxLength={9}
            onChangeText={(text) => setEditValue(text)}
            onBlur={handleSubmitText}
          />
        ) : (
          <MaskedText
            type="currency"
            options={{
              suffix: " ₽",
              groupSeparator: " ",
            }}
            style={styles.editInput}
          >
            {tipsSum.toString()}
          </MaskedText>
        )}
        <SpecialButton
          type="edit"
          onPress={handleEditOnPress}
          disabled={isLoading ?? false}
        />
      </View>
      <View style={styles.buttonsBox}>
        {tips?.map((percent) => (
          <TipsButton
            key={percent}
            percent={percent}
            value={calculateTips(percent, totalSum)}
            active={percent === activeTip}
            disabled={isLoading ?? false}
            onPress={(active) => {
              handlePercentChange(active ? percent : 0);
            }}
          />
        ))}
      </View>
    </Section>
  );
}

export default Default;
