export const useHelper = () => {
  return {
    getCookie,
    setCookie,
    getSearch,
    updateJWT,
    resetJWT,
    retrieveJWT,
    getObjectSearch,
  };
};

export function getObjectSearch(): URLSearchParams {
  const search = window.location.search.replace(/,/g, "&");
  return new URLSearchParams(search);
}

export function retrieveJWT() {
  // If client has cookie with key jwt-(uuid of currentOrder) set it to query
  const params = getObjectSearch();
  if (!params.has("jwt") && !params.has("old-jwt")) {
    if (
      params.has("u") &&
      getCookie("jwt-" + encodeURIComponent(params.get("u") || ""))
    ) {
      params.set(
        "jwt",
        getCookie("jwt-" + encodeURIComponent(params.get("u") || "")) || ""
      );
    } else if (
      params.has("r") &&
      params.has("t") &&
      params.has("n") &&
      getCookie("jwt-" + params.get("r") + params.get("t") + params.get("n"))
    ) {
      params.set(
        "jwt",
        getCookie(
          "jwt-" +
            (params.get("r") || "") +
            (params.get("t") || "") +
            (params.get("n") || "")
        ) || ""
      );
    }
    window.history.replaceState(null, "", "?" + params.toString());
  }
}

export function setCookie(
  name: string,
  value: string,
  options: Record<string, string | Date | boolean | number> = {}
) {
  options = {
    path: "/",
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (const optionKey in options) {
    updatedCookie += "; " + optionKey;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

export function getCookie(name: string) {
  const matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        // eslint-disable-next-line no-useless-escape
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function deleteCookie(name: string) {
  setCookie(name, "", {
    "max-age": -1,
  });
}

export function getSearch(): string {
  const session =
    getCookie("crd_session") !== undefined
      ? "&session=" + getCookie("crd_session")
      : "";

  const card =
    getCookie("crd_last") !== undefined ? "&card=" + getCookie("crd_last") : "";

  return "?" + getObjectSearch().toString() + session + card;
}

export function updateJWT(jwt: string, uuid: string) {
  const params = getObjectSearch();
  params.set("jwt", encodeURIComponent(jwt));
  setCookie(`jwt-${uuid}`, jwt, { secure: true, "max-age": 36000 });
  window.history.replaceState(null, "", "?" + params.toString());
}

export function resetJWT() {
  const params = getObjectSearch();
  const search = getObjectSearch();

  if (search.has("jwt")) {
    search.set("old-jwt", search.get("jwt") as string);
  }

  if (
    params.has("u") &&
    getCookie("jwt-" + encodeURIComponent(params.get("u") || ""))
  ) {
    deleteCookie("jwt-" + encodeURIComponent(params.get("u") || ""));
  } else if (
    params.has("r") &&
    params.has("t") &&
    params.has("n") &&
    getCookie("jwt-" + params.get("r") + params.get("t") + params.get("n"))
  ) {
    deleteCookie(
      "jwt-" +
        (params.get("r") || "") +
        (params.get("t") || "") +
        (params.get("n") || "")
    );
  }

  search.delete("jwt");
  window.history.replaceState(null, "", "?" + search.toString());
}
