import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import esApi from "./esApi";
import {
  OrderSession,
  checkLoyaltyResponse,
  LoyalSystem,
} from "../types/common";

interface PaymentSlice {
  totalSum: number;
  payUrl: string | null;
  saveCard: boolean;
  lastBankUsed?: string;
  tipsPercentage: boolean;
  tipsValue: number;
  takeServiceFee: boolean;
  guestAllUuid?: string | null;
  sessionExists: boolean;
  loyaltyLogin: boolean;
  useBonuses: boolean;
  found: boolean;
  balance: number;
  maxAmount: number;
  loyaltyCreds: {
    [K in LoyalSystem]: K extends "UDS"
      ? { id: string }
      : Record<string, string>;
  };
}

export const initialState: PaymentSlice = {
  totalSum: 0,
  tipsValue: 0,
  payUrl: null,
  saveCard: true,
  tipsPercentage: false,
  takeServiceFee: true,
  guestAllUuid: null,
  sessionExists: false,
  loyaltyLogin: false,
  useBonuses: true,
  found: false,
  balance: 0,
  maxAmount: 0,
  loyaltyCreds: {
    UDS: {
      id: "",
    },
    PrimeHill: {},
    PremiumBonus: {},
    LRG: {},
    Novikov: {},
  },
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setTotalSum: (state, { payload }: PayloadAction<number>) => {
      state.totalSum = payload;
    },
    setSaveCard: (state, { payload }: PayloadAction<boolean>) => {
      state.saveCard = payload;
    },

    setSeviceFee: (state, { payload }: PayloadAction<boolean>) => {
      state.takeServiceFee = payload;
    },

    setLastBankUsed: (state, { payload }: PayloadAction<string>) => {
      state.lastBankUsed = payload;
    },

    setUseBonuses: (state, { payload }: PayloadAction<boolean>) => {
      state.useBonuses = payload;
    },

    resetLoyalty: (state) => {
      state.useBonuses = true;
      state.found = false;
      state.balance = 0;
      state.maxAmount = 0;
      state.loyaltyLogin = false;
    },

    setGuestAllUuid: (state, { payload }: PayloadAction<string | null>) => {
      state.guestAllUuid = payload;
    },

    setLoyaltyLogin: (state, { payload }: PayloadAction<boolean>) => {
      state.loyaltyLogin = payload;
    },

    setLoyaltyCredentials: (
      state,
      {
        payload,
      }: PayloadAction<
        | { loyalty: LoyalSystem; id: string; rest: number }
        | { loyalty: "UDS"; id: string }
      >
    ) => {
      if (payload.loyalty === "UDS") {
        state.loyaltyCreds["UDS"] = { id: payload.id };
      } else {
        state.loyaltyCreds[payload.loyalty][payload.rest] = payload.id;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      esApi.endpoints.updateTips.matchFulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<{
          percentage: boolean;
          value: number;
        }>
      ) => {
        state.tipsPercentage = payload.percentage;
        state.tipsValue = payload.value;
      }
    );
    builder.addMatcher(
      esApi.endpoints.getOrder.matchFulfilled,
      (state, { payload }: PayloadAction<OrderSession>) => {
        state.sessionExists = payload.sessionExists;
        state.guestAllUuid = payload.order.guestPayAllUuid;
      }
    );
    builder.addMatcher(
      esApi.endpoints.checkLoyalty.matchFulfilled,
      (state, { payload }: PayloadAction<checkLoyaltyResponse>) => {
        state.balance = payload.balance;
        state.maxAmount = payload.maxAmount;
        state.found = payload.found;
        state.useBonuses = payload.maxAmount > 0;
      }
    );
  },
});

export const {
  setTotalSum,
  setSaveCard,
  setSeviceFee,
  setGuestAllUuid,
  setUseBonuses,
  resetLoyalty,
  setLoyaltyLogin,
  setLastBankUsed,
  setLoyaltyCredentials,
} = paymentSlice.actions;

export default paymentSlice.reducer;
