import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import esApi from "./esApi";
import { GetMenuResponse, MenuCategory } from "../types/common";

interface MenuSlice {
  searchText: string | null;
  categories: MenuCategory[];
}

export const initialState: MenuSlice = {
  searchText: null,
  categories: [],
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setSearchText: (state, { payload }: PayloadAction<string>) => {
      state.searchText = payload;
    },
    setCategories: (state, { payload }: PayloadAction<MenuCategory[]>) => {
      state.categories = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      esApi.endpoints.getMenu.matchFulfilled,
      (state, { payload }: PayloadAction<GetMenuResponse>) => {
        if (payload.length > 0) {
          payload[0].isActive = true;
        }
        state.categories = payload;
      }
    );
  },
});

export const { setSearchText, setCategories } = menuSlice.actions;

export default menuSlice.reducer;
