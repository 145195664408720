export const currencyFormat = (
  sum: number,
  thousandSeparator = "\u00A0",
  needSpace = true
): string => {
  const searchValue = /(\d)(?=(\d\d\d)+(\D|$))/g;
  const replaceValue = `$1${thousandSeparator}`;
  const spaceSymbol = needSpace ? "\u00A0" : "";

  return (
    Math.ceil(sum).toString().replace(searchValue, replaceValue) +
    spaceSymbol +
    "₽"
  );
};
