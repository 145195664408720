import { useRef, useState } from "react";
import { Image, StyleSheet, TextInput, View, ViewStyle } from "react-native";

import { CloseButton } from "./CloseButton";
import { ThemeColor, ThemeFont } from "../../theme";

type Props = {
  style?: ViewStyle;
  placeholder?: string;
  onChangeText?: (text: string) => void;
};

export const SearchInput = ({ style, placeholder, onChangeText }: Props) => {
  const [query, setQuery] = useState("");
  const inputRef = useRef<TextInput>(null);

  const handleChangeText = (text: string) => {
    setQuery(text);
    onChangeText && onChangeText(text);
  };
  const handleClearText = () => {
    (inputRef.current as TextInput).clear();
    handleChangeText("");
  };

  return (
    <View style={[styles.container, style]}>
      <Image
        style={styles.icon}
        source={require("../../../assets/img/search.svg")}
      />
      <TextInput
        ref={inputRef}
        style={styles.input}
        onChangeText={handleChangeText}
        placeholder={placeholder}
        placeholderTextColor={ThemeColor.GREY_4}
        keyboardType="default"
      />
      {!!query && <CloseButton onPress={handleClearText} />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 45,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 20,
    backgroundColor: ThemeColor.GREY,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: ThemeColor.GREY_3,
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: 12,
  },
  input: {
    flex: 1,
    height: 19,
    fontFamily: ThemeFont.REGULAR,
    color: ThemeColor.BLACK,
    fontSize: 14,
    lineHeight: 21,
    outlineStyle: "none",
  },
});
