import {
  ActivityIndicator,
  Image,
  ImageSourcePropType,
  StyleSheet,
  View,
} from "react-native";

import { AppText } from "./AppText";
import { ThemeColor } from "../../theme";

type Props = {
  icon: ImageSourcePropType;
  title: string;
  subtitle: string;
  isLoading?: boolean;
};

export const PaymentButtonContent = ({
  icon,
  title,
  subtitle,
  isLoading,
}: Props) => {
  return (
    <View>
      {isLoading ? (
        <ActivityIndicator
          style={styles.image}
          size={18}
          color={ThemeColor.BLACK}
        />
      ) : (
        <Image style={styles.image} source={icon} resizeMode="contain" />
      )}
      <AppText style={styles.title} weight={600} size={16} height={24}>
        {title}
      </AppText>
      <AppText weight={400} height={21}>
        {subtitle}
      </AppText>
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    width: 24,
    height: 24,
    marginBottom: 20,
  },
  title: {
    marginBottom: 20,
  },
});
