import { Image, ImageSourcePropType, StyleSheet, View } from "react-native";

import { ThemeColor } from "../../theme";

type Props = {
  src: ImageSourcePropType;
  style?: StyleSheet.NamedStyles<unknown>;
};

export const ImageBox = ({ src, style }: Props) => (
  <View style={[styles.container, style]}>
    <Image style={styles.image} source={src} />
  </View>
);

const styles = StyleSheet.create({
  container: {
    width: 36,
    height: 42,
    borderRadius: 12,
    backgroundColor: ThemeColor.PINK_WHITE,
    marginRight: 12,
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: 17,
    height: 17,
  },
});
