import { useEffect, useState } from "react";
import {
  Image,
  Linking,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";

import Loyalty from "./Loyalty";
import { AppButton } from "./ui/AppButton";
import { AppText } from "./ui/AppText";
import { CheckBox } from "./ui/CheckBox";
import { ImageBox } from "./ui/ImageBox";
import { NewCardButton } from "./ui/NewCardButton";
import { PaymentButton } from "./ui/PaymentButton";
import { PaymentButtonContent } from "./ui/PaymentButtonContent";
import { TotalCheckModal } from "./ui/TotalCheckModal";
import { useAppDispatch, useAppSelector } from "../store";
import { filterAndDivideSize } from "../store/orderHelpers";
import {
  setSeviceFee as setStoreServiceFee,
  setTotalSum,
} from "../store/paymentSlice";
import { ThemeColor } from "../theme";
import { Product } from "../types/common";
import { currencyFormat } from "../utils/currency-format";
import { getCookie, getObjectSearch } from "../utils/useHelper";
import { usePayBySavedCard } from "../utils/usePay";

type Props = {
  onChoose: (shoose: "card" | "sbp") => void; // TODO: temp
};

export const PaymentTypeChoose = ({ onChoose }: Props) => {
  const dispatch = useAppDispatch();

  const {
    productsSum,
    tipsSum,
    fee,
    tipsFee,
    maxService,
    loyalSystem,
    discount,
    guestProducts,
    currentGuest,
    sharedProducts,
  } = useAppSelector((state) => state.order);

  const {
    takeServiceFee,
    totalSum,
    sessionExists,
    maxAmount,
    useBonuses,
    found,
  } = useAppSelector((state) => state.payment);

  const { section } = useAppSelector((state) => state.app);

  const [payBySavedCard, { isLoading }] = usePayBySavedCard();

  const [userAgreementChecked, setUserAgreementChecked] = useState(true);
  const [showCheck, setShowCheck] = useState(false);
  const [serviceFee, setServiceFee] = useState(0);

  const curGuestProductIds = guestProducts
    .filter(
      ({ guestUuid, parts }) => currentGuest.uuid === guestUuid && parts > 0
    )
    .map(({ productUuid }) => productUuid);

  const curGuestProducts = curGuestProductIds
    .map((id) =>
      filterAndDivideSize(sharedProducts.flatMap(({ items }) => items)).find(
        ({ uuid }) => uuid === id
      )
    )
    .filter((x) => {
      return x !== undefined;
    }) as Product[];

  useEffect(() => {
    const sum = productsSum + tipsSum;
    const productsFee = productsSum * fee;
    const newTipsFee = tipsSum * tipsFee;
    let totalFee = productsFee + newTipsFee;
    totalFee = totalFee > maxService ? maxService : totalFee;
    const sumWithFees = takeServiceFee ? sum + totalFee : sum;
    const sumWithBonuses =
      useBonuses && found ? sumWithFees - maxAmount : sumWithFees;

    setServiceFee(totalFee);
    dispatch(setTotalSum(sumWithBonuses));
  }, [takeServiceFee, productsSum, tipsSum, useBonuses, maxAmount]);

  return (
    <ScrollView style={styles.container}>
      {productsSum > 0 && (
        <>
          <View style={styles.topButton}>
            <AppButton
              size="small"
              type="outline"
              onPress={() => setShowCheck(true)}
            >
              Смотреть чек
            </AppButton>
            <TotalCheckModal
              visible={showCheck}
              onClose={() => setShowCheck(false)}
            />
          </View>
          {loyalSystem && <Loyalty />}
        </>
      )}
      <View style={styles.header}>
        <ImageBox
          style={styles.headerImage}
          src={require("../../assets/img/coins.svg")}
        />
        <View style={{ flex: 1 }}>
          <AppText size={12} height={18}>
            Итого к оплате
          </AppText>
          <AppText weight={700} size={24} height={28}>
            {currencyFormat(totalSum)}
          </AppText>
        </View>

        {((useBonuses && maxAmount > 0) || !!discount) && productsSum > 0 && (
          <View style={{ flex: 1 }}>
            <AppText
              style={{ textDecorationLine: "line-through" }}
              weight={500}
              size={18}
              height={28}
              color={ThemeColor.GREY_4}
            >
              {currencyFormat(
                totalSum +
                  curGuestProducts.reduce(
                    (acc, product) => acc + product.discount,
                    0
                  ) +
                  (useBonuses ? maxAmount : 0)
              )}
            </AppText>
          </View>
        )}

        <TouchableOpacity
          onPress={async () => {
            await navigator.share({
              url: window.location.href.replace(/jwt=.*/, ""),
            });
          }}
        >
          <Image
            style={styles.shareBtnImg}
            source={require("../../assets/img/share.svg")}
          />
        </TouchableOpacity>
      </View>

      {userAgreementChecked && (
        <>
          <AppText weight={600} size={20} height={30}>
            Способ оплаты
          </AppText>
          <View style={styles.paymentTypesWrap}>
            <PaymentButton onPress={() => onChoose("sbp")}>
              <PaymentButtonContent
                icon={require("../../assets/img/sbp.svg")}
                title="СБП"
                subtitle="Перевод СБП"
              />
            </PaymentButton>
            {sessionExists && (
              <PaymentButton
                type="filled"
                onPress={payBySavedCard}
                disabled={isLoading}
              >
                <PaymentButtonContent
                  isLoading={isLoading}
                  icon={require("../../assets/img/card.svg")}
                  title={`**** ${
                    getCookie("crd_last") || getObjectSearch().get("card")
                  }`}
                  subtitle="Моя карта"
                />
              </PaymentButton>
            )}
            <NewCardButton
              compact={sessionExists}
              onPress={() => onChoose("card")}
            />
          </View>
        </>
      )}

      <CheckBox
        style={{ marginBottom: 22, alignItems: "center" }}
        checked={userAgreementChecked}
        onStateChange={setUserAgreementChecked}
      >
        <AppText weight={400} height={21}>
          Согласен с условиями
        </AppText>
        <AppText weight={400} height={21}>
          <AppText
            onPress={() => Linking.openURL("https://eatandsplit.com/lic1.html")}
          >
            Пользовательского соглашения
          </AppText>{" "}
          и{" "}
          <AppText
            onPress={() => Linking.openURL("https://eatandsplit.com/lic2.html")}
          >
            политикой конфиденциальности
          </AppText>
        </AppText>
      </CheckBox>

      {serviceFee > 0 && (
        <CheckBox
          style={{ alignItems: "center" }}
          checked={takeServiceFee}
          onStateChange={(value) => dispatch(setStoreServiceFee(value))}
        >
          <AppText weight={400}>
            {section === "Tips"
              ? "Соглашаюсь покрыть издержки на перевод средств сотруднику в размере "
              : "Соглашаюсь оплатить транзакционные издержки в размере "}
            <AppText>{currencyFormat(serviceFee)}</AppText>
          </AppText>
        </CheckBox>
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    paddingBottom: 40,
  },
  topButton: {
    alignItems: "center",
  },
  header: {
    paddingHorizontal: 20,
    paddingVertical: 16,
    backgroundColor: ThemeColor.WHITE,
    borderWidth: 1,
    borderColor: ThemeColor.GREY_3,
    borderRadius: 20,
    flexDirection: "row",
    alignItems: "flex-end",
    marginVertical: 40,
  },
  headerImage: {
    marginRight: 16,
    width: 41,
    height: 48,
  },
  shareBtnImg: {
    width: 16,
    height: 22,
    marginRight: 4,
  },
  paymentTypesWrap: {
    gap: 8,
    flexDirection: "row",
    marginVertical: 16,
  },

  cardLoaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
