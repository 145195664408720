import { PropsWithChildren } from "react";
import { StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";

import { ThemeColor } from "../../theme";

type Props = {
  checked: boolean;
  onStateChange: (state: boolean) => void;
  style?: ViewStyle;
};

export const CheckBox = ({
  checked,
  onStateChange,
  style,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <TouchableOpacity
      style={[styles.container, style]}
      onPress={() => onStateChange(!checked)}
    >
      {checked ? (
        <View style={[styles.tickBase, styles.tickChecked]}>
          <View style={styles.tickCheckedContent} />
        </View>
      ) : (
        <View style={[styles.tickBase, styles.tickUnchecked]} />
      )}
      <View style={styles.content}>{children}</View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  tickBase: {
    width: 16,
    height: 16,
    borderRadius: 4,
    marginTop: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  tickChecked: {
    backgroundColor: ThemeColor.BLUE,
  },
  tickCheckedContent: {
    width: 6,
    height: 6,
    borderRadius: 2,
    backgroundColor: ThemeColor.WHITE,
  },
  tickUnchecked: {
    backgroundColor: ThemeColor.GREY_2,
    borderColor: ThemeColor.GREY_3,
    borderWidth: 1,
  },
  content: {
    marginLeft: 12,
    flex: 1,
  },
});
