export enum ThemeColor {
  DARK = "#000000",
  BLACK = "#272727",
  BLACK_2 = "#090909",
  BLACK_3 = "#454545",
  BLACK_4 = "#1E1E1E",
  GREY = "#F7F7F7",
  GREY_2 = "#F3F3F3",
  GREY_3 = "#E1E1E1",
  GREY_4 = "#AAAAAA",
  GREY_5 = "#E6E6E6",
  GREY_6 = "#F0F0F0",
  SHADOW = "#6B6B6B",
  BLUE = "#0B3D6E",
  BLUE_2 = "#0D358E",
  BLUE_3 = "#2D8CFF",
  BLUE_LIGHT = "#E5ECF3",
  GREEN = "#065704",
  GREEN_LIGHT = "#BBE1BA",
  GREEN_TRANSPARENT = "#24C9521A",
  BRIGHT_GREEN = "#546700",
  BROWN = "#D0A590",
  YELLOW = "#8D7400",
  YELLOW_2 = "#EADEA6",
  YELLOW_3 = "#D8C880",
  YELLOW_TRANSPARENT = "#FFBE281A",
  YELLOW_BORDER = "#FFBE284D",
  BRIGHT_RED = "#E3CDC2",
  BRIGHT_RED_3 = "#A73800",
  RED = "#EDAB9D",
  RED_2 = "#ED5252",
  RED_TRANSPARENT = "#ED52524D",
  RED_TRANSPARENT_2 = "#ED52521A",
  PINK_BLACK = "#83026A",
  PINK = "#FF4ADD",
  PINK_STROKE = "#FFC1F3",
  PINK_WHITE = "#FFEAFB",
  WHITE = "#FFFFFF",
  TRANSPARENT = "transparent",
}

export enum ThemeFont {
  MEDIUM = "Golos-Text-medium",
  REGULAR = "Golos-Text-regular",
  SEMIBOLD = "Golos-Text-semibold",
  BOLD = "Golos-Text-bold",
  EXTRABOLD = "Golos-Text-extrabold",
}
