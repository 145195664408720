import { useState } from "react";
import { Pressable, View, StyleSheet, ViewStyle } from "react-native";

import { WaiterDrawer } from "./WaiterDrawer";
import WaiterIcon from "./icons/WaiterIcon";
import { ThemeColor } from "../theme";

const WaiterCallButton = ({ style }: { style?: ViewStyle }) => {
  const [modal, setModal] = useState(false);

  return (
    <>
      <View style={[styles.button, style]}>
        <Pressable onPress={() => setModal(true)}>
          <WaiterIcon />
        </Pressable>
      </View>
      <WaiterDrawer visible={modal} onVisibleChange={setModal} />
    </>
  );
};

export default WaiterCallButton;

const styles = StyleSheet.create({
  button: {
    bottom: 16,
    right: 16,
    position: "absolute",
    shadowColor: ThemeColor.PINK_WHITE,
    alignSelf: "flex-end",
    padding: 16,
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    backgroundColor: ThemeColor.PINK,
    zIndex: 50,
  },
});
