import { NavigationProp } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";

import { MainScreen } from "../screens/MainScreen";
import { MenuScreen } from "../screens/MenuScreen";
import { PostPaymentScreen } from "../screens/PostPaymentScreen";
import { SelectOrderScreen } from "../screens/SelectOrderScreen";
import { ThemeColor } from "../theme";

export type RootStackParamList = {
  Main: undefined;
  SelectOrder: undefined;
  PostPayment: undefined;
  Menu: undefined;
};

export type StackNavigation = NavigationProp<RootStackParamList>;

const Stack = createStackNavigator<RootStackParamList>();

export const AppNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName="Main"
      screenOptions={{
        headerShown: false,
        animationEnabled: false,
        cardStyle: {
          backgroundColor: ThemeColor.WHITE,
        },
        title: "Eat&Split - раздели и оплати счёт и чаевые онлайн",
      }}
    >
      <Stack.Screen name="Main" component={MainScreen} />
      <Stack.Screen name="SelectOrder" component={SelectOrderScreen} />
      <Stack.Screen
        name="PostPayment"
        component={PostPaymentScreen}
        options={{
          cardStyle: {
            backgroundColor: ThemeColor.GREY,
          },
        }}
      />
      <Stack.Screen name="Menu" component={MenuScreen} />
    </Stack.Navigator>
  );
};
