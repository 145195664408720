import { ImageBackground, StyleSheet, View } from "react-native";

import { ThemeColor } from "../../theme";

export const TotalCheckSeparator = () => {
  return (
    <View style={styles.container}>
      <View style={styles.semicircle}>
        <View style={[styles.semicircleBase, styles.semicircleLeft]} />
        <View style={[styles.semicircleBase, styles.semicircleLeftBg]} />
      </View>
      <View style={styles.middle}>
        <ImageBackground
          style={styles.dashedLine}
          source={require("../../../assets/img/dashed-line.svg")}
        />
      </View>
      <View style={styles.semicircle}>
        <View style={[styles.semicircleBase, styles.semicircleRight]} />
        <View style={[styles.semicircleBase, styles.semicircleRightBg]} />
      </View>
    </View>
  );
};

const SEMICIRCLE_WIDTH = 20;

const styles = StyleSheet.create({
  container: {
    height: 40,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  semicircle: {
    position: "relative",
    width: SEMICIRCLE_WIDTH,
    height: SEMICIRCLE_WIDTH * 2,
  },
  semicircleBase: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderStyle: "solid",
    position: "absolute",
  },
  semicircleLeft: {
    borderWidth: 1,
    borderColor: ThemeColor.GREY_3,
    borderLeftWidth: 0,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    zIndex: 1,
  },
  semicircleLeftBg: {
    borderWidth: 10,
    borderColor: ThemeColor.WHITE,
    borderLeftWidth: 0,
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    top: -10,
    bottom: -10,
    right: -10,
    zIndex: 0,
    boxSizing: "content-box",
  },
  semicircleRight: {
    borderWidth: 1,
    borderColor: ThemeColor.GREY_3,
    borderRightWidth: 0,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    zIndex: 1,
  },
  semicircleRightBg: {
    borderWidth: 10,
    borderColor: ThemeColor.WHITE,
    borderRightWidth: 0,
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    top: -10,
    bottom: -10,
    left: -10,
    zIndex: 0,
    boxSizing: "content-box",
  },
  middle: {
    height: 42,
    marginTop: -1,
    backgroundColor: ThemeColor.WHITE,
    flex: 1,
    justifyContent: "center",
    paddingHorizontal: 7,
  },
  dashedLine: {
    width: "100%",
    height: 2,
  },
});
