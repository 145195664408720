import { StyleSheet, View } from "react-native";
import Svg, { Rect, Path } from "react-native-svg";

type Props = {
  style?: StyleSheet.NamedStyles<unknown>;
};

export const VPNIcon = ({ style }: Props) => (
  <View style={style}>
    <Svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <Path
        d="M15.833 25C14.4441 25 13.2636 24.5139 12.2913 23.5417C11.3191 22.5694 10.833 21.3889 10.833 20C10.833 18.6111 11.3191 17.4306 12.2913 16.4583C13.2636 15.4861 14.4441 15 15.833 15C16.958 15 17.9408 15.3158 18.7813 15.9475C19.6219 16.5792 20.2086 17.3744 20.5413 18.3333H27.4997C27.958 18.3333 28.3505 18.4967 28.6772 18.8233C29.0038 19.15 29.1669 19.5422 29.1663 20C29.1663 20.5 28.9927 20.9028 28.6455 21.2083C28.2983 21.5139 27.9163 21.6667 27.4997 21.6667V23.3333C27.4997 23.7917 27.3363 24.1842 27.0097 24.5108C26.683 24.8375 26.2908 25.0006 25.833 25C25.3747 25 24.9822 24.8367 24.6555 24.51C24.3288 24.1833 24.1658 23.7911 24.1663 23.3333V21.6667H20.5413C20.208 22.625 19.6211 23.4203 18.7805 24.0525C17.94 24.6847 16.9575 25.0006 15.833 25ZM15.833 21.6667C16.2913 21.6667 16.6838 21.5033 17.0105 21.1767C17.3372 20.85 17.5002 20.4578 17.4997 20C17.4997 19.5417 17.3363 19.1492 17.0097 18.8225C16.683 18.4958 16.2908 18.3328 15.833 18.3333C15.3747 18.3333 14.9822 18.4967 14.6555 18.8233C14.3288 19.15 14.1658 19.5422 14.1663 20C14.1663 20.4583 14.3297 20.8508 14.6563 21.1775C14.983 21.5042 15.3752 21.6672 15.833 21.6667Z"
        fill="#ED5252"
      />
      <Rect x="0.5" y="0.5" width="39" height="39" rx="11.5" stroke="#ED5252" />
    </Svg>
  </View>
);
