import { StyleSheet, View } from "react-native";
import Svg, { Rect, Path, G, Defs, ClipPath } from "react-native-svg";

type Props = {
  style?: StyleSheet.NamedStyles<unknown>;
};

export const ErrorFillIcon = ({ style }: Props) => (
  <View style={style}>
    <Svg width={40} height={40} fill="none">
      <Rect width={39} height={39} x={0.5} y={0.5} fill="#ED5252" rx={11.5} />
      <Rect width={39} height={39} x={0.5} y={0.5} stroke="#ED5252" rx={11.5} />
      <G clipPath="url(#a)">
        <Path
          fill="#fff"
          d="M20 10c5.523 0 10 4.477 10 10s-4.477 10-10 10-10-4.477-10-10 4.477-10 10-10Zm0 2a8 8 0 1 0 0 16.001A8 8 0 0 0 20 12Zm0 11a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm0-9a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 1-1Z"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M8 8h24v24H8z" />
        </ClipPath>
      </Defs>
    </Svg>
  </View>
);
