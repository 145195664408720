import { useRef } from "react";
import { Animated, Pressable, StyleSheet, Text } from "react-native";

import { ThemeColor, ThemeFont } from "../../theme";
import { currencyFormat } from "../../utils/currency-format";

type Props = {
  percent?: number;
  value: number;
  active: boolean;
  disabled?: boolean;
  onPress: (active: boolean) => void;
};

export const TipsButton = ({
  percent,
  active,
  value,
  disabled,
  onPress,
}: Props) => {
  const animatedScale = useRef(new Animated.Value(1)).current;

  const handlePress = () => {
    animatedScale.setValue(0.9);
    Animated.spring(animatedScale, {
      toValue: 1,
      bounciness: 16,
      speed: 20,
      useNativeDriver: false,
    }).start();
    onPress(!active);
  };

  return (
    <Pressable
      style={styles.container}
      onPress={() => handlePress()}
      disabled={disabled ?? false}
    >
      <Animated.View
        style={[
          styles.button,
          active && styles.buttonActive,
          { transform: [{ scale: animatedScale }] },
        ]}
      >
        {percent && <Text style={styles.buttonPercent}>{percent}%</Text>}
        <Text style={styles.buttonSum}>{currencyFormat(value, "")}</Text>
      </Animated.View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "calc((100% - 18px) / 4)",
    borderWidth: 1,
    borderColor: ThemeColor.TRANSPARENT,
  },
  button: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: ThemeColor.GREY_3,
    borderRadius: 16,
    paddingHorizontal: 12,
    paddingVertical: 10,
    minHeight: 62,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  buttonActive: {
    borderColor: ThemeColor.PINK,
    borderWidth: 1,
  },
  buttonPercent: {
    color: ThemeColor.GREY_4,
    fontFamily: ThemeFont.REGULAR,
    fontSize: 14,
    marginBottom: 4,
  },
  buttonSum: {
    color: ThemeColor.BLACK,
    fontFamily: ThemeFont.MEDIUM,
    fontSize: 14,
    whiteSpace: "nowrap",
  },
});
