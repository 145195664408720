import * as React from "react";
import Svg, { SvgProps, Rect, Path } from "react-native-svg";
const SuccessFillIcon = (props: SvgProps) => (
  <Svg width={40} height={40} fill="none" {...props}>
    <Rect width={40} height={40} fill="#24C952" rx={12} />
    <Path
      stroke="#fff"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M20 30a9.97 9.97 0 0 0 7.071-2.929A9.969 9.969 0 0 0 30 20a9.968 9.968 0 0 0-2.929-7.071A9.967 9.967 0 0 0 20 10a9.968 9.968 0 0 0-7.071 2.929A9.968 9.968 0 0 0 10 20a9.968 9.968 0 0 0 2.929 7.071A9.968 9.968 0 0 0 20 30Z"
    />
    <Path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m16 20 3 3 6-6"
    />
  </Svg>
);
export default SuccessFillIcon;
