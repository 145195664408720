import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import Toast from "react-native-toast-message";
// import Toast from "react-native-toast-message";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      if (action.payload.data.status === 500) {
        Toast.show({
          type: "error",
          text1: "Ошибка",
          text2: "Что-то пошло не так",
        });
      } else {
        Toast.show({
          type: "error",
          text1: "Ошибка",
          text2: action.payload.data.message,
        });
      }
    }

    return next(action);
  };
