import { ImageSourcePropType, ImageURISource } from "react-native";

export type User = {
  image: ImageSourcePropType;
  name: string;
};

export const USER_MAP: { [key: string]: ImageURISource } = {
  Питахайя: require("../../assets/img/fruits/dragonfruit.png"),
  Дуриан: require("../../assets/img/fruits/durian.png"),
  Апельсин: require("../../assets/img/fruits/orange.png"),
  Лайм: require("../../assets/img/fruits/lime.png"),
  Клубника: require("../../assets/img/fruits/strawberry.png"),
  Авокадо: require("../../assets/img/fruits/avocado.png"),
  Киви: require("../../assets/img/fruits/kiwi.png"),
  Слива: require("../../assets/img/fruits/plum.png"),
  Виноград: require("../../assets/img/fruits/grape.png"),
  Вишня: require("../../assets/img/fruits/cherry.png"),
  Банан: require("../../assets/img/fruits/banana.png"),
  Груша: require("../../assets/img/fruits/pear.png"),
  Черника: require("../../assets/img/fruits/blueberry.png"),
  Мандарин: require("../../assets/img/fruits/pumpkin.png"),
  Яблоко: require("../../assets/img/fruits/apple.png"),
  // 'Малина': require('../../assets/img/fruits/raspberry.png'), // Should be Смородина
  Ананас: require("../../assets/img/fruits/pineapple.png"),
  Папайя: require("../../assets/img/fruits/papaya.png"),
  Манго: require("../../assets/img/fruits/mango.png"),
  Гранат: require("../../assets/img/fruits/pomegranate.png"),
};
