import Svg, { SvgProps, Path } from "react-native-svg";
export const GMapsIcon = (props: SvgProps) => (
  <Svg width={75} height={25} fill="none" {...props}>
    <Path
      fill="#5F6368"
      fillRule="evenodd"
      d="m67.41 13.949 3.692-1.534c-.203-.516-.813-.876-1.532-.876-.924 0-2.207.813-2.16 2.41Zm4.333 1.486 1.407.938c-.453.673-1.548 1.83-3.44 1.83-2.347 0-4.041-1.815-4.041-4.13 0-2.457 1.71-4.13 3.837-4.13 2.144 0 3.191 1.705 3.535 2.629l.188.469-5.523 2.283c.423.83 1.08 1.253 2.003 1.253.922 0 1.565-.455 2.034-1.142Zm-8.95 2.519h1.814V5.813h-1.814v12.14Zm-2.961-3.866c0-1.454-.97-2.519-2.207-2.519-1.25 0-2.3 1.065-2.3 2.52 0 1.439 1.05 2.488 2.3 2.488 1.236 0 2.207-1.05 2.207-2.489Zm1.595-3.894v7.415c0 3.051-1.799 4.303-3.927 4.303-2.003 0-3.207-1.345-3.66-2.44l1.58-.658c.282.672.97 1.47 2.082 1.47 1.361 0 2.207-.844 2.207-2.425v-.594h-.063c-.407.502-1.19.939-2.174.939-2.066 0-3.959-1.8-3.959-4.116 0-2.331 1.893-4.146 3.959-4.146.986 0 1.767.437 2.174.923h.063v-.673c-.002.002 1.718.002 1.718.002Zm-19.803 3.88c0-1.488-1.06-2.503-2.29-2.503-1.228 0-2.288 1.017-2.288 2.503 0 1.47 1.06 2.503 2.289 2.503 1.227 0 2.288-1.033 2.288-2.503Zm1.78 0c0 2.378-1.829 4.13-4.071 4.13s-4.07-1.752-4.07-4.13c0-2.394 1.828-4.13 4.07-4.13 2.244 0 4.07 1.736 4.07 4.13Zm7.344 0c0-1.488-1.06-2.503-2.289-2.503s-2.288 1.017-2.288 2.503c0 1.47 1.06 2.503 2.288 2.503 1.23 0 2.29-1.033 2.29-2.503Zm1.782 0c0 2.378-1.828 4.13-4.07 4.13-2.243 0-4.071-1.752-4.071-4.13 0-2.394 1.828-4.13 4.07-4.13 2.243 0 4.071 1.736 4.071 4.13Zm-24.241 4.13c-3.536 0-6.508-2.88-6.508-6.415 0-3.536 2.974-6.415 6.51-6.415 1.957 0 3.348.767 4.397 1.77L31.452 8.38c-.751-.705-1.768-1.253-3.161-1.253-2.582 0-4.601 2.082-4.601 4.663 0 2.582 2.02 4.662 4.6 4.662 1.674 0 2.628-.673 3.24-1.283.502-.5.83-1.22.955-2.205H28.29v-1.752h5.897c.064.313.095.69.095 1.096 0 1.315-.36 2.941-1.517 4.1-1.129 1.171-2.568 1.796-4.477 1.796Z"
      clipRule="evenodd"
    />
    <Path
      fill="#34A853"
      d="M7.092 16.796a19.37 19.37 0 0 1 1.568 2.316c.4.758.564 1.27.861 2.183.175.503.34.657.687.657.38 0 .553-.257.687-.657.277-.862.492-1.516.83-2.142.665-1.2 1.507-2.266 2.326-3.29.224-.287 1.66-1.978 2.306-3.32 0 0 .789-1.466.789-3.516 0-1.916-.78-3.248-.78-3.248l-2.255.605-1.373 3.607-.34.492-.07.093-.093.112-.154.184-.224.225-1.22.993-3.044 1.763-.501 2.943Z"
    />
    <Path
      fill="#FBBC04"
      d="M3.945 12.297c.748 1.702 2.173 3.188 3.147 4.5l5.156-6.109s-.728.953-2.04.953a2.64 2.64 0 0 1-2.654-2.644c0-1.015.605-1.71.605-1.71l-3.497.932-.717 4.078Z"
    />
    <Path
      fill="#4285F4"
      d="M12.31 2.366c1.71.553 3.176 1.711 4.058 3.424l-4.121 4.909s.605-.707.605-1.711c0-1.507-1.27-2.644-2.644-2.644-1.302 0-2.05.944-2.05.944V4.191l4.151-1.825Z"
    />
    <Path
      fill="#1A73E8"
      d="M4.887 4.528c1.024-1.22 2.818-2.48 5.298-2.48a7.08 7.08 0 0 1 2.11.318l-4.138 4.92H5.226l-.34-2.758Z"
    />
    <Path
      fill="#EA4335"
      d="M3.946 12.297s-.676-1.343-.676-3.279c0-1.834.717-3.434 1.628-4.48l3.27 2.757-4.222 5.002Z"
    />
  </Svg>
);
