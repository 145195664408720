import { StyleSheet, View } from "react-native";

import { AppText } from "./AppText";
import { ThemeColor } from "../../theme";
import { ErrorIcon } from "../icons/ErrorIcon";
import { StarFillIcon } from "../icons/StarFillIcon";
import { VPNIcon } from "../icons/VPNIcon";

interface NotificationToastProps {
  type: "vpn" | "error" | "feedback" | "timer";
  title?: string;
  content?: string;
}

const NotificationToast: React.FC<NotificationToastProps> = ({
  type,
  title,
  content,
}) => {
  return (
    <View style={styles.container}>
      {type === "vpn" && <VPNIcon />}
      {type === "error" && <ErrorIcon />}
      {type === "timer" && <ErrorIcon />}
      {type === "feedback" && <StarFillIcon />}
      <View style={styles.text}>
        <AppText>{title}</AppText>
        {content && (
          <AppText
            style={{
              maxWidth: 250,
            }}
            size={12}
            color={ThemeColor.GREY_4}
          >
            {content}
          </AppText>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    zIndex: 999,
    borderRadius: 20,
    gap: 14,
    paddingHorizontal: 20,
    paddingVertical: 16,
    backgroundColor: ThemeColor.WHITE,
    maxWidth: 358,
    minWidth: 300,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    shadowColor: ThemeColor.SHADOW,
    shadowOpacity: 0.05,
    shadowRadius: 10,
    shadowOffset: { width: 2, height: 2 },
  },
  text: {
    gap: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
});

export default NotificationToast;
