import { StyleSheet, View } from "react-native";
import Svg, { Path } from "react-native-svg";

type Props = {
  style?: StyleSheet.NamedStyles<unknown>;
};

export const StarFillIcon = ({ style }: Props) => (
  <View style={style}>
    <Svg width={36} height={34} fill="none">
      <Path
        fill="#E79746"
        stroke="#E79746"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m12.312 10.727 4.33-8.72a1.518 1.518 0 0 1 2.716 0l4.33 8.72 9.68 1.406a1.504 1.504 0 0 1 .839 2.57l-7.004 6.784 1.654 9.583c.211 1.23-1.089 2.167-2.2 1.587L18 28.13l-8.658 4.527c-1.11.581-2.41-.357-2.199-1.589l1.654-9.583-7.004-6.783a1.504 1.504 0 0 1 .839-2.567l9.68-1.408Z"
      />
    </Svg>
  </View>
);
