import { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";

import { Section } from "./ui/Section";
import {
  ShareProductButton,
  ShareProductButtonProps,
} from "./ui/ShareProductButton";
import { SuccessChip } from "./ui/SuccessChip";
import { useAppSelector } from "../store";
import { ThemeColor, ThemeFont } from "../theme";
import { GuestProduct, SharedProduct, Transaction } from "../types/common";
import { currencyFormat } from "../utils/currency-format";

interface SharedProductItemProps {
  product: SharedProduct;
}

export const SharedProductItem: React.FC<SharedProductItemProps> = ({
  product,
}) => {
  const { currentGuest, guestProducts, disableDiscount, transactions } =
    useAppSelector((state) => state.order);

  const { size: totalSize, total, items } = product;

  const [guestTotal, setGuestTotal] = useState(0);
  const [isPaid, setIsPaid] = useState(false);
  const [buttons, setButtons] = useState<ShareProductButtonProps[]>([]);

  useEffect(() => {
    if (guestProducts) {
      const flatGuestProducts: GuestProduct[] = items.flatMap(({ uuid }, i) =>
        guestProducts.filter(
          ({ productUuid, parts, position }) =>
            productUuid === uuid && parts > 0 && position === i
        )
      );

      const currentGuestTotal = flatGuestProducts
        .filter((pg) => pg.guestUuid === currentGuest.uuid)
        .reduce((acc, cur) => acc + cur.total, 0);

      setGuestTotal(currentGuestTotal);

      const inProgressGuests = transactions
        .filter((t: Transaction) => t.inProgress || t.success)
        .map(({ guestUuid, lastChangeDate }) => ({
          guestUuid,
          lastChangeDate,
        }));

      const tSuccessGuests = transactions
        .filter((t: Transaction) => t.success)
        .map((t) => t.guestUuid);

      const inProgressGuestProducts = flatGuestProducts
        .filter(
          (gp) =>
            inProgressGuests
              .map(({ guestUuid }) => guestUuid)
              .indexOf(gp.guestUuid) >= 0 && gp.parts > 0
        )
        .map((gp) => ({
          ...gp,
          lastChangeDate: inProgressGuests.find(
            ({ guestUuid }) => gp.guestUuid === guestUuid
          )?.lastChangeDate,
        }));

      const tSuccessGuestProducts = flatGuestProducts.filter(
        (gp) => tSuccessGuests.indexOf(gp.guestUuid) >= 0 && gp.parts > 0
      );

      const disabledProduct = product.discount > 0 && disableDiscount;

      const generateButtons = (
        uuid: string,
        price: number,
        position: number
      ): ShareProductButtonProps => {
        const selectProductGuest = flatGuestProducts.filter(
          (gp: GuestProduct) =>
            gp.productUuid === uuid && gp.position === position
        );
        const isActive = selectProductGuest.some(
          (gp: GuestProduct) => gp.guestUuid === currentGuest.uuid
        );
        const totalInProgress = inProgressGuestProducts.filter(
          (gp: GuestProduct) => gp.position === position
        );
        const totalPay = totalInProgress.reduce(
          (acc, cur) => acc + cur.total,
          0
        );

        const lastChange = totalInProgress[0]?.lastChangeDate;

        const totalSuccess = tSuccessGuestProducts.filter(
          (gp: GuestProduct) => gp.position === position
        );
        const totalPaid = totalSuccess.reduce((acc, cur) => acc + cur.total, 0);

        let totalPrice = price - totalPay;
        let totalSelected = selectProductGuest.length - totalInProgress.length;

        const isDisabled = disabledProduct || totalPrice < 0.1;
        const isProductPaid = price - totalPaid < 0.1;

        if (totalPrice < 0.1) {
          totalPrice = price;
          totalSelected = selectProductGuest.length;
        }

        return {
          position,
          totalSelected,
          isDisabled,
          isActive,
          isProductPaid,
          uuid,
          isSelectedByOthers: totalSelected > 0,
          price: totalPrice / (totalSelected > 0 ? totalSelected : 1),
          lastChange,
        };
      };

      const newButtons: ShareProductButtonProps[] = items.map(
        ({ uuid, price }, i) => generateButtons(uuid, price, i)
      );
      setIsPaid(newButtons.every((b) => b.isProductPaid));
      setButtons(newButtons);
    }
  }, [guestProducts, transactions]);

  return (
    <Section style={styles.section}>
      <View style={styles.header}>
        <View style={styles.amountBox}>
          <Text style={styles.amount}>{totalSize}</Text>
        </View>
        <Text style={styles.title}>{product.ru}:</Text>
        {isPaid ? (
          <SuccessChip />
        ) : (
          <View style={styles.sumBox}>
            <Text style={styles.sum}>{currencyFormat(guestTotal)} /</Text>
            <Text style={styles.total}>{currencyFormat(total)}</Text>
          </View>
        )}
      </View>

      <View style={styles.buttonList}>
        {buttons.map((button, i) => (
          <ShareProductButton
            key={button.uuid + i}
            style={{ marginRight: (i + 1) % 4 ? 6 : 0 }}
            {...button}
          />
        ))}
      </View>
    </Section>
  );
};

const styles = StyleSheet.create({
  section: {
    marginBottom: 8,
    borderRadius: 20,
    marginHorizontal: 0, // TODO: remove after implement navigation
  },
  header: {
    flexDirection: "row",
  },
  amountBox: {
    width: 32,
    height: 21,
    borderRadius: 4,
    backgroundColor: ThemeColor.GREY_2,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 8,
  },
  amount: {
    fontFamily: ThemeFont.MEDIUM,
    color: ThemeColor.BLACK,
    fontSize: 12,
  },
  title: {
    fontFamily: ThemeFont.MEDIUM,
    color: ThemeColor.BLACK,
    fontSize: 16,
    lineHeight: 21,
    paddingRight: 8,
  },
  sumBox: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "auto",
    flexWrap: "nowrap",
  },
  sum: {
    fontFamily: ThemeFont.SEMIBOLD,
    color: ThemeColor.BLACK,
    fontSize: 14,
    lineHeight: 21,
    whiteSpace: "nowrap",
  },
  total: {
    fontFamily: ThemeFont.MEDIUM,
    color: ThemeColor.GREY_4,
    fontSize: 14,
    lineHeight: 21,
    whiteSpace: "nowrap",
    marginLeft: 4,
  },
  buttonList: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});
