import { PropsWithChildren } from "react";
import { StyleSheet, View } from "react-native";
import Modal from "react-native-modal";

import LRG from "./LRG";
import PremiumBonus from "./PremiumBonus";
import PrimeHill from "./PrimeHill";
import UDS from "./UDS";
import { ThemeColor } from "../../theme";
import { LoyalSystem } from "../../types/common";
import { CloseButton } from "../ui/CloseButton";

type Props = {
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
  loyalSystem: LoyalSystem;
};

export const LoyaltyLoginDrawer = ({
  visible,
  onVisibleChange,
  loyalSystem,
}: PropsWithChildren<Props>) => {
  return (
    <Modal
      style={styles.modal}
      isVisible={visible}
      backdropColor={ThemeColor.DARK}
      backdropOpacity={0.2}
    >
      <View style={styles.content}>
        <CloseButton
          size="large"
          style={styles.closeBtn}
          onPress={() => {
            onVisibleChange(false);
          }}
        />
        {loyalSystem === "UDS" && <UDS onVisibleChange={onVisibleChange} />}
        {loyalSystem === "PrimeHill" && (
          <PrimeHill onVisibleChange={onVisibleChange} />
        )}
        {loyalSystem === "PremiumBonus" && (
          <PremiumBonus onVisibleChange={onVisibleChange} />
        )}
        {loyalSystem === "LRG" && <LRG onVisibleChange={onVisibleChange} />}
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: {
    margin: 0,
    justifyContent: "flex-end",
  },
  content: {
    width: "100%",
    maxHeight: "100%",
    borderTopLeftRadius: 42,
    borderTopRightRadius: 42,
    padding: 16,
    paddingTop: 32,
    position: "relative",
    backgroundColor: ThemeColor.WHITE,
  },
  closeBtn: {
    position: "absolute",
    top: 16,
    right: 16,
  },
  form: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: 14,
  },
  cardImg: {
    overflow: "hidden",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    height: 100,
    width: 400,
    position: "absolute",
    top: -100,
    zIndex: 100,
  },
});
