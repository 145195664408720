import { useEffect } from "react";
import { StyleSheet, View } from "react-native";

import { FeedbackBlock } from "../blocks/FeedbackBlock";
import { ShareBillBlock } from "../blocks/ShareBillBlock";
import TipsBlock from "../blocks/TipsBlock";
import { useAppDispatch, useAppSelector } from "../store";
import { setProductsSum } from "../store/orderSlice";
import { GuestProduct } from "../types/common";

export const ShareBillScreen = () => {
  const dispatch = useAppDispatch();

  const { tips, sharedProducts, currentGuest, guestProducts, productsSum } =
    useAppSelector((state) => state.order);

  useEffect(() => {
    if (guestProducts) {
      const guestProductsSum = guestProducts
        .filter((gp: GuestProduct) => gp.guestUuid === currentGuest.uuid)
        .reduce((acc: number, cur: GuestProduct) => acc + cur.total, 0);

      dispatch(setProductsSum(guestProductsSum));
    }
  }, [guestProducts]);

  return (
    <View style={styles.container}>
      <ShareBillBlock sharedProducts={sharedProducts} />
      <TipsBlock initialTip={tips[2]} totalSum={productsSum} tips={tips} />
      <FeedbackBlock title="Оценить обслуживание" />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
