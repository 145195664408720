import { useEffect, useRef, useState } from "react";

import { AppText } from "./AppText";
import { ThemeColor } from "../../theme";

type Props = {
  shiftInSeconds?: number;
  size?: number;
  color?: string;
  onTimeout: () => void;
};

export const Countdown = ({
  shiftInSeconds = 300,
  onTimeout,
  size = 24,
  color = ThemeColor.BLACK,
}: Props) => {
  const [countdownString, setCountdownString] = useState("");
  const intervalRef = useRef<NodeJS.Timer>();

  const updateCountdown = (target: number) => {
    const countdownTime = Math.round((target - Date.now()) / 1000);
    let minutes = String(Math.trunc(countdownTime / 60));
    let seconds = String(countdownTime % 60).padStart(2, "0");
    if (countdownTime <= 0) {
      clearInterval(intervalRef.current);
      minutes = "0";
      seconds = "00";
      onTimeout();
    }

    setCountdownString(`${minutes}:${seconds}`);
  };

  const startTimer = () => {
    const targetMoment = Date.now() + shiftInSeconds * 1000;

    updateCountdown(targetMoment);
    intervalRef.current = setInterval(() => {
      updateCountdown(targetMoment);
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <AppText weight={600} size={size} height={29} color={color}>
      {countdownString}
    </AppText>
  );
};
