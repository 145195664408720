import { Product, SharedProduct } from "../types/common";

const sortProducts = (
  productA: { discount: number; productUuid: string },
  productB: { discount: number; productUuid: string },
  disableDiscount: boolean
): number => {
  const { discount: discountA, productUuid: uuidA } = productA;
  const { discount: discountB, productUuid: uuidB } = productB;

  if (disableDiscount) {
    return discountA - discountB;
  } else {
    if (uuidA < uuidB) return -1;
    if (uuidA > uuidB) return 1;
    return 0;
  }
};

export const mapSharedProducts = (
  products: Product[],
  disableDiscount: boolean
): SharedProduct[] => {
  const filteredProducts = products.filter(
    ({ price, deleted }) => price > 0 && !deleted
  );
  const filteredFlatProducts = duplicateBySize(filteredProducts);

  const combinedProducts: SharedProduct[] = [];

  for (const product of filteredFlatProducts) {
    const existingCombinedProduct = combinedProducts.find(
      (p) => p.productUuid === product.productUuid
    );

    if (existingCombinedProduct) {
      const {
        size: sizeExisting,
        discount: discountExisting,
        items,
        price: priceExisting,
      } = existingCombinedProduct;

      const {
        size: sizeCurr,
        discount: discountCurr,
        price: priceCurr,
      } = product;

      const isWholeExisting = sizeExisting % 1 === 0;
      const isWholeCurr = sizeCurr % 1 === 0;

      const discountPerItemExisting = discountExisting / sizeExisting;
      const discountPerItemCurr = discountCurr / sizeCurr;

      if (
        (isWholeCurr && isWholeExisting
        /*
           &&
          discountPerItemCurr === discountPerItemExisting &&
          priceCurr === priceExisting
        */
        ) ||
        (sizeCurr === sizeExisting 
        /*
          &&
          discountCurr === discountExisting &&
          priceCurr === priceExisting
        */
          )
      ) {
        items.push(product);
      }
    } else {
      combinedProducts.push({ ...product, items: [product] });
    }
  }

  const sharedProducts: SharedProduct[] = combinedProducts.map((product) => {
    return {
      ...product,
      total: product.items.reduce(
        (acc: number, cur: Product) => acc + cur.total,
        0
      ),
      size: product.items.reduce(
        (acc: number, cur: Product) => acc + cur.size,
        0
      ),
    };
  });

  const sortedSharedProducts = sharedProducts
    .map((p) => {
      p.items.sort((a, b) => (a.uuid < b.uuid ? 1 : a.uuid > b.uuid ? -1 : 0));
      return p;
    })
    .sort((a, b) => sortProducts(a, b, disableDiscount));

  return sortedSharedProducts;
};

// TODO find within for loop - room for optimization

export const mapProducts = (
  products: Product[],
  disableDiscount: boolean
): Product[] => {
  const filteredProducts = products.filter(
    ({ price, deleted }) => price > 0 && !deleted
  );
  const uniqueProducts: Product[] = [];

  for (const product of filteredProducts) {
    const existingProduct = uniqueProducts.find(
      (p) => p.productUuid === product.productUuid
    );

    if (existingProduct) {
      existingProduct.total += product.total;
      existingProduct.size += product.size;
    } else {
      uniqueProducts.push({ ...product });
    }
  }

  return uniqueProducts.sort((a, b) => sortProducts(a, b, disableDiscount));
};

export function filterAndDivideSize(items: Product[]) {
  const dividedItems = items.map((item) => {
    const duplicates = items.filter((i) => i.id === item.id).length;
    const dividedSize = item.size / duplicates;
    const dividedDiscount = item.discount / duplicates;
    return { ...item, size: dividedSize, discount: dividedDiscount };
  });

  return dividedItems;
}

function duplicateBySize(products: Product[]): Product[] {
  const duplicatedProduct: Product[] = [];

  products.forEach((product) => {
    for (let i = 0; i < product.size; i++) {
      duplicatedProduct.push({ ...product, size: 1, total: product.price });
    }
  });

  return duplicatedProduct;
}
