import { Image, ImageSourcePropType, StyleSheet, Text } from "react-native";

import { ThemeColor, ThemeFont } from "../../theme";

export enum Rating {
  AWESOME = 5,
  LIKE = 4,
  DONT_LIKE = 3,
  BAD = 2,
  TERRIBLY = 1,
  NONE = 0,
}

enum ContainerClass {
  AWESOME = "awesome",
  LIKE = "like",
  DONT_LIKE = "dontLike",
  BAD = "bad",
  TERRIBLY = "terribly",
  NONE = "",
}

enum TextClass {
  AWESOME = "awesomeText",
  LIKE = "likeText",
  DONT_LIKE = "dontLikeText",
  BAD = "badText",
  TERRIBLY = "terriblyText",
  NONE = "",
}

type RatingType = {
  containerClass: ContainerClass;
  textClass: TextClass;
  emoji: ImageSourcePropType;
  text: string;
};
type RatingMap = { [key: number]: RatingType };

type Props = {
  rating: Rating;
};

const RATING_MAP: RatingMap = {
  [Rating.TERRIBLY]: {
    containerClass: ContainerClass.TERRIBLY,
    textClass: TextClass.TERRIBLY,
    emoji: require("../../../assets/img/feedback/1-terribly.png"),
    text: "Ужасно",
  },
  [Rating.BAD]: {
    containerClass: ContainerClass.BAD,
    textClass: TextClass.BAD,
    emoji: require("../../../assets/img/feedback/2-bad.png"),
    text: "Плохо",
  },
  [Rating.DONT_LIKE]: {
    containerClass: ContainerClass.DONT_LIKE,
    textClass: TextClass.DONT_LIKE,
    emoji: require("../../../assets/img/feedback/3-dont-like.png"),
    text: "Нормально",
  },
  [Rating.LIKE]: {
    containerClass: ContainerClass.LIKE,
    textClass: TextClass.LIKE,
    emoji: require("../../../assets/img/feedback/4-like.png"),
    text: "Нравится",
  },
  [Rating.AWESOME]: {
    containerClass: ContainerClass.AWESOME,
    textClass: TextClass.AWESOME,
    emoji: require("../../../assets/img/feedback/5-awesome.png"),
    text: "Классно",
  },
  [Rating.NONE]: {
    containerClass: ContainerClass.AWESOME,
    textClass: TextClass.AWESOME,
    emoji: require("../../../assets/img/feedback/fine.png"), // TODO ?
    text: "",
  },
};

export const FeedbackBanner = ({ rating }: Props) => {
  const set = RATING_MAP[rating];

  return (
    <Text style={[styles.banner, styles[set.containerClass]]}>
      <Image source={set.emoji} style={styles.image} />
      <Text style={[styles.text, styles[set.textClass]]}>{set.text}</Text>
    </Text>
  );
};

const styles = StyleSheet.create({
  banner: {
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 20,
    height: 46,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  image: {
    width: 30,
    height: 30,
    marginRight: 8,
  },
  text: {
    fontFamily: ThemeFont.MEDIUM,
    fontSize: 14,
  },
  [ContainerClass.TERRIBLY]: {
    backgroundColor: ThemeColor.RED,
    borderColor: "#BE8366", // TODO: add to UI kit
  },
  [TextClass.TERRIBLY]: {
    color: "#961B00", // TODO: add to UI kit
  },
  [ContainerClass.BAD]: {
    backgroundColor: ThemeColor.BRIGHT_RED,
    borderColor: "#D0A590", // TODO: add to UI kit
  },
  [TextClass.BAD]: {
    color: ThemeColor.BRIGHT_RED_3,
  },
  [ContainerClass.DONT_LIKE]: {
    backgroundColor: "#EADEA6", // TODO: add to UI kit
    borderColor: "#D8C880", // TODO: add to UI kit
  },
  [TextClass.DONT_LIKE]: {
    color: ThemeColor.YELLOW,
  },
  [ContainerClass.LIKE]: {
    backgroundColor: "#D4DDAD", // TODO: add to UI kit
    borderColor: "#C2CD91", // TODO: add to UI kit
  },
  [TextClass.LIKE]: {
    color: ThemeColor.BRIGHT_GREEN,
  },
  [ContainerClass.AWESOME]: {
    backgroundColor: "#BBE1BA", // TODO: add to UI kit
    borderColor: "#9CCF9B", // TODO: add to UI kit
  },
  [TextClass.AWESOME]: {
    color: ThemeColor.GREEN,
  },
  [ContainerClass.NONE]: {
    backgroundColor: "#BBE1BA", // TODO: add to UI kit
    borderColor: "#9CCF9B", // TODO: add to UI kit
  },
  [TextClass.NONE]: {
    color: ThemeColor.GREEN,
  },
});
