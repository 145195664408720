import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ScrollView, StyleSheet, View } from "react-native";
import Modal from "react-native-modal";

import BellIcon from "./icons/WaiterIcon";
import SendIcon from "./icons/SendIcon";
import { AppButton } from "./ui/AppButton";
import { AppInput } from "./ui/AppInput";
import { AppText } from "./ui/AppText";
import { CloseButton } from "./ui/CloseButton";
import { useAppDispatch, useAppSelector } from "../store";
import { addWaiterMessage } from "../store/appSlice";
import esApi from "../store/esApi";
import { ThemeColor } from "../theme";
import { getSearch } from "../utils/useHelper";

interface FormData {
  message: string;
}

type Props = {
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
};

export const WaiterDrawer = ({
  visible,
  onVisibleChange,
}: PropsWithChildren<Props>) => {
  const [isBlocked, setIsBlocked] = useState(false);

  const dispatch = useAppDispatch();

  const [callWaiter, { isLoading }] = esApi.useCallWaiterMutation();

  const { waiterMessages } = useAppSelector((state) => state.app);

  const scrollView = useRef<ScrollView>(null);

  const { control, watch, handleSubmit, reset } = useForm<FormData>({
    mode: "onTouched",
    defaultValues: {
      message: "",
    },
  });

  const watcher = watch("message");

  useEffect(() => {
    scrollView.current?.scrollToEnd();
  }, [waiterMessages]);

  return (
    <Modal
      style={styles.modal}
      isVisible={visible}
      backdropColor={ThemeColor.DARK}
      backdropOpacity={0.2}
    >
      <View style={styles.content}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            paddingVertical: 22,
          }}
        >
          <AppText weight={600} size={18}>
            Вызов официанта
          </AppText>
          <CloseButton
            size="large"
            style={styles.closeBtn}
            onPress={() => {
              onVisibleChange(false);
            }}
          />
        </View>
        <ScrollView ref={scrollView}>
          <View style={styles.messageContainer}>
            {waiterMessages.map((message) => (
              <>
                <AppText
                  color={message ? ThemeColor.WHITE : ThemeColor.BLACK}
                  style={[
                    styles.message,
                    {
                      backgroundColor: message
                        ? ThemeColor.BLUE_3
                        : ThemeColor.WHITE,
                    },
                  ]}
                >
                  {message || "Вы позвали официанта"}
                </AppText>
                {!message && (
                  <AppText style={{ textAlign: "center" }}>
                    Официант скоро к Вам подойдет
                  </AppText>
                )}
              </>
            ))}
          </View>
        </ScrollView>
        <View style={styles.form}>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <AppInput
                keyboardType="default"
                key="input-waiter-message"
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                placeholder="Введите сообщение..."
                style={{ backgroundColor: ThemeColor.WHITE }}
              />
            )}
            name="message"
          />
          <AppButton
            disabled={isBlocked || isLoading}
            size="small"
            onPress={handleSubmit(async ({ message }) => {
              setIsBlocked(true);
              setTimeout(() => {
                setIsBlocked(false);
              }, 5000);

              await callWaiter({ order: getSearch(), message });
              dispatch(addWaiterMessage(message || ""));
              reset();
            })}
            style={{
              height: 53,
              width: 140,
              backgroundColor: watcher ? ThemeColor.BLUE_2 : ThemeColor.BLACK,
            }}
          >
            <View style={styles.button}>
              {watcher ? (
                <>
                  Отправить <SendIcon />
                </>
              ) : (
                <>
                  Позвать <BellIcon width={14} height={16} />
                </>
              )}
            </View>
          </AppButton>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: {
    margin: 0,
    justifyContent: "flex-end",
  },
  content: {
    borderTopLeftRadius: 42,
    borderTopRightRadius: 42,
    backgroundColor: ThemeColor.GREY,
  },
  message: {
    paddingHorizontal: 12,
    paddingVertical: 20,
    borderRadius: 20,
    textAlign: "right",
    alignSelf: "flex-end",
    maxWidth: "100%",
  },
  messageContainer: {
    flexDirection: "column",
    gap: 20,
    paddingHorizontal: 16,
    height: 400,
    paddingBottom: 20,
  },
  closeBtn: {
    position: "absolute",
    top: 16,
    right: 16,
  },
  form: {
    width: "100%",
    padding: 8,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    gap: 10,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: ThemeColor.GREY_2,
  },
  button: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    alignItems: "center",
    justifyContent: "center",
  },
});
