import { StyleSheet, View } from "react-native";
import Svg, { Path } from "react-native-svg";

type Props = {
  style?: StyleSheet.NamedStyles<unknown>;
};

export const StarIcon = ({ style }: Props) => (
  <View style={style}>
    <Svg width={29} height={28} fill="none">
      <Path
        stroke="#BBB"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m9.95 8.981 3.464-6.976a1.215 1.215 0 0 1 2.173 0l3.464 6.976 7.744 1.126a1.203 1.203 0 0 1 .67 2.056l-5.602 5.426 1.323 7.667c.169.984-.871 1.733-1.76 1.27L14.5 22.903l-6.927 3.621c-.888.466-1.928-.285-1.758-1.27l1.322-7.667-5.602-5.427a1.202 1.202 0 0 1 .67-2.053L9.95 8.981Z"
      />
    </Svg>
  </View>
);
