import { format } from "date-fns";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Easing, StyleSheet, Text, View } from "react-native";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";

import { AppButton } from "./ui/AppButton";
import { ProductItem } from "./ui/ProductItem";
import { SectionHeader } from "./ui/SectionHeader";
import { TotalCheckSeparator } from "./ui/TotalCheckSeparator";
import { ThemeColor, ThemeFont } from "../theme";
import { Product } from "../types/common";
import { currencyFormat } from "../utils/currency-format";

interface TotalCheckProps {
  products: Product[];
  total: number;
  created?: string;
  showCount?: number;
  action?: ReactNode;
  showTitle?: string;
}

export const TotalCheck: React.FC<TotalCheckProps> = ({
  products,
  total,
  created,
  showCount = 3,
  action,
  showTitle = "Смотреть все",
}) => {
  const [showedProducts, setShowedProducts] = useState<Product[]>([]);
  const [hiddenProducts, setHiddenProducts] = useState<Product[]>([]);
  const [expanded, setExpanded] = useState<boolean>(false);

  const contentRef = useRef<View>(null);
  const contentHeight = useSharedValue<number>(0);
  const animatedStyle = useAnimatedStyle(() => ({
    height: contentHeight.value,
  }));

  const toggleExpand = () => {
    contentRef.current?.measure(
      (x: number, y: number, width: number, height: number) => {
        contentHeight.value = withTiming(expanded ? 0 : height, {
          duration: 500,
          easing: Easing.bezier(0.25, 0.1, 0.25, 1),
        });
      }
    );
    setExpanded(!expanded);
  };

  useEffect(() => {
    setShowedProducts(products.slice(0, showCount));
    setHiddenProducts(
      products.length > showCount ? products.slice(showCount) : []
    );
  }, []);

  return (
    <View style={styles.section}>
      <View style={styles.header}>
        <SectionHeader
          style={styles.sectionHeader}
          src={require("../../assets/img/bill.svg")}
          title="Сумма счета"
          text={created ? `Время: ${format(created, "HH:MM")}` : "Ваш чек"}
          extra={currencyFormat(total)}
        />
      </View>
      <TotalCheckSeparator />
      <View style={styles.body}>
        {showCount !== 0 && (
          <View style={styles.productListTitleBox}>
            <Text style={styles.productListTitle}>Позиции</Text>
            <Text style={styles.productListTitle}>Итого</Text>
          </View>
        )}
        <View style={[styles.productList]}>
          {showedProducts.map((p, index) => (
            <ProductItem
              key={index}
              amount={p.size}
              title={p.ru}
              price={p.total}
            />
          ))}
          <Animated.View style={[animatedStyle]}>
            {showCount === 0 && (
              <View style={styles.productListTitleBox}>
                <Text style={styles.productListTitle}>Позиции</Text>
                <Text style={styles.productListTitle}>Итого</Text>
              </View>
            )}
            <View ref={contentRef}>
              {hiddenProducts.map((p, index) => (
                <ProductItem
                  key={index}
                  amount={p.size}
                  title={p.ru}
                  price={p.total}
                />
              ))}
            </View>
          </Animated.View>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {!!hiddenProducts.length && (
            <AppButton type="link" size="large" onPress={toggleExpand}>
              {expanded ? "Свернуть" : showTitle}
            </AppButton>
          )}
          {action}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  section: {
    marginBottom: 32,
  },
  header: {
    backgroundColor: ThemeColor.WHITE,
    borderStyle: "solid",
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: ThemeColor.GREY_3,
    paddingHorizontal: 35,
    paddingTop: 16,
    zIndex: 1,
  },
  sectionHeader: {
    marginBottom: -5,
  },
  body: {
    backgroundColor: ThemeColor.WHITE,
    borderStyle: "solid",
    borderBottomLeftRadius: 24,
    borderBottomRightRadius: 24,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderColor: ThemeColor.GREY_3,
    paddingHorizontal: 24,
  },
  productListTitleBox: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  productListTitle: {
    fontFamily: ThemeFont.MEDIUM,
    fontSize: 12,
    color: ThemeColor.GREY_4,
    marginBottom: 10,
    lineHeight: 18,
  },
  productList: {
    overflow: "hidden",
  },
});
