import { ActivityIndicator, StyleSheet, Text, View } from "react-native";
import Modal from "react-native-modal";

import { AppButton } from "./ui/AppButton";
import { ThemeColor, ThemeFont } from "../theme";

type Props = {
  title: string;
  subtitle: string;
  showModal: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  isLoading?: boolean;
};

export const ConfirmationModal = ({
  title,
  subtitle,
  showModal = false,
  onConfirm,
  onCancel,
  isLoading,
}: Props) => {
  const handleCancel = () => {
    onCancel();
  };
  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Modal
      style={styles.modal}
      isVisible={showModal}
      backdropColor={ThemeColor.DARK}
      backdropOpacity={0.2}
      onBackdropPress={handleCancel}
      animationIn="fadeIn"
      animationOut="fadeOut"
    >
      <View style={styles.content}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.subtitle}>{subtitle}</Text>
        <View style={styles.buttonWrapper}>
          <AppButton
            size="small"
            type="secondary"
            onPress={handleCancel}
            disabled={isLoading}
          >
            Отмена
          </AppButton>
          <AppButton size="small" type="primary" onPress={handleConfirm}>
            {isLoading ? (
              <ActivityIndicator size={18} color={ThemeColor.GREY_5} />
            ) : (
              "Подтвердить"
            )}
          </AppButton>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: {
    margin: 0,
    alignItems: "center",
  },
  content: {
    width: 290,
    backgroundColor: ThemeColor.WHITE,
    borderRadius: 20,
    padding: 20,
  },
  title: {
    fontFamily: ThemeFont.SEMIBOLD,
    fontSize: 20,
    lineHeight: 30,
    marginBottom: 4,
    color: ThemeColor.BLACK,
  },
  subtitle: {
    fontFamily: ThemeFont.REGULAR,
    fontSize: 14,
    lineHeight: 21,
    color: ThemeColor.GREY_4,
    marginBottom: 23,
  },
  buttonWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
