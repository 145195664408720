import {
  KeyboardAvoidingView,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";

import { Header } from "../components/Header";
import Tapbar from "../components/Tapbar";
import { TotalCheck } from "../components/TotalCheck";
import { AppButton } from "../components/ui/AppButton";
import { AppText } from "../components/ui/AppText";
import esApi from "../store/esApi";
import { ThemeColor } from "../theme";
import { redirectToTableUUID } from "../utils/helpers";
import { useHelper } from "../utils/useHelper";

export const SelectOrderScreen = () => {
  const { getSearch } = useHelper();

  const { data: orderSession } = esApi.useGetOrderQuery({
    order: getSearch(),
    reset: true,
  });

  const showTapbar =
    orderSession?.menuType && orderSession?.menuType !== "NONE";

  return (
    <KeyboardAvoidingView behavior="padding" style={styles.container}>
      <ScrollView contentContainerStyle={{ minHeight: "100%" }}>
        <Header
          background={orderSession?.order.restaurantBackground}
          logo={orderSession?.order.restaurantLogo}
          name={orderSession?.order.restaurantName}
        />
        <View style={styles.navigation}>
          <AppText weight={600} size={20}>
            Выберите ваш заказ
          </AppText>
          <View style={{ paddingTop: 10 }}>
            {orderSession?.tableOrders?.map(
              ({ total, discount, products, created, uuid, restaurant }) => (
                <TotalCheck
                  key={uuid}
                  products={products}
                  total={total - discount}
                  created={created}
                  showCount={0}
                  showTitle="Смотреть позиции"
                  action={
                    <AppButton
                      onPress={() => {
                        console.log({
                          jwt: orderSession.jwt,
                          order: {
                            restaurant,
                            uuid,
                          },
                        });
                        redirectToTableUUID({
                          jwt: orderSession.jwt,
                          order: {
                            restaurant,
                            uuid,
                          },
                        });
                      }}
                      type="outline"
                      size="small"
                    >
                      Перейти
                    </AppButton>
                  }
                />
              )
            )}
          </View>
        </View>
      </ScrollView>
      {showTapbar && <Tapbar />}
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: "100vw",
    maxHeight: "100svh",
    backgroundColor: ThemeColor.GREY,
  },
  navigation: {
    flexGrow: 1,
    paddingHorizontal: 16,
    paddingTop: 10,
    paddingBottom: 20,
  },
});
