import { useNavigation } from "@react-navigation/native";
import { useEffect, useRef, useState } from "react";
import {
  Easing,
  KeyboardAvoidingView,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { NativeStackScreenProps } from "react-native-screens/native-stack";
import { useDispatch } from "react-redux";

import { FeedbackBlock } from "../blocks/FeedbackBlock";
import { AppButton } from "../components/ui/AppButton";
import { AppText } from "../components/ui/AppText";
import { BackButton } from "../components/ui/BackButton";
import { PostPayInfoCheck } from "../components/ui/PostPayInfoCheck";
import {
  PaymentStatus,
  PostPayUserCard,
} from "../components/ui/PostPayUserCard";
import {
  RootStackParamList,
  StackNavigation,
} from "../navigation/AppNavigation";
import { useAppSelector } from "../store";
import { setSection } from "../store/appSlice";
import esApi from "../store/esApi";
import { setComment, setRating } from "../store/orderSlice";
import { ThemeColor } from "../theme";
import { Transaction } from "../types/common";
import { paid } from "../utils/helpers";
import { useHelper } from "../utils/useHelper";
import { User, USER_MAP } from "../utils/users";

type Props = NativeStackScreenProps<RootStackParamList, "PostPayment">;

export type UserCheck = User & {
  sum: number;
  status: PaymentStatus;
  bonus: number;
};

const SHOWED_USERS_AMOUNT = 3;

export const PostPaymentScreen: React.FC<Props> = () => {
  const navigation = useNavigation<StackNavigation>();
  const dispatch = useDispatch();
  const { resetJWT, getSearch, updateJWT } = useHelper();
  const { total, discount, transactions, currentGuest, currentTransaction } =
    useAppSelector((state) => state.order);

  const { feedbackSent } = useAppSelector((state) => state.app);

  const [getOrder] = esApi.useLazyGetOrderQuery();

  const [cancelLoyalty] = esApi.useCancelLoyaltyMutation();

  const [currentUser, setCurrentUser] = useState<UserCheck | null>(null);
  const [showedUsers, setShowedUsers] = useState<UserCheck[]>([]);
  const [hiddenUsers, setHiddenUsers] = useState<UserCheck[]>([]);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [leftToPay, setLeftToPay] = useState(0);
  // const [width, setWidth] = useState(0);

  const contentRef = useRef<View>(null);
  const contentHeight = useSharedValue<number>(0);
  const animatedStyle = useAnimatedStyle(() => ({
    height: contentHeight.value,
  }));

  const toggleExpand = () => {
    contentRef.current?.measure(
      (x: number, y: number, width: number, height: number) => {
        contentHeight.value = withTiming(expanded ? 0 : height, {
          duration: 500,
          easing: Easing.bezier(0.25, 0.1, 0.25, 1),
        });
      }
    );
    setExpanded(!expanded);
  };

  const mapTransactionToUser = (t: Transaction): UserCheck => ({
    name: t.guestFullName,
    image: USER_MAP[t.guestFullName],
    sum: t.products,
    bonus: t.bonus,
    status: t.success ? "paid" : "inProgress",
  });

  useEffect(() => {
    const totalWithDiscount = total - discount;
    setLeftToPay(totalWithDiscount - paid(transactions));
    console.log({ totalWithDiscount, paid: paid(transactions) });

    const { user, users } = transactions.reduce(
      (acc: { user: UserCheck | null; users: UserCheck[] }, t: Transaction) => {
        if (t.guestUuid === currentGuest.uuid) {
          acc.user = mapTransactionToUser(t);
        } else if (t.products > 0) {
          acc.users.push(mapTransactionToUser(t));
        }
        return acc;
      },
      { user: null, users: [] }
    );

    setCurrentUser(user);
    setShowedUsers(users.slice(0, SHOWED_USERS_AMOUNT));
    if (users.length > SHOWED_USERS_AMOUNT) {
      setHiddenUsers(users.slice(SHOWED_USERS_AMOUNT, 6));
    }
  }, [transactions]);

  return (
    <KeyboardAvoidingView behavior="padding" style={styles.container}>
      <ScrollView scrollEnabled style={{ padding: 16 }}>
        <View style={styles.backButton}>
          <BackButton
            onPress={() => {
              resetJWT();
              getOrder({ order: getSearch(), reset: true })
                .unwrap()
                .then(async (data) => {
                  updateJWT(data.jwt, data.order.uuid);
                  dispatch(setRating(0));
                  dispatch(setComment(""));
                  await cancelLoyalty(getSearch());
                  dispatch(setSection(null));
                  navigation.navigate("Main");
                });
            }}
          />
          <AppText size={16} height={24}>
            Вернуться
          </AppText>
        </View>

        <PostPayInfoCheck
          isTipsOnly={currentTransaction.products <= 0}
          style={styles.infoCheck}
          leftToPay={leftToPay}
        />

        <PostPayUserCard
          isTipsOnly={currentTransaction.products <= 0}
          style={styles.postPayOwnerCard}
          user={currentUser}
          owner
        />

        {currentTransaction.products > 0 && transactions.length > 1 && (
          <>
            <AppText
              style={styles.userListTitle}
              size={16}
              height={24}
              color={ThemeColor.DARK}
            >
              Другие участники
            </AppText>

            <View style={styles.userListWrap}>
              <View style={styles.userList}>
                {showedUsers.map((user, index) => (
                  <PostPayUserCard
                    key={index}
                    style={styles.postPayUserCard}
                    user={user}
                  />
                ))}
                <Animated.View style={[animatedStyle]}>
                  <View ref={contentRef}>
                    {hiddenUsers.map((user, index) => (
                      <PostPayUserCard
                        key={index}
                        style={styles.postPayUserCard}
                        user={user}
                      />
                    ))}
                  </View>
                </Animated.View>
              </View>
              {!!hiddenUsers.length && (
                <AppButton type="link" size="large" onPress={toggleExpand}>
                  {expanded ? "Свернуть" : "Смотреть все"}
                </AppButton>
              )}
            </View>
          </>
        )}

        <FeedbackBlock
          title="Ваша оценка"
          withSendButton
          readonly={feedbackSent}
        />
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: "100vw",
    maxHeight: "100svh",
    backgroundColor: ThemeColor.GREY,
    position: "relative",
  },
  backButton: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 34,
  },
  infoCheck: {
    marginBottom: 20,
  },
  postPayOwnerCard: {
    marginBottom: 40,
  },
  userListTitle: {
    marginBottom: 12,
  },
  userListWrap: {
    marginBottom: 32,
  },
  userList: {
    overflow: "hidden",
  },
  postPayUserCard: {
    marginBottom: 8,
  },
});
