import { useState } from "react";
import { StyleSheet, View } from "react-native";

import { PaymentDrawer } from "./PaymentDrawer";
import { PaymentFullScreenModal } from "./PaymentFullScreenModal";
import { PaymentTypeCard } from "./PaymentTypeCard";
import { PaymentTypeChoose } from "./PaymentTypeChoose";
import { PaymentTypeSBP } from "./PaymentTypeSBP";
import { AppButton } from "./ui/AppButton";
import { useAppDispatch, useAppSelector } from "../store";
import esApi from "../store/esApi";
import { setLoyaltyLogin } from "../store/paymentSlice";
import { ThemeColor } from "../theme";
import { currencyFormat } from "../utils/currency-format";
import { getSearch } from "../utils/useHelper";

export type BlockState = "choose" | "card" | "sbp";

export const Payment = () => {
  const { productsSum, tipsSum } = useAppSelector((state) => state.order);
  const { section } = useAppSelector((state) => state.app);
  const [showModal, setShowModal] = useState(false);
  // TODO: temp. remove after fixing navigation
  const [block, setBlock] = useState<BlockState>("choose");

  const [setAll, { isLoading }] = esApi.useSetGuestProductAllMutation();
  const [timeout] = esApi.usePayTimeoutMutation();

  const resetModal = () => {
    setShowModal(false);
    setBlock("choose");

    if (section === "PayAll") {
      timeout(getSearch());
    }
  };

  const handleChoosePaymentType = (type: "card" | "sbp") => {
    setBlock(type);
  };

  const { loyalSystem, restaurantId } = useAppSelector((state) => state.order);
  const { loyaltyCreds } = useAppSelector((state) => state.payment);

  const [checkLoyalty] = esApi.useCheckLoyaltyMutation({
    fixedCacheKey: "loyalty",
  });

  const dispatch = useAppDispatch();

  const handlePaymentClick = async () => {
    if (section === "PayAll") {
      await setAll(getSearch());
    }

    setShowModal(true);

    if (loyaltyCreds) {
      await checkLoyaltyBasedOnSystem();
    }
  };

  const checkLoyaltyBasedOnSystem = async () => {
    let credential = null;

    if (loyalSystem === "UDS") {
      credential = loyaltyCreds.UDS?.id;
    } else if (loyalSystem) {
      credential = loyaltyCreds[loyalSystem][restaurantId];
    }

    if (credential && loyalSystem !== "UDS") {
      const { found } = await checkLoyalty({
        order: getSearch(),
        loyalParam1: "saved",
        credential,
      }).unwrap();

      if (found) {
        dispatch(setLoyaltyLogin(true));
      }
    }
  };

  return (
    <View style={styles.container}>
      <AppButton
        ariaLable="button-payment"
        size="large"
        disabled={(!productsSum && !tipsSum) || isLoading}
        onPress={handlePaymentClick}
      >
        Оплатить {currencyFormat(productsSum + tipsSum)}
      </AppButton>

      <PaymentFullScreenModal
        visible={showModal && block === "sbp"}
        block="sbp"
      >
        <PaymentTypeSBP onClose={resetModal} />
      </PaymentFullScreenModal>

      <PaymentDrawer
        visible={showModal && block === "choose"}
        onVisibleChange={resetModal}
        block="choose"
      >
        <PaymentTypeChoose onChoose={handleChoosePaymentType} />
      </PaymentDrawer>

      <PaymentDrawer
        bgColor={ThemeColor.WHITE}
        visible={showModal && block === "card"}
        onVisibleChange={resetModal}
        block="card"
      >
        <PaymentTypeCard onClose={resetModal} />
      </PaymentDrawer>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 90,
    marginBottom: 80,
    paddingHorizontal: 20,
  },
});
