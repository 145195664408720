import { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";

import GuestIcon from "./icons/GuestIcon";
import CupIcon from "./icons/CupIcon";
import GuestsIcon from "./icons/GuestsIcon";
import { AppText } from "./ui/AppText";
import { BackButton } from "./ui/BackButton";
import { NavigationButton } from "./ui/NavigationButton";
import { OrderLoadingChip } from "./ui/OrderLoadingChip";
import { OrderPaidFullChip } from "./ui/OrderPaidFullChip";
import { OtherUserPaying } from "./ui/OtherUserPaying";
import { useAppDispatch, useAppSelector } from "../store";
import { setSection } from "../store/appSlice";
import esApi from "../store/esApi";
import { paid } from "../utils/helpers";
import { useHelper } from "../utils/useHelper";
import { ThemeColor } from "../theme";

interface NavigationPanelProps {
  totalAmount: number;
}

export const NavigationPanel: React.FC<NavigationPanelProps> = ({
  totalAmount,
}) => {
  const dispatch = useAppDispatch();

  const { getSearch } = useHelper();

  const { data: orderSession } = esApi.endpoints.getOrder.useQueryState({
    order: getSearch(),
    reset: true,
  });

  const [payTimeout] = esApi.usePayTimeoutMutation();

  const { section } = useAppSelector((state) => state.app);

  const [paidStatus, setPaidStatus] = useState<
    "part" | "full" | "none" | "other"
  >("none");

  const {
    transactions,
    total,
    discount,
    currentGuest,
    payDisabled,
    closed,
    modelService,
    paymentModel,
    guestProducts,
    products,
  } = useAppSelector((state) => state.order);

  const { guestAllUuid } = useAppSelector((state) => state.payment);

  useEffect(() => {
    if (paid(transactions) === 0 && !payDisabled && !closed) {
      setPaidStatus("none");
    } else if (
      total - discount === paid(transactions) ||
      payDisabled ||
      closed
    ) {
      setPaidStatus("full");
    }

    if (
      transactions.some(
        (t) =>
          t.inProgress === true &&
          t.products > 0 &&
          t.guestUuid !== currentGuest.uuid
      ) &&
      total - discount !== paid(transactions) &&
      !guestAllUuid
    ) {
      setPaidStatus("part");
      if (section === "PayAll") {
        dispatch(setSection("Split"));
      }
    }
  }, [transactions, guestProducts]);

  useEffect(() => {
    if (guestAllUuid && guestAllUuid !== currentGuest.uuid) {
      const transaction = transactions.find(
        ({ guestUuid }) => guestUuid === guestAllUuid
      );

      setPaidStatus("other");
      if (section !== "Tips") {
        dispatch(setSection(null));
      }

      if (transaction?.success || closed) {
        setPaidStatus("full");
        if (section !== "Tips") {
          dispatch(setSection(null));
        }
      }
    }
  }, [transactions, guestAllUuid]);

  useEffect(() => {
    if (paidStatus === "full") {
      dispatch(setSection("Tips"));
    }
  }, [paidStatus]);

  return (
    <>
      {orderSession ? (
        <View style={styles.container}>
          {section && (
            <View style={styles.backButton}>
              <BackButton
                onPress={() => {
                  payTimeout(getSearch())
                    .unwrap()
                    .then(() => {
                      dispatch(setSection(null));
                    });
                }}
              />
              <AppText size={16} height={24}>
                Вернуться
              </AppText>
            </View>
          )}
          {paidStatus === "full" && <OrderPaidFullChip />}
          <>
            {!section && (
              <>
                {paidStatus === "other" && guestAllUuid ? (
                  <OtherUserPaying guestAllUuid={guestAllUuid} />
                ) : (
                  <>
                    {modelService !== "Tips" && products.length > 0 && (
                      <View
                        style={{
                          flexGrow: 1,
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          gap: 12,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <NavigationButton
                          name="Оплатить счет целиком"
                          section="PayAll"
                          total={totalAmount}
                          icon={<GuestIcon />}
                          disabled={
                            !(
                              paidStatus === "none" &&
                              (paymentModel === "AllAndPartial" ||
                                paymentModel === "OnlyAll")
                            )
                          }
                        />
                        <NavigationButton
                          name="Разделить счет на компанию"
                          section="Split"
                          icon={<GuestsIcon />}
                          disabled={
                            !(
                              (paidStatus === "none" ||
                                paidStatus === "part") &&
                              (paymentModel === "AllAndPartial" ||
                                paymentModel === "OnlyPartial")
                            )
                          }
                        />
                      </View>
                    )}
                  </>
                )}
                {modelService !== "Pays" && modelService !== "TipsInPays" && (
                  <NavigationButton
                    direction="horizontal"
                    height={76}
                    name="Чаевые сотруднику"
                    section="Tips"
                    icon={<CupIcon />}
                  />
                )}
              </>
            )}
          </>
        </View>
      ) : (
        <OrderLoadingChip />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 24,
    gap: 12,
  },
  backButton: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
});
