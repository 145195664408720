import { Image, StyleSheet, Text, View } from "react-native";

import { Section } from "../components/ui/Section";
import { useAppSelector } from "../store";
import { ThemeColor, ThemeFont } from "../theme";
import { baseUrl } from "../utils/helpers";

export const WaiterBlock = () => {
  const { waiterWishName, waiterWishText, waiterName, waiterAvatar } =
    useAppSelector((state) => state.order);

  return (
    <Section style={styles.container}>
      <View
        style={{
          ...styles.header,
          borderBottomLeftRadius: waiterWishText ? 0 : 24,
          borderBottomRightRadius: waiterWishText ? 0 : 24,
        }}
      >
        <View style={styles.imageBox}>
          <Image
            style={styles.image}
            source={
              waiterAvatar
                ? baseUrl + `../tipswish/getavatar/${waiterAvatar}`
                : require("../../assets/img/fruits/pomegranate.png")
            }
          />
        </View>
        <View>
          <Text style={styles.title}>{waiterWishName ?? waiterName}</Text>
          <Text style={styles.subtitle}>Сотрудник</Text>
        </View>
      </View>
      {waiterWishText && (
        <View style={styles.body}>
          <Text style={styles.bodyTitle}>Цель</Text>
          <Text style={styles.bodyText}>{waiterWishText}</Text>
        </View>
      )}
    </Section>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 0,
    marginBottom: 12,
  },
  header: {
    backgroundColor: ThemeColor.GREY_2,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    paddingHorizontal: 20,
    paddingVertical: 17,
    flexDirection: "row",
  },
  imageBox: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: "#F187A7", // TODO: add to UI kit
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12,
  },
  image: {
    borderRadius: 20,
    width: 40,
    height: 40,
  },
  title: {
    fontFamily: ThemeFont.SEMIBOLD,
    fontSize: 16,
    letterSpacing: 0.5,
    marginBottom: 2,
  },
  subtitle: {
    fontFamily: ThemeFont.MEDIUM,
    fontSize: 14,
  },
  body: {
    paddingTop: 12,
    paddingHorizontal: 20,
    paddingBottom: 20,
    alignItems: "flex-start",
  },
  bodyTitle: {
    backgroundColor: ThemeColor.BLACK,
    color: ThemeColor.WHITE,
    fontFamily: ThemeFont.REGULAR,
    fontSize: 12,
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 8,
    marginBottom: 8,
  },
  bodyText: {
    fontFamily: ThemeFont.REGULAR,
    fontSize: 14,
    lineHeight: 21,
    color: ThemeColor.BLACK,
  },
});
