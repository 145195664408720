import { Pressable, StyleSheet, View, ViewStyle } from "react-native";

import { AppText } from "./AppText";
import { useAppDispatch } from "../../store";
import { NavSection, setSection } from "../../store/appSlice";
import { ThemeColor } from "../../theme";
import { currencyFormat } from "../../utils/currency-format";

interface NavigationButtonProps {
  name: string;
  description?: string;
  icon: JSX.Element;
  section: NavSection;
  total?: number;
  disabled?: boolean;
  direction?: "horizontal" | "vertical";
  height?: number;
}

export const NavigationButton: React.FC<NavigationButtonProps> = ({
  name,
  icon,
  total,
  section,
  disabled,
  direction = "vertical",
  height = 140,
}) => {
  const dispatch = useAppDispatch();

  return (
    <Pressable
      style={({ pressed }) => [
        {
          ...styles.container,
          height,
          backgroundColor:
            pressed || disabled ? ThemeColor.GREY : ThemeColor.WHITE,
        },
      ]}
      disabled={disabled}
      onPress={() => dispatch(setSection(section))}
    >
      <View
        style={[
          styles.content,
          direction === "horizontal" && {
            flexDirection: "row",
            alignItems: "center",
          },
        ]}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {icon}
          {total && !disabled && (
            <AppText style={styles.total}>{currencyFormat(total)}</AppText>
          )}
        </View>
        <AppText
          style={[
            { maxWidth: 130 },
            direction === "horizontal" && { maxWidth: "max-content" },
          ]}
          size={16}
          weight={600}
          height={21}
          color={disabled ? ThemeColor.GREY_4 : ThemeColor.BLACK}
        >
          {name}
        </AppText>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    padding: 16,
    borderRadius: 24,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: ThemeColor.GREY_3,
    backgroundColor: ThemeColor.WHITE,
    justifyContent: "center",
    alignItems: "flex-start",
    shadowColor: ThemeColor.SHADOW,
    shadowOpacity: 0.05,
    shadowRadius: 10,
    shadowOffset: { width: 2, height: 2 },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  total: {
    backgroundColor: ThemeColor.BLACK,
    color: ThemeColor.WHITE,
    width: "fit-content",
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 20,
  },
});
