import { PropsWithChildren } from "react";
import { StyleSheet, View } from "react-native";

import { ThemeColor } from "../../theme";

type Props = {
  style?: StyleSheet.NamedStyles<unknown>;
};

export const Section = ({ children, style }: PropsWithChildren<Props>) => (
  <View style={[styles.container, style]}>{children}</View>
);

const styles = StyleSheet.create({
  container: {
    backgroundColor: ThemeColor.WHITE,
    borderRadius: 24,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: ThemeColor.GREY_3,
    shadowColor: ThemeColor.SHADOW,
    shadowOpacity: 0.05,
    shadowRadius: 10,
    shadowOffset: {
      width: 2,
      height: 2,
    },
    padding: 20,
  },
});
