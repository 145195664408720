import { useState } from "react";
import {
  View,
  StyleSheet,
  ImageBackground,
  ActivityIndicator,
} from "react-native";

import { LoyaltyLoginDrawer } from "./LoyaltyLoginDrawer/LoyaltyLoginDrawer";
import { AppButton } from "./ui/AppButton";
import { AppSwitch } from "./ui/AppSwitch";
import { AppText } from "./ui/AppText";
import { useAppDispatch, useAppSelector } from "../store";
import esApi from "../store/esApi";
import { setUseBonuses } from "../store/paymentSlice";
import { ThemeColor } from "../theme";
import { useHelper } from "../utils/useHelper";

const Loyalty = () => {
  const [modal, setModal] = useState(false);

  const { getSearch } = useHelper();

  const dispatch = useAppDispatch();

  const [setBonus] = esApi.useSetBonusMutation();

  const { loyalSystem } = useAppSelector((state) => state.order);

  const [_, { isLoading }] = esApi.useCheckLoyaltyMutation({
    fixedCacheKey: "loyalty",
  });

  const [cancelLoyalty, { isLoading: cancelIsLoading }] =
    esApi.useCancelLoyaltyMutation();

  const { maxAmount, loyaltyLogin, useBonuses } = useAppSelector(
    (state) => state.payment
  );

  return (
    <>
      <View style={styles.container}>
        {isLoading || cancelIsLoading ? (
          <View
            style={{
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ActivityIndicator size={40} />
          </View>
        ) : (
          <>
            {loyaltyLogin ? (
              <View
                style={{
                  width: "100%",
                  flexDirection: "column",
                  display: "flex",
                  gap: 20,
                }}
              >
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <AppText size={16} height={24} weight={600} color="white">
                    Доступно к списанию
                  </AppText>
                  <AppText
                    color="white"
                    onPress={() => cancelLoyalty(getSearch())}
                  >
                    Выйти
                  </AppText>
                </View>
                <View
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: 12,
                  }}
                >
                  <AppText size={32} height={24} weight={600} color="white">
                    {maxAmount}
                  </AppText>
                  <AppText size={16} height={24} weight={600} color="white">
                    бонусов
                  </AppText>
                </View>
                <View
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <AppText size={16} height={24} weight={600} color="white">
                    списать баллы
                  </AppText>
                  <AppSwitch
                    disabled={maxAmount === 0}
                    value={useBonuses}
                    onValueChange={(value) => {
                      setBonus({
                        order: getSearch(),
                        value: value ? maxAmount : 0,
                      });
                      dispatch(setUseBonuses(value));
                    }}
                  />
                </View>
              </View>
            ) : (
              <>
                <AppText
                  size={16}
                  height={24}
                  weight={600}
                  color="white"
                  style={{ width: 250, textAlign: "center" }}
                >
                  Воспользоваться системой лояльности
                </AppText>
                <AppButton type="secondary" onPress={() => setModal(true)}>
                  {loyalSystem === "UDS" ? "Ввести код UDS" : "Войти"}
                </AppButton>
              </>
            )}
          </>
        )}
        <ImageBackground
          style={styles.background}
          source={require("../../assets/img/gradient-bg.svg")}
          resizeMode="cover"
          resizeMethod="resize"
        />
      </View>
      {loyalSystem && (
        <LoyaltyLoginDrawer
          loyalSystem={loyalSystem}
          visible={modal}
          onVisibleChange={setModal}
        />
      )}
    </>
  );
};

export default Loyalty;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 18,
    backgroundColor: ThemeColor.WHITE,
    borderRadius: 20,
    flexDirection: "column",
    alignItems: "center",
    marginTop: 14,
    gap: 14,
    minHeight: 155,
  },
  background: {
    borderRadius: 20,
    height: "100%",
    width: "100%",
    overflow: "hidden",
    position: "absolute",
    top: 0,
    zIndex: -1,
  },
});
