import { StyleSheet } from "react-native";

import Default from "./Default";
import NoIntegration from "./NoIntegration";
import NoProducts from "./NoProducts";
import { useAppSelector } from "../../store";
import { ThemeFont } from "../../theme";

export interface TipsBlockProps {
  totalSum: number;
  initialTip?: number | null;
  tips: number[];
}

export function TipsBlock({ totalSum, initialTip, tips }: TipsBlockProps) {
  const { fake, modelService } = useAppSelector((state) => state.order);
  const { section } = useAppSelector((state) => state.app);

  if (modelService === "Pays") {
    return null;
  }

  if (fake) {
    return (
      <NoIntegration totalSum={totalSum} tips={tips} initialTip={initialTip} />
    );
  }

  return (
    <>
      {section === "Tips" ? (
        <NoProducts totalSum={totalSum} tips={tips} initialTip={initialTip} />
      ) : (
        <Default totalSum={totalSum} tips={tips} initialTip={initialTip} />
      )}
    </>
  );
}

export const styles = StyleSheet.create({
  section: {
    marginBottom: 12,
  },
  editBox: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginTop: 20,
    marginBottom: 12,
  },
  editInput: {
    fontFamily: ThemeFont.BOLD,
    fontSize: 20,
    maxWidth: "50%",
    flexWrap: "nowrap",
    outlineStyle: "none",
  },
  buttonsBox: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
