import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";
const BillIcon = (props: SvgProps) => (
  <Svg width={18} height={20} fill="none" {...props}>
    <Path
      stroke={props.stroke || "#AAA"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M17 19V3a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v16l2.5-2 3 2L9 17l2.5 2 3-2 2.5 2Z"
    />
  </Svg>
);
export default BillIcon;
