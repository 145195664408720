import { useState } from "react";
import {
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  ViewStyle,
} from "react-native";

import { ThemeColor, ThemeFont } from "../../theme";
import { EditIcon } from "../icons/EditIcon";
import { ResetIcon } from "../icons/ResetIcon";

type ButtonType = "reset" | "edit";
type ButtonTypeMap = Record<ButtonType, StyleSheet.NamedStyles<unknown>>;
type ButtonContentMap = Record<ButtonType, string>;

type Props = {
  type?: ButtonType;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
  onPress?: () => void;
};

const BTN_TYPE_MAP: ButtonTypeMap = {
  reset: {
    backgroundColor: ThemeColor.WHITE,
    borderColor: ThemeColor.GREY_3,
  },
  edit: {
    backgroundColor: ThemeColor.TRANSPARENT,
    borderColor: ThemeColor.GREY_3,
  },
};

const CONTENT_TYPE_MAP: ButtonTypeMap = {
  reset: { color: ThemeColor.BLACK },
  edit: { color: ThemeColor.BLACK },
};

const IMAGE_MAP = {
  reset: ResetIcon,
  edit: EditIcon,
};

const TEXT_MAP: ButtonContentMap = {
  reset: "Сброс",
  edit: "Своя сумма",
};

const BTN_ACTIVE_MAP: ButtonTypeMap = {
  reset: {
    backgroundColor: ThemeColor.BLACK,
    borderColor: ThemeColor.BLACK,
  },
  edit: {
    backgroundColor: ThemeColor.TRANSPARENT,
    borderColor: ThemeColor.BLACK,
  },
};

const TEXT_ACTIVE_MAP: ButtonTypeMap = {
  reset: { color: ThemeColor.WHITE },
  edit: { color: ThemeColor.BLACK },
};

export const SpecialButton = ({
  type = "reset",
  style,
  disabled,
  onPress,
}: Props) => {
  const [pressed, setPressed] = useState(false);

  const ButtonIcon = IMAGE_MAP[type];

  return (
    <Pressable
      style={[
        styles.buttonBody,
        style,
        BTN_TYPE_MAP[type],
        pressed && BTN_ACTIVE_MAP[type],
      ]}
      onPressIn={() => setPressed(true)}
      onPressOut={() => setPressed(false)}
      onPress={onPress}
      disabled={disabled ?? false}
    >
      <ButtonIcon
        style={[
          styles.buttonImage,
          CONTENT_TYPE_MAP[type],
          pressed && TEXT_ACTIVE_MAP[type],
        ]}
      />
      <Text
        style={[
          styles.buttonText,
          CONTENT_TYPE_MAP[type],
          pressed && TEXT_ACTIVE_MAP[type],
        ]}
        numberOfLines={1}
      >
        {TEXT_MAP[type]}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  buttonBody: {
    height: 34,
    width: 123,
    borderRadius: 12,
    borderWidth: 1,
    borderStyle: "solid",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: ThemeColor.SHADOW,
    shadowOpacity: 0.05,
    shadowRadius: 10,
    shadowOffset: {
      width: 2,
      height: 2,
    },
  },
  buttonImage: {
    width: 11,
    height: 11,
    marginRight: 8,
  },
  buttonText: {
    fontFamily: ThemeFont.MEDIUM,
    fontSize: 12,
    pointerEvents: "none",
  },
});
