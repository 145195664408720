import { PropsWithChildren } from "react";
import { Image, StyleSheet, View } from "react-native";

import { AppText } from "./AppText";
import { PaymentButton } from "./PaymentButton";

type Props = {
  compact?: boolean;
  onPress: VoidFunction;
};

export const NewCardButton = ({
  compact = false,
  onPress,
}: PropsWithChildren<Props>) => {
  return (
    <PaymentButton
      key="new-card"
      type="filled"
      style={{
        width: compact ? 16 : 150,
        backgroundColor: "none",
        padding: compact ? 20 : 10,
        borderStyle: "dashed",
      }}
      onPress={onPress}
    >
      <View style={styles.addCardBtn}>
        <Image
          style={styles.addCardBtnImg}
          source={require("../../../assets/img/plus.svg")}
        />
        {!compact && (
          <AppText
            style={styles.addCardBtnText}
            weight={500}
            size={14}
            height={21}
          >
            Оплатить картой
          </AppText>
        )}
      </View>
    </PaymentButton>
  );
};

const styles = StyleSheet.create({
  addCardBtn: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  addCardBtnImg: {
    width: 19,
    height: 19,
  },
  addCardBtnText: {
    marginTop: 10,
    textAlign: "center",
  },
});
