import { useState } from "react";
import { Image, Pressable, StyleSheet } from "react-native";

import { ThemeColor } from "../../theme";

type Props = {
  onPress: () => void;
};

export const BackButton = ({ onPress }: Props) => {
  const [pressed, setPressed] = useState(false);

  return (
    <Pressable
      style={[
        styles.container,
        pressed && { backgroundColor: ThemeColor.GREY_5 },
      ]}
      onPress={onPress}
      onPressIn={() => setPressed(true)}
      onPressOut={() => setPressed(false)}
      testID="backbutton"
    >
      <Image
        style={styles.image}
        source={require("../../../assets/img/chevron-left.svg")}
      />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 32,
    height: 32,
    borderRadius: 8,
    backgroundColor: ThemeColor.WHITE,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12,
    shadowColor: ThemeColor.SHADOW,
    shadowOpacity: 0.05,
    shadowRadius: 10,
    shadowOffset: {
      width: 2,
      height: 2,
    },
  },
  image: {
    width: 9,
    height: 14,
  },
});
