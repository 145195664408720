import { ImageBackground, StyleSheet, Text } from "react-native";

import { CloseButton } from "./CloseButton";
import { Section } from "./Section";
import { ThemeColor, ThemeFont } from "../../theme";

type Props = {
  onClose: () => void;
};

export const HintBanner = ({ onClose }: Props) => {
  return (
    <Section style={styles.section}>
      <Text style={styles.title}>Подсказка</Text>
      <Text style={styles.subtitle}>
        Можно разделить одно блюдо на несколько человек
      </Text>

      <CloseButton style={styles.closeBtn} onPress={onClose} />
      <ImageBackground
        style={styles.background}
        source={require("../../../assets/img/hint-bg.svg")}
        resizeMode="cover"
        resizeMethod="resize"
      />
    </Section>
  );
};

const styles = StyleSheet.create({
  section: {
    marginBottom: 20,
    position: "relative",
    maxWidth: 350,
  },
  title: {
    fontFamily: ThemeFont.MEDIUM,
    fontSize: 16,
    color: ThemeColor.BLACK,
    lineHeight: 24,
    marginBottom: 4,
  },
  subtitle: {
    fontFamily: ThemeFont.REGULAR,
    fontSize: 14,
    color: ThemeColor.BLACK,
    lineHeight: 21,
    width: "70%",
  },
  closeBtn: {
    position: "absolute",
    right: 12,
    top: 12,
    zIndex: 1,
  },
  background: {
    height: "100%",
    width: 125,
    borderTopRightRadius: 24,
    borderBottomRightRadius: 24,
    overflow: "hidden",
    position: "absolute",
    right: -1,
    top: 0,
  },
});
