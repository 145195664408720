import { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

import { ThemeColor } from "../../theme";

const RADIUS = 9;
const DIAMETER = RADIUS * 2;

export const TornCheckEdge = () => {
  const [array, setArray] = useState<number[]>([]);
  const [componentWidth, setComponentWidth] = useState(0);

  useEffect(() => {
    const amount = Math.floor(componentWidth / (DIAMETER + RADIUS - 2));
    const items = Array.from({ length: amount }, (_, i) => i);
    setArray(items);
  }, [componentWidth]);

  return (
    <View
      style={[styles.container, { width: componentWidth }]}
      onLayout={({
        nativeEvent: {
          layout: { width },
        },
      }) => setComponentWidth(width)}
    >
      <View style={styles.top} />
      <View style={styles.bottom}>
        {array.map((i) => (
          <View key={i} style={styles.semiholeWrap}>
            <View style={styles.semihole} />
          </View>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  top: {
    flex: 1,
    backgroundColor: ThemeColor.WHITE,
    shadowColor: ThemeColor.SHADOW,
    shadowOpacity: 0.05,
    shadowRadius: 10,
    shadowOffset: {
      width: 2,
      height: 2,
    },
  },
  bottom: {
    flexDirection: "row",
    justifyContent: "space-between",
    overflow: "hidden",
  },
  semiholeWrap: {
    width: DIAMETER,
    height: RADIUS,
    position: "relative",
  },
  semihole: {
    width: DIAMETER,
    height: RADIUS,
    borderTopLeftRadius: DIAMETER,
    borderTopRightRadius: DIAMETER,
    borderWidth: RADIUS,
    borderColor: ThemeColor.WHITE,
    borderBottomWidth: 0,
    position: "absolute",
    boxSizing: "content-box",
    bottom: 0,
    left: -RADIUS,
  },
});
