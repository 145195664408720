import { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import Toast from "react-native-toast-message";

import { ConfirmationModal } from "../components/ConfirmationModal";
import { SharedProductItem } from "../components/SharedProductItem";
import { SpecialButton } from "../components/ui/SpecialButton";
import { useAppDispatch, useAppSelector } from "../store";
import esApi from "../store/esApi";
import { setTipsSum } from "../store/orderSlice";
import { SharedProduct } from "../types/common";
import { useHelper } from "../utils/useHelper";

interface ShareBillBlockProps {
  sharedProducts: SharedProduct[];
}

export const ShareBillBlock: React.FC<ShareBillBlockProps> = ({
  sharedProducts,
}) => {
  const { getSearch } = useHelper();
  const [resetGuestProducts, { isLoading }] =
    esApi.useResetGuestProductsMutation();

  const { tipsPercentage } = useAppSelector((state) => state.payment);

  const dispatch = useAppDispatch();

  const [openedModal, setOpenedModal] = useState(false);

  const handleResetConfirmation = () => {
    resetGuestProducts(getSearch())
      .unwrap()
      .then(() => {
        if (tipsPercentage) {
          dispatch(setTipsSum(0));
        }
        setOpenedModal(false);
      });
  };

  const handleResetCancel = () => {
    setOpenedModal(false);
  };

  useEffect(() => {
    Toast.show({
      type: "hint",
      visibilityTime: 5000,
    });
  }, []);

  return (
    <View style={styles.container}>
      {sharedProducts?.map((product) => (
        <SharedProductItem key={product.productUuid} product={product} />
      ))}

      <SpecialButton type="reset" onPress={() => setOpenedModal(true)} />

      <ConfirmationModal
        isLoading={isLoading}
        title="Вы подтверждаете сброс позиций?"
        subtitle="После подтверждения, все выбранные позиции сбросятся"
        showModal={openedModal}
        onConfirm={handleResetConfirmation}
        onCancel={handleResetCancel}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 32,
  },
});
