import { PropsWithChildren, useState } from "react";
import {
  LayoutChangeEvent,
  Pressable,
  StyleProp,
  StyleSheet,
  TextStyle,
  ViewStyle,
} from "react-native";

import { AppText } from "./AppText";
import { ThemeColor, ThemeFont } from "../../theme";

type ButtonType = "primary" | "secondary" | "outline" | "link";
type ButtonTypeMap = Record<ButtonType, ViewStyle | TextStyle>;

type ButtonSize = "small" | "medium" | "big" | "large";
type ButtonSizeMap = Record<ButtonSize, ViewStyle | TextStyle>;

type Props = {
  size?: ButtonSize;
  type?: ButtonType;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  ariaLable?: string;
  onPress?: () => void;
  onLayout?: (event: LayoutChangeEvent) => void;
};

const BTN_SIZE_MAP: ButtonSizeMap = {
  small: { height: 42, width: 122, borderRadius: 21 },
  medium: { height: 44, width: 202, borderRadius: 22 },
  big: { height: 52, borderRadius: 26 },
  large: { height: 57, borderRadius: 30 },
};

const TEXT_SIZE_MAP: ButtonSizeMap = {
  small: { fontSize: 14 },
  medium: { fontSize: 14 },
  big: { fontSize: 14 },
  large: { fontSize: 16 },
};

const BTN_TYPE_MAP: ButtonTypeMap = {
  primary: {
    backgroundColor: ThemeColor.BLACK,
    borderColor: ThemeColor.BLACK,
  },
  secondary: {
    backgroundColor: ThemeColor.WHITE,
    borderColor: ThemeColor.GREY_3,
  },
  outline: {
    backgroundColor: ThemeColor.TRANSPARENT,
    borderColor: ThemeColor.BLACK,
  },
  link: {
    backgroundColor: ThemeColor.TRANSPARENT,
    borderColor: ThemeColor.TRANSPARENT,
  },
};

const TEXT_TYPE_MAP: ButtonTypeMap = {
  primary: { color: ThemeColor.WHITE },
  secondary: { color: ThemeColor.BLACK },
  outline: { color: ThemeColor.BLACK },
  link: { color: ThemeColor.BLACK },
};

const BTN_ACTIVE_MAP: ButtonTypeMap = {
  primary: {
    backgroundColor: "#090909", // TODO: add to UI kit
    borderColor: "#090909", // TODO: add to UI kit
  },
  secondary: {
    backgroundColor: ThemeColor.GREY_2,
    borderColor: ThemeColor.GREY_3,
  },
  outline: {
    backgroundColor: ThemeColor.BLACK,
    borderColor: ThemeColor.BLACK,
  },
  link: {
    backgroundColor: ThemeColor.TRANSPARENT,
    borderColor: ThemeColor.TRANSPARENT,
  },
};

const TEXT_ACTIVE_MAP: ButtonTypeMap = {
  primary: { color: ThemeColor.WHITE },
  secondary: { color: ThemeColor.BLACK },
  outline: { color: ThemeColor.WHITE },
  link: {
    fontFamily: ThemeFont.SEMIBOLD,
    color: ThemeColor.BLACK,
  },
};

const BTN_DISABLED_MAP: ButtonTypeMap = {
  primary: {
    backgroundColor: ThemeColor.GREY_2,
    borderColor: ThemeColor.GREY_3,
  },
  secondary: {
    backgroundColor: ThemeColor.GREY_2,
    borderColor: ThemeColor.GREY_3,
  },
  outline: {
    backgroundColor: ThemeColor.GREY_2,
    borderColor: ThemeColor.GREY_3,
  },
  link: {
    backgroundColor: ThemeColor.TRANSPARENT,
    borderColor: ThemeColor.TRANSPARENT,
  },
};

const TEXT_DISABLED_MAP: ButtonTypeMap = {
  primary: { color: ThemeColor.GREY_4 },
  secondary: { color: ThemeColor.GREY_4 },
  outline: { color: ThemeColor.GREY_4 },
  link: { color: ThemeColor.GREY_4 },
};

export const AppButton = ({
  size = "medium",
  type = "primary",
  disabled = false,
  style,
  children,
  onPress,
  onLayout,
  ariaLable,
}: PropsWithChildren<Props>) => {
  const [pressed, setPressed] = useState(false);

  return (
    <Pressable
      nativeID="paymentButton"
      aria-label={ariaLable}
      style={[
        styles.buttonBody,
        BTN_SIZE_MAP[size],
        BTN_TYPE_MAP[type],
        style,
        pressed && BTN_ACTIVE_MAP[type],
        disabled && BTN_DISABLED_MAP[type],
      ]}
      disabled={disabled}
      onPressIn={() => setPressed(true)}
      onPressOut={() => setPressed(false)}
      onPress={onPress}
      onLayout={onLayout}
    >
      <AppText
        style={[
          styles.buttonText,
          TEXT_SIZE_MAP[size],
          TEXT_TYPE_MAP[type],
          pressed && TEXT_ACTIVE_MAP[type],
          disabled && TEXT_DISABLED_MAP[type],
        ]}
        numberOfLines={1}
      >
        {children}
      </AppText>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  buttonBody: {
    borderWidth: 1,
    borderStyle: "solid",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: ThemeColor.SHADOW,
    shadowOpacity: 0.02,
    shadowRadius: 10,
    shadowOffset: {
      width: 2,
      height: 2,
    },
  },
  buttonText: {
    color: ThemeColor.WHITE,
    fontFamily: ThemeFont.MEDIUM,
    fontSize: 16,
    letterSpacing: 0.4,
    flexDirection: "row",
    pointerEvents: "none",
  },
});
