import { Image, StyleSheet, View, ViewStyle } from "react-native";

import { AppText } from "./AppText";
import { TornCheckEdge } from "./TornCheckEdge";
import { ThemeColor } from "../../theme";
import { currencyFormat } from "../../utils/currency-format";

interface PostPayInfoCheckProps {
  style?: ViewStyle;
  leftToPay: number;
  isTipsOnly: boolean;
}

export const PostPayInfoCheck: React.FC<PostPayInfoCheckProps> = ({
  style,
  leftToPay,
  isTipsOnly,
}) => (
  <View style={style}>
    <View style={styles.containerTop}>
      <Image
        style={styles.successImg}
        source={require("../../../assets/img/success-fill.svg")}
      />

      <AppText size={20} height={30}>
        Отлично!
      </AppText>
      <AppText
        style={styles.subtitle}
        size={14}
        height={21}
        weight={400}
        color={ThemeColor.GREY_4}
      >
        {!isTipsOnly &&
          (leftToPay > 0 ? "Ваша часть оплачена" : "Счет полностью оплачен")}
        {isTipsOnly && "Ваши чаевые успешно оплачены"}
      </AppText>

      {leftToPay > 0 && !isTipsOnly && (
        <View style={styles.leftToPayContainer}>
          <AppText weight={400} height={21}>
            Осталось
          </AppText>
          <AppText weight={600} height={27} size={18}>
            {currencyFormat(leftToPay)}
          </AppText>
        </View>
      )}
    </View>

    <View style={styles.containerBottom}>
      <View style={[styles.bottomCorner, { borderBottomLeftRadius: 20 }]} />
      <TornCheckEdge />
      <View style={[styles.bottomCorner, { borderBottomRightRadius: 20 }]} />
    </View>
  </View>
);

const styles = StyleSheet.create({
  containerTop: {
    alignItems: "center",
    backgroundColor: ThemeColor.WHITE,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    position: "relative",
    paddingTop: 30,
    paddingHorizontal: 30,
    paddingBottom: 12,
    shadowColor: ThemeColor.SHADOW,
    shadowOpacity: 0.05,
    shadowRadius: 10,
    shadowOffset: {
      width: 2,
      height: 2,
    },
  },
  successImg: {
    width: 44,
    height: 44,
    marginTop: -51,
    marginBottom: 8,
  },
  subtitle: {
    marginBottom: 16,
  },
  leftToPayContainer: {
    backgroundColor: ThemeColor.YELLOW_2,
    borderColor: ThemeColor.YELLOW_3,
    borderWidth: 1,
    borderStyle: "solid",
    width: 180,
    padding: 8,
    borderRadius: 20,
    alignItems: "center",
  },
  containerBottom: {
    flexDirection: "row",
  },
  bottomCorner: {
    width: 20,
    height: 20,
    backgroundColor: ThemeColor.WHITE,
  },
});
