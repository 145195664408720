import { useRef } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";

import { ThemeColor, ThemeFont } from "../../theme";
import { MenuCategory } from "../../types/common";
import { AppText } from "./AppText";

type Props = {
  item: MenuCategory;
  onCategoryPress: (id: number) => void;
  onLayout: (categoryId: number | undefined, y: number) => void | undefined;
};

export const MenuCategoryInHeader = ({
  item,
  onCategoryPress,
  onLayout,
}: Props) => {
  const topRef = useRef<null | View>(null);

  return (
    <>
      <Pressable
        ref={topRef}
        onPress={() => {
          onCategoryPress(item.id);
        }}
        onLayout={(event) => {
          topRef.current?.measureInWindow((x, y, w, h) => {
            onLayout(item.id, x);
          });
        }}
      >
        <View
          style={[
            styles.menuContainer,
            item.isActive ?? false
              ? styles.bottomLineActive
              : styles.bottomLine,
          ]}
        >
          <AppText
            style={item.isActive ?? false ? styles.nameActive : styles.name}
            size={16} weight={(item.isActive ?? false) ? 600 : 400}
          >
            {item.name}
          </AppText>
        </View>
      </Pressable>
      <View
        style={[styles.menuContainer, styles.emptyMenu, styles.bottomLine]}
      />
    </>
  );
};

const styles = StyleSheet.create({
  emptyMenu: {
    width: 20,
  },
  menuContainer: {
    backgroundColor: ThemeColor.GREY,
    height: 40,
  },
  bottomLine: {
    borderBottomWidth: 1,
    borderBottomColor: ThemeColor.GREY_3,
  },
  bottomLineActive: {
    borderBottomWidth: 1,
    borderBottomColor: ThemeColor.BLACK_4,
  },
  name: {
    color: ThemeColor.GREY_3,
  },
  nameActive: {
    color: ThemeColor.BLACK_4,
  },
});
