import { useEffect } from "react";
import { StyleSheet, View } from "react-native";

import { FeedbackBlock } from "../blocks/FeedbackBlock";
import TipsBlock from "../blocks/TipsBlock";
import { TotalCheck } from "../components/TotalCheck";
import { useAppDispatch, useAppSelector } from "../store";
import { setSection } from "../store/appSlice";
import { setProductsSum } from "../store/orderSlice";

export const PayAllBillScreen = () => {
  const { tips, total, products, transactions, discount } = useAppSelector(
    (state) => state.order
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (transactions.some((t) => t.success === true && t.products > 0)) {
      dispatch(setSection("Tips"));
    }
  }, [transactions]);

  useEffect(() => {
    dispatch(setProductsSum(total - discount));
  }, []);

  return (
    <View style={styles.container}>
      <TotalCheck products={products} total={total - discount} />
      <TipsBlock initialTip={tips[2]} totalSum={total - discount} tips={tips} />
      <FeedbackBlock title="Оценить обслуживание" />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minHeight: "fit-content",
  },
});
