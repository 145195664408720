import Svg, { SvgProps, Path, Mask, G } from "react-native-svg";

export const TwoGisIcon = (props: SvgProps) => (
  <Svg width={73} height={33} fill="none" {...props}>
    <Path
      fill="#1A1A1A"
      fillOpacity={0.01}
      fillRule="evenodd"
      d="m16.015 7.736-.683.007c-.47.012-.825.04-1.174.103-.425.075-.81.2-1.178.385a3.987 3.987 0 0 0-2.14 2.902 7.445 7.445 0 0 0-.104 1.157l-.005.191a24.52 24.52 0 0 0-.002.206l-.002.416v7.1c.002.189.004.351.008.508.013.459.042.81.104 1.156a3.973 3.973 0 0 0 2.141 2.902c.368.186.753.31 1.178.385.317.057.64.085 1.043.1l.303.006c.157.003.389.004.852.005h6.574l.706-.008a7.71 7.71 0 0 0 1.174-.103c.425-.075.81-.2 1.178-.385a3.998 3.998 0 0 0 1.75-1.732c.188-.365.314-.747.39-1.17a7.04 7.04 0 0 0 .1-1.035l.005-.122.005-.292.004-.806v-6.67l-.004-.36a19.327 19.327 0 0 0-.005-.293 7.449 7.449 0 0 0-.104-1.156 4.11 4.11 0 0 0-.391-1.17 3.99 3.99 0 0 0-1.75-1.732 4.204 4.204 0 0 0-1.178-.385 7.253 7.253 0 0 0-1.043-.1l-.347-.007-.09-.001-7.315-.002Z"
      clipRule="evenodd"
    />
    <Path
      fill="#1A1A1A"
      fillOpacity={0.01}
      fillRule="evenodd"
      d="m15.34 8.258.704-.007 7.337.003.25.004c.363.01.73.031 1.09.095.365.065.704.171 1.036.338a3.477 3.477 0 0 1 1.524 1.508 3.6 3.6 0 0 1 .341 1.026c.065.356.087.719.096 1.079.005.165.007.33.008.496v.294l.001 6.518-.001.589c-.001.165-.003.33-.008.495-.01.36-.03.723-.096 1.079a3.602 3.602 0 0 1-.341 1.026 3.449 3.449 0 0 1-1.524 1.508 3.697 3.697 0 0 1-1.036.337c-.36.064-.727.086-1.09.095l-1.096.009-6.947-.004c-.11-.001-.18-.003-.249-.005a7.344 7.344 0 0 1-1.09-.095 3.7 3.7 0 0 1-1.037-.338 3.482 3.482 0 0 1-1.524-1.507 3.614 3.614 0 0 1-.341-1.026 7.11 7.11 0 0 1-.096-1.079c-.005-.165-.006-.33-.007-.495l-.002-.295v-6.518c0-.196 0-.393.002-.588 0-.166.002-.331.007-.496.01-.36.031-.723.096-1.079.066-.361.172-.697.341-1.026a3.445 3.445 0 0 1 1.524-1.508 3.698 3.698 0 0 1 1.037-.338c.36-.064.726-.085 1.09-.095Z"
      clipRule="evenodd"
    />
    <Mask id="a" width={23} height={23} x={2} y={5} maskUnits="userSpaceOnUse">
      <Path
        fill="#fff"
        fillRule="evenodd"
        d="M7.619 5.01 8.586 5l10.062.004c.153.001.248.003.343.006.499.013 1.002.042 1.495.13.501.09.967.234 1.421.464a4.766 4.766 0 0 1 2.09 2.068c.233.45.38.911.469 1.407.089.488.118.986.131 1.48.007.226.01.452.01.68l.002.403v8.94l-.001.807c-.001.226-.004.453-.01.68a9.752 9.752 0 0 1-.132 1.479 4.94 4.94 0 0 1-.469 1.407 4.775 4.775 0 0 1-2.09 2.068c-.454.23-.92.375-1.42.463-.494.088-.997.117-1.496.13l-1.503.012-9.527-.005a18.897 18.897 0 0 1-.342-.006 10.082 10.082 0 0 1-1.495-.13 5.073 5.073 0 0 1-1.422-.464 4.77 4.77 0 0 1-2.09-2.068 4.966 4.966 0 0 1-.469-1.407 9.754 9.754 0 0 1-.13-1.48c-.007-.226-.01-.453-.011-.68L2 20.986v-8.94c0-.269 0-.538.002-.807.001-.227.004-.453.01-.68a9.76 9.76 0 0 1 .131-1.48c.09-.495.237-.956.469-1.406a4.76 4.76 0 0 1 2.09-2.068 5.07 5.07 0 0 1 1.422-.464c.493-.088.996-.117 1.495-.13Z"
        clipRule="evenodd"
      />
    </Mask>
    <G mask="url(#a)">
      <Path fill="#19AA1E" d="M24.61 4.998H2v22.63h22.61V4.998Z" />
      <Path
        fill="#FFB919"
        fillRule="evenodd"
        d="M2 4.998h22.61v7.426L2 8.888v-3.89Z"
        clipRule="evenodd"
      />
      <Path
        fill="#82D714"
        fillRule="evenodd"
        d="m2 25.153 22.61-3.536v6.011H2v-2.475Z"
        clipRule="evenodd"
      />
      <Path
        fill="#fff"
        fillRule="evenodd"
        d="m2 8.44 7.892 1.235c.982-.623 2.151-.964 3.413-.964 1.747 0 3.316.655 4.459 1.804a6 6 0 0 1 .408.455l6.437 1.006v.895l-5.784-.904c.43.825.662 1.745.662 2.683 0 1.213-.271 2.421-.831 3.635l-.017.037-.238.51h-.563c-1.16 0-1.931.348-2.435.964-.396.484-.624 1.136-.701 1.848l-.003.022-.01.114-.006.05-.009.096c-.03.318-.049.587-.059.807l9.994-1.563v.895L2 25.6v-.895l10.005-1.565-.004-.2-.001-.057-.001-.03-.002-.044a17.014 17.014 0 0 0-.067-.934l-.006-.072-.013-.124c-.072-.716-.297-1.377-.691-1.868-.499-.62-1.262-.973-2.414-.98h-.598l-.237-.51c-.572-1.226-.849-2.447-.849-3.672a5.883 5.883 0 0 1 1.82-4.23L2 9.336v-.894Z"
        clipRule="evenodd"
      />
      <Path
        fill="#0073FA"
        fillRule="evenodd"
        d="M13.305 9.595c3.156 0 5.299 2.432 5.299 5.055 0 1.053-.23 2.15-.765 3.298-3.106 0-3.873 2.226-4.018 3.627l-.01.094c-.048.5-.077.9-.084 1.203l-.842.132v-.028a16.104 16.104 0 0 0-.09-1.334l-.002-.025c-.134-1.397-.885-3.669-4.022-3.669-.536-1.149-.765-2.245-.765-3.298 0-2.623 2.142-5.055 5.299-5.055Z"
        clipRule="evenodd"
      />
    </G>
    <Path
      fill="#1A1A1A"
      fillRule="evenodd"
      d="M35.066 10.479c-2.755 0-4.822 1.68-4.804 4.597h2.561c-.035-1.167.707-2.299 2.243-2.299 1.166 0 1.908.778 1.908 1.768 0 1.026-.813 1.592-2.12 2.228-1.713.831-2.737 1.22-4.592 1.839v3.359H39.8v-2.299h-6.076a52.84 52.84 0 0 0 2.526-1.149c2.066-.99 3.285-2.104 3.285-4.101 0-2.405-1.801-3.943-4.469-3.943Zm8.62 2.475h4.946v-2.298h-7.419V21.97h2.473v-9.017Zm8.832-2.298h-2.473V21.97H52.5l4.61-7.231v7.23h2.473V10.657H57.11l-4.592 7.23v-7.23ZM72 17.728H69.44c-.389 1.255-1.572 2.121-2.968 2.121-2.031 0-3.356-1.75-3.356-3.536 0-1.785 1.29-3.536 3.427-3.536 1.413 0 2.508.867 2.897 2.122H72c-.37-2.528-2.366-4.42-5.528-4.42-3.497 0-5.829 2.458-5.829 5.834 0 3.377 2.332 5.835 5.83 5.835 3.16 0 5.157-1.857 5.528-4.42Z"
      clipRule="evenodd"
    />
  </Svg>
);
