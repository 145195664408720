import { StyleSheet, View } from "react-native";
import Svg, { Path } from "react-native-svg";

type Props = {
  style?: StyleSheet.NamedStyles<unknown>;
};

export const EditIcon = ({ style }: Props) => (
  <View style={style}>
    <Svg width="11" height="10" viewBox="0 0 11 10" fill="none">
      <Path
        d="M1.50007 9.50001C1.53992 9.50483 1.58021 9.50483 1.62007 9.50001L3.62007 9.00001C3.70879 8.97892 3.79006 8.93397 3.85507 8.87001L10.0001 2.70501C10.1863 2.51765 10.2909 2.2642 10.2909 2.00001C10.2909 1.73583 10.1863 1.48238 10.0001 1.29501L9.21007 0.500013C9.1172 0.407036 9.00691 0.333277 8.88551 0.282953C8.76411 0.232629 8.63398 0.206726 8.50257 0.206726C8.37115 0.206726 8.24102 0.232629 8.11963 0.282953C7.99823 0.333277 7.88794 0.407036 7.79507 0.500013L1.65007 6.64501C1.58546 6.71032 1.53894 6.7913 1.51507 6.88001L1.01507 8.88001C0.997097 8.94471 0.99234 9.01236 1.00108 9.07893C1.00982 9.1455 1.03188 9.20964 1.06595 9.2675C1.10001 9.32536 1.14538 9.37577 1.19935 9.41572C1.25332 9.45566 1.31478 9.48433 1.38007 9.50001C1.41992 9.50483 1.46021 9.50483 1.50007 9.50001ZM8.50007 1.20501L9.29507 2.00001L8.50007 2.79501L7.71007 2.00001L8.50007 1.20501ZM2.45507 7.25501L7.00007 2.70501L7.79507 3.50001L3.24507 8.05001L2.19007 8.31001L2.45507 7.25501Z"
        fill="currentColor"
      />
    </Svg>
  </View>
);
