import dayjs from "dayjs";
import { useRef, useState } from "react";
import {
  ActivityIndicator,
  Animated,
  Image,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import Toast from "react-native-toast-message";

import { AppText } from "./AppText";
import { Countdown } from "./Countdown";
import esApi from "../../store/esApi";
import { ThemeColor, ThemeFont } from "../../theme";
import { currencyFormat } from "../../utils/currency-format";
import { useHelper } from "../../utils/useHelper";
import { ConfirmationModal } from "../ConfirmationModal";

export interface ShareProductButtonProps {
  uuid: string;
  price: number;
  totalSelected: number;
  position: number;
  style?: StyleSheet.NamedStyles<unknown>;
  isDisabled: boolean;
  isActive: boolean;
  isProductPaid: boolean;
  isSelectedByOthers: boolean;
  lastChange?: string;
}

export const ShareProductButton: React.FC<ShareProductButtonProps> = ({
  style,
  uuid,
  position,
  price,
  isDisabled,
  isActive,
  totalSelected,
  isSelectedByOthers,
  isProductPaid,
  lastChange,
}) => {
  const { getSearch } = useHelper();

  const [updateGuestProduct, { isLoading }] =
    esApi.useUpdateGuestProductMutation();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const animatedScale = useRef(new Animated.Value(1)).current;

  const diff = dayjs(Date.now()).diff(dayjs(lastChange), "seconds");

  const animateButton = () => {
    animatedScale.setValue(0.9);
    Animated.spring(animatedScale, {
      toValue: 1,
      bounciness: 16,
      speed: 20,
      useNativeDriver: false,
    }).start();
  };

  const handleToggle = () => {
    updateGuestProduct({
      order: getSearch(),
      product: uuid,
      parts: isActive ? 0 : 1,
      position,
    });
  };

  const handlePress = () => {
    if (isSelectedByOthers && !isActive) {
      setShowConfirmation(true);
    } else if (!isLoading) {
      animateButton();
      handleToggle();
    }
  };

  const handleShowToast = () => {
    Toast.show({
      type: "timer",
      text1: "Блюдо в процессе оплаты",
    });
  };

  const handleConfirmModal = () => {
    setShowConfirmation(false);
    animateButton();
    handleToggle();
  };

  const handleCancelModal = () => {
    setShowConfirmation(false);
  };

  const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

  const SelectedBanner = () => (
    <View style={styles.customer}>
      <Image
        style={styles.customerImg}
        source={require("../../../assets/img/guest.svg")}
      />
      <AppText style={styles.customerText} weight={400} height={16}>
        {totalSelected}
      </AppText>
    </View>
  );

  return (
    <View style={[styles.container, style]}>
      {totalSelected > 1 && <SelectedBanner />}

      <AnimatedPressable
        style={[
          styles.button,
          isSelectedByOthers && styles.buttonChoosed,
          isActive && styles.buttonActive,
          isDisabled && styles.buttonDisabled,
          { transform: [{ scale: animatedScale }] },
        ]}
        onPress={() => {
          if (isDisabled) {
            if (!isProductPaid) {
              handleShowToast();
            }
          } else {
            handlePress();
          }
        }}
      >
        <Text
          style={[
            styles.buttonText,
            isActive && styles.buttonTextActive,
            isDisabled && styles.buttonTextDisabled,
          ]}
        >
          {isLoading ? (
            <ActivityIndicator size={18} color={ThemeColor.GREY_5} />
          ) : !isProductPaid && isDisabled ? (
            <Countdown
              shiftInSeconds={300 - diff}
              size={12}
              color={ThemeColor.GREY_3}
              onTimeout={() => console.log("timeout")}
            />
          ) : (
            currencyFormat(price)
          )}
        </Text>
      </AnimatedPressable>

      <ConfirmationModal
        showModal={showConfirmation}
        title="Вы хотите разделить позицию?"
        subtitle="После подтверждения, блюдо разделится пополам"
        onConfirm={handleConfirmModal}
        onCancel={handleCancelModal}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "calc((100% - 18px) / 4)",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: 20,
    borderWidth: 1,
    borderColor: ThemeColor.TRANSPARENT,
  },
  customer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: ThemeColor.BLACK,
    borderRadius: 8,
    paddingHorizontal: 8,
    marginBottom: 4,
    height: 20,
  },
  customerImg: {
    width: 10,
    height: 10,
    marginRight: 4,
  },
  customerText: {
    marginTop: 3,
  },
  button: {
    height: 42,
    width: "100%",
    borderWidth: 1,
    borderColor: ThemeColor.GREY_3,
    borderRadius: 16,
    paddingHorizontal: 12,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonTextDisabled: {
    color: ThemeColor.GREY_3,
  },
  buttonChoosed: {
    backgroundColor: ThemeColor.BLUE_LIGHT,
    borderColor: ThemeColor.BLUE_LIGHT,
  },
  buttonActive: {
    borderColor: ThemeColor.BLUE,
    backgroundColor: ThemeColor.BLUE,
  },
  buttonDisabled: {
    backgroundColor: ThemeColor.WHITE,
  },
  buttonText: {
    color: ThemeColor.BLACK,
    fontFamily: ThemeFont.MEDIUM,
    fontSize: 12,
    whiteSpace: "nowrap",
  },
  buttonTextActive: {
    color: ThemeColor.WHITE,
  },
});
