import { PropsWithChildren, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import Modal from "react-native-modal";

import { BlockState } from "./Payment";
import { CloseButton } from "./ui/CloseButton";
import { useAppSelector } from "../store";
import esApi from "../store/esApi";
import { ThemeColor } from "../theme";
import { useHelper } from "../utils/useHelper";

type Props = {
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
  block?: BlockState;
  bgColor?: string;
};

export const PaymentDrawer = ({
  visible,
  onVisibleChange,
  children,
  block,
  bgColor = ThemeColor.GREY,
}: PropsWithChildren<Props>) => {
  const { guestAllUuid } = useAppSelector((state) => state.payment);

  const [cancelLoyalty] = esApi.useCancelLoyaltyMutation();

  const { getSearch } = useHelper();
  const [timeout, { isLoading }] = esApi.usePayTimeoutMutation();

  const { section } = useAppSelector((state) => state.app);
  const { found } = useAppSelector((state) => state.payment);

  useEffect(() => {
    if (guestAllUuid === null && section === "PayAll") {
      onVisibleChange(false);
    }
  }, [guestAllUuid]);

  return (
    <Modal
      style={styles.modal}
      isVisible={visible}
      backdropColor={ThemeColor.DARK}
      backdropOpacity={0.2}
    >
      <View style={{ ...styles.content, backgroundColor: bgColor }}>
        <CloseButton
          size="large"
          isLoading={isLoading}
          style={styles.closeBtn}
          onPress={async () => {
            if (block !== "choose") {
              await timeout(getSearch());
              onVisibleChange(false);
            } else {
              if (found) {
                cancelLoyalty(getSearch());
              }
              onVisibleChange(false);
            }
          }}
        />
        {children}
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: {
    margin: 0,
    justifyContent: "flex-end",
  },
  content: {
    width: "100%",
    maxHeight: "100%",
    borderTopLeftRadius: 42,
    borderTopRightRadius: 42,
    paddingHorizontal: 16,
    position: "relative",
  },
  closeBtn: {
    position: "absolute",
    top: 16,
    right: 16,
  },
});
