/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CompatClient, Stomp } from "@stomp/stompjs";
import Constants from "expo-constants";
import SockJS from "sockjs-client";

import { Transaction } from "../types/common";

// TODO bad code
export class ApiEvents {
  uuid!: string;
  reconnect = false;
  name;
  ws: CompatClient | null = null;

  constructor(name: string) {
    this.name = name;

    console.log(`Created ApiEvents ${name}`);
  }

  connect(
    uuid: string,
    connectCB: Function,
    disconnectCB: Function
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.ws = Stomp.over(() => {
        return new SockJS(Constants.manifest?.extra?.baseWS);
      });

      this.ws.debug = (msg) => {
        return null;
      };

      this.uuid = uuid;
      this.reconnect = true;

      this.ws.connect(
        {},
        () => {
          connectCB();
          console.debug(this.name, "ws connected");
          resolve(); // Resolve the promise when the connection is successful
        },
        (e: WebSocketErrorEvent) => {
          console.warn("ws err", e);
          reject(e); // Reject the promise if there is an error during connection
        },
        () => {
          console.debug(this.name, "ws disconnected");

          if (this.reconnect) {
            console.debug(this.name, "ws will be reconnecting after 5 seconds");

            setTimeout(() => {
              disconnectCB();
            }, 1000);
          }
        }
      );
    });
  }

  disconnect() {
    this.reconnect = false;

    if (this.ws !== null) {
      this.ws.disconnect();
    }

    this.ws = null;
  }

  subscribeGuest(callback: Function) {
    this.ws!.subscribe(`/${this.uuid}/authGuestEvent`, (message) => {
      const parse = JSON.parse(message.body);

      callback({
        uuid: parse.guestUuid,
        fullName: parse.fullName,
      });
    });
  }

  subscribeSplit(callback: Function) {
    this.ws!.subscribe(`/${this.uuid}/splitOrderEvent`, (message) => {
      const parse = JSON.parse(message.body);
      console.log("parse split event data: ", parse);
      const productGuests = [];

      for (let i = 0; i < parse.guestProducts.length; i++) {
        productGuests.push({
          guestUuid: parse.guestProducts[i].guestUuid,
          guestFullName: parse.guestProducts[i].guestFullName,
          productUuid: parse.guestProducts[i].productUuid,
          parts: parse.guestProducts[i].parts,
          total: parse.guestProducts[i].total,
          position: parse.guestProducts[i].position,
        });
      }

      callback({
        equally: parse.equally,
        productGuests,
        guestAllUuid: parse.guestAllUuid,
      });
    });
  }

  subscribePayment(callback: (t: Transaction) => void) {
    this.ws!.subscribe(`/${this.uuid}/paymentEvent`, (message) => {
      const transaction = JSON.parse(message.body) as Transaction;

      callback(transaction);
    });
  }

  subscribeUpdate(callback: Function) {
    this.ws!.subscribe(`/${this.uuid}/orderUpdate`, (message) => {
      const parse = JSON.parse(message.body);

      callback(parse);
    });
  }
}
