import { ScrollView, StyleSheet, View } from "react-native";
import Modal from "react-native-modal";

import { AppButton } from "./AppButton";
import { AppText } from "./AppText";
import { CheckInfoBanner } from "./CheckInfoBanner";
import { ProductItem } from "./ProductItem";
import { SectionHeader } from "./SectionHeader";
import { TotalCheckSeparator } from "./TotalCheckSeparator";
import { useAppSelector } from "../../store";
import { filterAndDivideSize } from "../../store/orderHelpers";
import { ThemeColor } from "../../theme";
import { Product } from "../../types/common";
import { currencyFormat } from "../../utils/currency-format";

type Props = {
  visible: boolean;
  onClose: () => void;
};

export const TotalCheckModal = ({ visible, onClose }: Props) => {
  const {
    discount,
    products,
    tipsSum,
    guestProducts,
    currentGuest,
    sharedProducts,
  } = useAppSelector((state) => state.order);

  const { section } = useAppSelector((state) => state.app);
  const { totalSum } = useAppSelector((state) => state.payment);

  const curGuestProductIds = guestProducts
    .filter(
      ({ guestUuid, parts }) => currentGuest.uuid === guestUuid && parts > 0
    )
    .map(({ productUuid }) => productUuid);

  const curGuestProducts = curGuestProductIds
    .map((id) =>
      filterAndDivideSize(sharedProducts.flatMap(({ items }) => items)).find(
        ({ uuid }) => uuid === id
      )
    )
    .filter((x) => {
      return x !== undefined;
    }) as Product[];

  return (
    <Modal
      style={styles.modal}
      isVisible={visible}
      backdropColor={ThemeColor.DARK}
      backdropOpacity={0.2}
      animationIn="fadeIn"
      animationOut="fadeOut"
    >
      <View style={styles.section}>
        <View style={styles.header}>
          <SectionHeader
            style={styles.sectionHeader}
            src={require("../../../assets/img/bill.svg")}
            title="Сумма счета"
            text="Ваш чек"
            extra={currencyFormat(totalSum)}
          />
        </View>
        <TotalCheckSeparator />
        <View style={styles.listContainer}>
          <View style={styles.extraInfoBanners}>
            <CheckInfoBanner
              type="discount"
              image={require("../../../assets/img/percent.svg")}
              title="Скидка"
              amount={
                section === "Split"
                  ? curGuestProducts.reduce(
                      (acc, product) => acc + product.discount,
                      0
                    )
                  : discount
              }
            />
            <CheckInfoBanner
              type="tips"
              image={require("../../../assets/img/user-circle.svg")}
              title="Чаевые"
              amount={tipsSum}
            />
          </View>

          <View style={styles.productListTitleBox}>
            <AppText size={12} height={18} color={ThemeColor.GREY_4}>
              Позиции
            </AppText>
            <AppText size={12} height={18} color={ThemeColor.GREY_4}>
              Итого
            </AppText>
          </View>

          <ScrollView style={[styles.productList]}>
            {section === "Split"
              ? curGuestProducts.map((p, index) => (
                  <ProductItem
                    key={index}
                    style={styles.productItem}
                    amount={p.size}
                    title={p.ru}
                    price={p.price}
                  />
                ))
              : products.map((p, index) => (
                  <ProductItem
                    key={index}
                    style={styles.productItem}
                    amount={p.size}
                    title={p.ru}
                    price={p.total}
                  />
                ))}
          </ScrollView>

          <View style={styles.buttonWrap}>
            <AppButton size="medium" type="secondary" onPress={onClose}>
              Закрыть
            </AppButton>
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: {
    margin: 0,
    paddingHorizontal: 16,
    justifyContent: "center",
  },
  section: {
    maxHeight: "calc(100% - 32px)",
  },
  header: {
    backgroundColor: ThemeColor.WHITE,
    borderStyle: "solid",
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: ThemeColor.GREY_3,
    paddingHorizontal: 35,
    paddingTop: 16,
    zIndex: 1,
  },
  sectionHeader: {
    marginBottom: -5,
  },
  listContainer: {
    backgroundColor: ThemeColor.WHITE,
    borderStyle: "solid",
    borderBottomLeftRadius: 24,
    borderBottomRightRadius: 24,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderColor: ThemeColor.GREY_3,
    paddingBottom: 24,
    flex: 1,
  },
  extraInfoBanners: {
    paddingHorizontal: 24,
    marginBottom: 12,
  },
  productListTitleBox: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 24,
    marginBottom: 8,
  },
  productList: {
    overflow: "hidden",
    marginBottom: 60,
  },
  productItem: {
    paddingHorizontal: 24,
  },
  buttonWrap: {
    alignItems: "center",
  },
});
