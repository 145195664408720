import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";
const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      stroke="#fff"
      strokeLinejoin="round"
      d="m16.917 1.083-5.542 15.834L8.21 9.792 1.084 6.625l15.833-5.542Z"
    />
    <Path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16.917 1.083 8.208 9.792"
    />
  </Svg>
);
export default SvgComponent;
