import { TEST_START, TASK_IND, SESSION_ID } from './utils.js';
export default class SignalManager {
    constructor(ingestPoint, getTimestamp, token, testId, storageKey, setStorageKey, removeStorageKey, getStorageKey, getSessionId) {
        this.ingestPoint = ingestPoint;
        this.getTimestamp = getTimestamp;
        this.token = token;
        this.testId = testId;
        this.storageKey = storageKey;
        this.setStorageKey = setStorageKey;
        this.removeStorageKey = removeStorageKey;
        this.getStorageKey = getStorageKey;
        this.getSessionId = getSessionId;
        this.durations = {
            testStart: 0,
            tasks: [],
        };
        this.getDurations = () => {
            return this.durations;
        };
        this.setDurations = (durations) => {
            this.durations.testStart = durations.testStart;
            this.durations.tasks = durations.tasks;
        };
        this.signalTask = (taskId, status, taskAnswer) => {
            if (!taskId)
                return console.error('User Testing: No Task ID Given');
            const taskStart = this.durations.tasks.find((t) => t.taskId === taskId);
            const timestamp = this.getTimestamp();
            const duration = taskStart ? timestamp - taskStart.started : 0;
            return fetch(`${this.ingestPoint}/v1/web/uxt/signals/task`, {
                method: 'POST',
                headers: {
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    Authorization: `Bearer ${this.token}`,
                },
                body: JSON.stringify({
                    testId: this.testId,
                    taskId,
                    status,
                    duration,
                    timestamp,
                    taskAnswer,
                }),
            });
        };
        this.signalTest = (status) => {
            const timestamp = this.getTimestamp();
            if (status === 'begin' && this.testId) {
                const sessionId = this.getSessionId();
                this.setStorageKey(SESSION_ID, sessionId);
                this.setStorageKey(this.storageKey, this.testId.toString());
                this.setStorageKey(TEST_START, timestamp.toString());
            }
            else {
                this.removeStorageKey(this.storageKey);
                this.removeStorageKey(TASK_IND);
                this.removeStorageKey(TEST_START);
            }
            const start = this.durations.testStart || timestamp;
            const duration = timestamp - start;
            return fetch(`${this.ingestPoint}/v1/web/uxt/signals/test`, {
                method: 'POST',
                headers: {
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    Authorization: `Bearer ${this.token}`,
                },
                body: JSON.stringify({
                    testId: this.testId,
                    status,
                    duration,
                    timestamp,
                }),
            });
        };
        const possibleStart = this.getStorageKey(TEST_START);
        if (possibleStart) {
            this.durations.testStart = parseInt(possibleStart, 10);
        }
    }
}
