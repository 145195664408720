import {
  StyleSheet,
  View,
  Image,
  ImageURISource,
  Pressable,
} from "react-native";

import { AppText } from "./AppText";
import { ThemeColor, ThemeFont } from "../../theme";
import { MenuProduct } from "../../types/common";
import { currencyFormat } from "../../utils/currency-format";
import { baseUrl } from "../../utils/helpers";

type Props = {
  item: MenuProduct;
  onProductSelected: (product: MenuProduct) => void;
};

export const MenuProductPreview = ({ item, onProductSelected }: Props) => {
  const strToUri = (url: string): ImageURISource => ({
    uri: baseUrl + `../menu/product-image/${url}`,
  });

  // bad
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const defaultProductImage = require("../../../assets/img/no-product-image.svg");

  const logo =
    (item.imageName ?? "") !== ""
      ? strToUri(item.imageName ?? "")
      : (defaultProductImage as ImageURISource);

  const emptyLogo = (item.imageName ?? "") === "";

  return (
    <Pressable
      onPress={() => {
        onProductSelected(item);
      }}
      style={styles.container}
    >
      <View style={styles.itemsContainer}>
        <View style={styles.imageContainer}>
          <Image
            style={emptyLogo ? styles.emptyImage : styles.image}
            source={logo}
          />
        </View>
        <View style={styles.textContainer}>
          <AppText color={ThemeColor.BLACK_4} weight={500} size={16} numberOfLines={2} ellipsizeMode="tail">{item.name}</AppText>
          <AppText color={ThemeColor.GREY_4} weight={400} size={12} numberOfLines={2} ellipsizeMode="tail" style={styles.description}>
            {item.description}
          </AppText>
          <AppText color={ThemeColor.BLACK_4} weight={500} size={16} style={styles.priceStyle}>
            {currencyFormat(item.price ?? 0, "\u00A0", false)}
          </AppText>
        </View>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    fontSize: 16,
    flexGrow: 1,
    flexBasis: 1,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    maxWidth: "50%",
  },
  itemsContainer: {
    minHeight: 254,
    maxHeight: 254,
  },
  textContainer: {
    paddingTop: 12,
    paddingHorizontal: 12,
    display: "flex",
    flex: 1,
    backgroundColor: ThemeColor.WHITE,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  imageContainer: {
    height: 104,
    width: "100%",
    backgroundColor: ThemeColor.GREY_6,
    justifyContent: "center",
    alignItems: "center",
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  emptyImage: {
    width: 51.49,
    height: 48.75,
  },
  image: {
    resizeMode: "cover",
    width: "100%",
    height: "100%",
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  description: {
    marginTop: 4,
  },
  priceStyle: {
    position: "absolute",
    bottom: 10,
  },
});
