import { useEffect } from "react";

import { getCookie, useHelper } from "./useHelper";
import { useAppSelector } from "../store";
import esApi from "../store/esApi";
import { PayByCardDTO } from "../types/common";

interface FormData {
  card: string;
  date: string;
  cvc: string;
  email: string;
}

export function usePayByCard(): [
  typeof pay,
  {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    originalArgs?: {
      order: string;
      body: PayByCardDTO;
    };
  }
] {
  const { getSearch } = useHelper();

  const { rating, comment } = useAppSelector((state) => state.order);

  const {
    saveCard,
    takeServiceFee,
    tipsPercentage,
    tipsValue,
    useBonuses,
    found,
  } = useAppSelector((state) => state.payment);

  const [sendComment] = esApi.useCommentMutation();

  const [payByCard, { isLoading, isSuccess, isError, originalArgs }] =
    esApi.usePayByCardMutation();

  const [processLoyalty] = esApi.useProcessLoyaltyMutation();

  const [payStart] = esApi.usePayStartMutation();

  const sendTransaction = async () => {
    if (useBonuses && found) {
      await processLoyalty({ order: getSearch(), loyalParam1: "uds" });
    }

    await payStart({
      order: getSearch(),
      takeServiceFee,
      tipsPercentage,
      tipsValue,
    });
  };

  useEffect(() => {
    sendTransaction();
  }, []);

  const pay = async ({ card, date, cvc, email }: FormData) => {
    await payByCard({
      order: getSearch(),
      body: { card, date, cvc, email, saveCard },
    });

    if (rating > 0) {
      sendComment({ order: getSearch(), comment, stars: rating });
    }
  };

  return [pay, { isLoading, isSuccess, isError, originalArgs }];
}

export function usePayBySavedCard(): [
  typeof sendTransaction,
  { isLoading: boolean; isSuccess: boolean }
] {
  const { getSearch, getObjectSearch } = useHelper();

  const { rating, comment } = useAppSelector((state) => state.order);

  const [sendComment] = esApi.useCommentMutation();

  const [processLoyalty] = esApi.useProcessLoyaltyMutation();

  const { takeServiceFee, tipsPercentage, tipsValue, useBonuses, found } =
    useAppSelector((state) => state.payment);

  const [payByCard, { isLoading, isSuccess }] = esApi.usePayByCardMutation();
  const [payStart] = esApi.usePayStartMutation();

  const sendTransaction = async () => {
    const session =
      getCookie("crd_session") || getObjectSearch().get("session");

    console.log(session);

    if (session) {
      if (useBonuses && found) {
        await processLoyalty({ order: getSearch(), loyalParam1: "uds" });
      }

      await payStart({
        order: getSearch(),
        takeServiceFee,
        tipsPercentage,
        tipsValue,
      });

      await payByCard({
        order: getSearch(),
        body: { saveCard: false, session },
      });

      if (rating > 0) {
        sendComment({ order: getSearch(), comment, stars: rating });
      }
    }
  };

  return [sendTransaction, { isLoading, isSuccess }];
}
