import { useState, useEffect } from "react";
import { Image, StyleSheet, Text, TextInput, View } from "react-native";

import { useAppDispatch } from "../../store";
import { setSearchText } from "../../store/menuSlice";
import { ThemeColor, ThemeFont } from "../../theme";
import { AppText } from "./AppText";

export const MenuSearch = () => {
  const [active, setActive] = useState(false);
  const [text, setText] = useState("");

  const dispatch = useAppDispatch();

  const handleFocus = () => setActive(true);
  const handleBlur = () => {
    if (text.length <= 0) {
      setActive(false);
    }
    dispatch(setSearchText(text));
  };

  const handleChangeText = (value: string) => {
    setText(value);
    dispatch(setSearchText(value));
  };

  useEffect(() => {
    setActive(false);
  }, []);

  return (
    <View style={styles.menuContainer}>
      <View style={styles.container}>
        <Image
          style={styles.placeholderImg}
          source={require("../../../assets/img/search.svg")}
        />

        <TextInput
          editable
          style={styles.input}
          keyboardType="default"
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={text}
          onChangeText={handleChangeText}
        />
        {!active && (
          <View style={styles.placeholder}>
            <AppText weight={400} size={14} style={styles.placeholderText}>Найти блюдо</AppText>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  menuContainer: {
    backgroundColor: ThemeColor.GREY,
    paddingHorizontal: 16,
    paddingTop: 15,
  },
  container: {
    backgroundColor: ThemeColor.WHITE,
    borderRadius: 20,
    minHeight: 48,
    position: "relative",
    flex: 1,
    flexDirection: "row",
  },
  placeholder: {
    position: "absolute",
    top: 13.5,
    left: 56,
    pointerEvents: "none",
  },
  placeholderImg: {
    width: 20.28,
    height: 20.28,
    marginLeft: 20,
    marginTop: 12,
  },
  placeholderText: {
    color: ThemeColor.GREY_4,
  },
  input: {
    flex: 1,
    marginBottom: 12,
    outlineStyle: "none",
    fontFamily: ThemeFont.MEDIUM,
    color: ThemeColor.BLACK_4,
    marginLeft: 12,
    marginTop: 12,
  },
});
