import { useNavigation, useRoute } from "@react-navigation/native";
import { PropsWithChildren } from "react";
import { Pressable, StyleSheet, View } from "react-native";

import BasketIcon from "./icons/BasketIcon";
import BillIcon from "./icons/BillIcon";
import MenuIcon from "./icons/MenuIcon";
import ProfileIcon from "./icons/ProfileIcon";
import { AppText } from "./ui/AppText";
import { StackNavigation } from "../navigation/AppNavigation";
import { ThemeColor } from "../theme";

const Tapbar = () => {
  return (
    <View style={styles.wrapper}>
      <View style={styles.container}>
        <TapbarItem link="Menu">Меню</TapbarItem>
        {/* <TapbarItem link="Basket">Корзина</TapbarItem> */}
        <TapbarItem link="Main">Счет</TapbarItem>
        {/* <TapbarItem link="Profile">Профиль</TapbarItem> */}
      </View>
    </View>
  );
};

export default Tapbar;

interface TapbarItemProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  link?: any;
}

const TapbarItem: React.FC<PropsWithChildren<TapbarItemProps>> = ({
  children,
  link,
}) => {
  const route = useRoute();

  const { navigate } = useNavigation<StackNavigation>();

  const active = route.name === link;

  return (
    <Pressable style={styles.button} onPress={() => navigate(link)}>
      <View>
        {link === "Menu" && (
          <MenuIcon
            stroke={active ? ThemeColor.BLACK : ThemeColor.GREY_4}
            fill={active ? ThemeColor.WHITE : "none"}
          />
        )}
        {link === "Basket" && (
          <BasketIcon
            stroke={active ? ThemeColor.BLACK : ThemeColor.GREY_4}
            fill={active ? ThemeColor.WHITE : "none"}
          />
        )}
        {link === "Main" && (
          <BillIcon
            stroke={active ? ThemeColor.BLACK : ThemeColor.GREY_4}
            fill={active ? ThemeColor.WHITE : "none"}
          />
        )}
        {link === "Profile" && (
          <ProfileIcon
            stroke={active ? ThemeColor.BLACK : ThemeColor.GREY_4}
            fill={active ? ThemeColor.WHITE : "none"}
          />
        )}
      </View>
      <AppText
        color={active ? ThemeColor.WHITE : ThemeColor.GREY_4}
        size={12}
        weight={400}
      >
        {children}
      </AppText>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: ThemeColor.BLACK_4,
    borderRadius: 24,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "flex-end",
    paddingVertical: 16,
    paddingHorizontal: 10,
  },
  wrapper: {
    paddingHorizontal: 16,
    paddingBottom: 8,
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  button: {
    display: "flex",
    flexDirection: "column",
    gap: 6,
    alignItems: "center",
    justifyContent: "space-between",
  },
});
