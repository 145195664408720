import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import appSlice from "./appSlice";
import { rtkQueryErrorLogger } from "./errorMiddleware";
import esApi from "./esApi";
import menuSlice from "./menuSlice";
import orderSlice from "./orderSlice";
import paymentSlice from "./paymentSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedPaymentSlice = persistReducer(
  {
    ...persistConfig,
    whitelist: ["lastBankUsed", "loyaltyCreds"],
  },
  paymentSlice
);

const persistedAppSlice = persistReducer(
  { ...persistConfig, whitelist: ["userId"] },
  appSlice
);

const store = configureStore({
  reducer: {
    app: persistedAppSlice,
    order: orderSlice,
    payment: persistedPaymentSlice,
    menu: menuSlice,
    [esApi.reducerPath]: esApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(esApi.middleware, rtkQueryErrorLogger),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
