import { StyleSheet, View } from "react-native";
import Svg, { Path } from "react-native-svg";

type Props = {
  style?: StyleSheet.NamedStyles<unknown>;
};

export const CloseIcon = ({ style }: Props) => (
  <View style={style}>
    <Svg width="100%" height="100%" viewBox="0 0 28 28" fill="none">
      <Path
        d="M14 15.96L18.06 20.02C18.3167 20.2767 18.6433 20.405 19.04 20.405C19.4367 20.405 19.7633 20.2767 20.02 20.02C20.2767 19.7633 20.405 19.4367 20.405 19.04C20.405 18.6433 20.2767 18.3167 20.02 18.06L15.96 14L20.02 9.94C20.2767 9.68333 20.405 9.35667 20.405 8.96C20.405 8.56333 20.2767 8.23667 20.02 7.98C19.7633 7.72333 19.4367 7.595 19.04 7.595C18.6433 7.595 18.3167 7.72333 18.06 7.98L14 12.04L9.94 7.98C9.68333 7.72333 9.35667 7.595 8.96 7.595C8.56333 7.595 8.23667 7.72333 7.98 7.98C7.72333 8.23667 7.595 8.56333 7.595 8.96C7.595 9.35667 7.72333 9.68333 7.98 9.94L12.04 14L7.98 18.06C7.72333 18.3167 7.595 18.6433 7.595 19.04C7.595 19.4367 7.72333 19.7633 7.98 20.02C8.23667 20.2767 8.56333 20.405 8.96 20.405C9.35667 20.405 9.68333 20.2767 9.94 20.02L14 15.96ZM14 28C12.0633 28 10.2433 27.6323 8.54 26.8968C6.83667 26.1613 5.355 25.1641 4.095 23.905C2.835 22.645 1.83773 21.1633 1.1032 19.46C0.368667 17.7567 0.000933333 15.9367 0 14C0 12.0633 0.367733 10.2433 1.1032 8.54C1.83867 6.83667 2.83593 5.355 4.095 4.095C5.355 2.835 6.83667 1.83773 8.54 1.1032C10.2433 0.368667 12.0633 0.000933333 14 0C15.9367 0 17.7567 0.367733 19.46 1.1032C21.1633 1.83867 22.645 2.83593 23.905 4.095C25.165 5.355 26.1627 6.83667 26.8982 8.54C27.6337 10.2433 28.0009 12.0633 28 14C28 15.9367 27.6323 17.7567 26.8968 19.46C26.1613 21.1633 25.1641 22.645 23.905 23.905C22.645 25.165 21.1633 26.1627 19.46 26.8982C17.7567 27.6337 15.9367 28.0009 14 28Z"
        fill="currentColor"
      />
    </Svg>
  </View>
);
