import { useRef, useState } from "react";
import { Animated, Easing, Pressable, StyleSheet } from "react-native";

import { ThemeColor } from "../../theme";

type Props = {
  value?: boolean;
  disabled?: boolean;
  onValueChange?: (value: boolean) => void;
};

export const AppSwitch = ({ value, onValueChange, disabled }: Props) => {
  const [active, setActive] = useState(value);
  const animatedValue = useRef(new Animated.Value(value ? 1 : 0)).current;

  const handleValueChange = (state: boolean) => {
    setActive(state);

    Animated.timing(animatedValue, {
      toValue: state ? 1 : 0,
      easing: Easing.linear,
      duration: 300,
      useNativeDriver: false,
    }).start();

    onValueChange && onValueChange(state);
  };

  return (
    <Pressable
      style={[styles.container, active && styles.containerActive]}
      disabled={disabled}
      onPress={() => handleValueChange(!active)}
    >
      <Animated.View
        style={[
          styles.handle,
          {
            left: animatedValue.interpolate({
              inputRange: [0, 1],
              outputRange: ["5%", "43%"],
            }),
          },
        ]}
      />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 51,
    height: 31,
    borderRadius: 16,
    backgroundColor: "rgba(120, 120, 128, 0.16)", // TODO: add to UI kit
    position: "relative",
  },
  containerActive: {
    backgroundColor: ThemeColor.BLUE,
  },
  handle: {
    width: 27,
    height: 27,
    backgroundColor: ThemeColor.WHITE,
    borderRadius: 14,
    position: "absolute",
    top: 2,
    shadowColor: ThemeColor.DARK,
    shadowOpacity: 0.15,
    shadowRadius: 8,
    shadowOffset: {
      width: 3,
      height: 3,
    },
  },
});
