import { StyleSheet, View } from "react-native";
import Svg, { Rect, Path, G } from "react-native-svg";

type Props = {
  style?: StyleSheet.NamedStyles<unknown>;
};

export const ErrorIcon = ({ style }: Props) => (
  <View style={style}>
    <Svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <G>
        <Path
          d="M20 10C25.523 10 30 14.477 30 20C30 25.523 25.523 30 20 30C14.477 30 10 25.523 10 20C10 14.477 14.477 10 20 10ZM20 12C17.8783 12 15.8434 12.8429 14.3431 14.3431C12.8429 15.8434 12 17.8783 12 20C12 22.1217 12.8429 24.1566 14.3431 25.6569C15.8434 27.1571 17.8783 28 20 28C22.1217 28 24.1566 27.1571 25.6569 25.6569C27.1571 24.1566 28 22.1217 28 20C28 17.8783 27.1571 15.8434 25.6569 14.3431C24.1566 12.8429 22.1217 12 20 12ZM20 23C20.2652 23 20.5196 23.1054 20.7071 23.2929C20.8946 23.4804 21 23.7348 21 24C21 24.2652 20.8946 24.5196 20.7071 24.7071C20.5196 24.8946 20.2652 25 20 25C19.7348 25 19.4804 24.8946 19.2929 24.7071C19.1054 24.5196 19 24.2652 19 24C19 23.7348 19.1054 23.4804 19.2929 23.2929C19.4804 23.1054 19.7348 23 20 23ZM20 14C20.2652 14 20.5196 14.1054 20.7071 14.2929C20.8946 14.4804 21 14.7348 21 15V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22C19.7348 22 19.4804 21.8946 19.2929 21.7071C19.1054 21.5196 19 21.2652 19 21V15C19 14.7348 19.1054 14.4804 19.2929 14.2929C19.4804 14.1054 19.7348 14 20 14Z"
          fill="#ED5252"
        />
      </G>
      <Rect x="0.5" y="0.5" width="39" height="39" rx="11.5" stroke="#ED5252" />
    </Svg>
  </View>
);
