import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  ActivityIndicator,
  ImageBackground,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";

import { AppButton } from "./ui/AppButton";
import { AppInput } from "./ui/AppInput";
import { AppSwitch } from "./ui/AppSwitch";
import { AppText } from "./ui/AppText";
import { Countdown } from "./ui/Countdown";
import { ImageBox } from "./ui/ImageBox";
import { ErrorScreen } from "../screens/ErrorScreen";
import { useAppDispatch, useAppSelector } from "../store";
import esApi from "../store/esApi";
import { setSaveCard } from "../store/paymentSlice";
import { ThemeColor } from "../theme";
import { currencyFormat } from "../utils/currency-format";
import { luhnCheck } from "../utils/helpers";
import { useHelper } from "../utils/useHelper";
import { usePayByCard } from "../utils/usePay";

type Props = {
  onClose: () => void; // TODO: temp
};

interface FormData {
  card: string;
  date: string;
  cvc: string;
  email: string;
}

export const PaymentTypeCard = ({ onClose }: Props) => {
  const { getSearch } = useHelper();

  // const dispatch = useAppDispatch();

  const { totalSum } = useAppSelector((state) => state.payment);

  const { currentTransaction } = useAppSelector((state) => state.order);

  const [payByCard, { isLoading, isSuccess, isError, originalArgs }] =
    usePayByCard();
  const [payTimeout] = esApi.usePayTimeoutMutation();

  const handlePayment = (values: FormData) => {
    payByCard(values);
  };

  const {
    control,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onTouched",
    defaultValues: {
      card: "",
      date: "",
      cvc: "",
      email: "",
    },
  });

  const handleCancel = () => {
    payTimeout(getSearch())
      .unwrap()
      .then(() => {
        onClose();
      });
  };

  const handleRetry = () => {
    if (originalArgs) {
      payByCard(originalArgs.body as FormData);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      currentTransaction.success && onClose();
    }
  }, [currentTransaction, isSuccess]);

  if (isError) {
    return (
      <ErrorScreen
        isLoading={isLoading}
        onRetry={handleRetry}
        onCancel={handleCancel}
      />
    );
  }

  return (
    <View style={styles.container}>
      <ImageBackground
        style={styles.cardImg}
        resizeMode="stretch"
        source={require("../../assets/img/card-bg.svg")}
      />

      <ScrollView
        style={styles.scrollContainer}
        contentContainerStyle={styles.scroll}
      >
        <View style={styles.header}>
          <ImageBox
            style={styles.headerImage}
            src={require("../../assets/img/coins.svg")}
          />
          <View style={{ flex: 1 }}>
            <AppText size={12} height={18} color={ThemeColor.GREY_4}>
              Итого к оплате
            </AppText>
            <AppText weight={700} size={24} height={29}>
              {currencyFormat(totalSum)}
            </AppText>
          </View>
        </View>

        <Controller
          control={control}
          rules={{
            required: "Укажите номер карты",
            validate: (value) => luhnCheck(value) || "Неверный номер карты",
            pattern: {
              value: /^\d{4} \d{4} \d{4} \d{4}$/,
              message: "Неверный номер карты",
            },
          }}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <AppInput
              autocomplete="cc-number"
              style={styles.cardNumberInput}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              label="Номер карты"
              mask="9999 9999 9999 9999"
              placeholder="4276 3000 0000 0000"
              error={errors?.card?.message}
              reset={() => resetField(name)}
            />
          )}
          name="card"
        />

        <View style={styles.inputGroup}>
          <Controller
            control={control}
            rules={{
              required: "Укажите дату",
              pattern: {
                value: /^(0[1-9]|1[0-2])\/\d{2}$/,
                message: "Неверная дата",
              },
            }}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <AppInput
                key="input-card-date"
                autocomplete="cc-exp"
                style={styles.halfWidthInput}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                label="Дата"
                mask="99/99"
                placeholder="06/25"
                error={errors?.date?.message}
                reset={() => resetField(name)}
              />
            )}
            name="date"
          />

          <Controller
            control={control}
            rules={{
              required: "Укажите код",
              pattern: {
                value: /^\d{3}$/,
                message: "Неверный код",
              },
            }}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <AppInput
                secureTextEntry
                key="input-card-code"
                autocomplete="cc-csc"
                style={styles.halfWidthInput}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                label="CVC"
                mask="999"
                placeholder="999"
                error={errors?.cvc?.message}
                reset={() => resetField(name)}
              />
            )}
            name="cvc"
          />
        </View>

        {/* <View style={styles.switcher}>
          <AppText size={16} height={24}>
            Сохранить карту
          </AppText>
          <AppSwitch
            value={saveCard}
            onValueChange={(value) => dispatch(setSaveCard(value))}
          />
        </View> */}
        {/* 
        <AppText style={styles.emailTitle} size={16} height={24}>
          Сохранить чек на почту
        </AppText>

        <Controller
          control={control}
          rules={{
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: "Неверная почта",
            },
          }}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <AppInput
              style={styles.emailInput}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              keyboardType="email-address"
              placeholder="example@mail.ru"
              error={errors?.email?.message}
              reset={() => resetField(name)}
            />
          )}
          name="email"
        /> */}

        <View style={styles.countdownWrap}>
          <AppText style={styles.coundownTitle} weight={400} height={21}>
            Введите данные в течение
          </AppText>
          <Countdown onTimeout={handleCancel} />
        </View>

        <AppButton
          key="pay"
          size="large"
          disabled={isLoading}
          onPress={handleSubmit((data) => handlePayment(data))}
        >
          <View style={styles.payButton}>
            {isLoading && (
              <ActivityIndicator size={18} color={ThemeColor.GREY_5} />
            )}
            <Text>Оплатить {currencyFormat(totalSum)}</Text>
          </View>
        </AppButton>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    flex: 1,
  },
  scrollContainer: {
    height: "100%",
    maxHeight: "100%",
  },
  scroll: {
    paddingTop: 128,
    paddingBottom: 20,
  },
  cardImg: {
    position: "absolute",
    top: -1,
    right: 10,
    left: 10,
    height: 86,
    zIndex: 10,
  },
  header: {
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: 20,
  },
  headerImage: {
    marginRight: 16,
    width: 41,
    height: 48,
  },
  cardNumberInput: {
    marginBottom: 8,
  },
  inputGroup: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  halfWidthInput: {
    width: "calc((100% - 8px) / 2)",
  },
  switcher: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 40,
  },
  emailTitle: {
    marginBottom: 12,
  },
  emailInput: {
    marginBottom: 40,
  },
  countdownWrap: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 16,
  },
  coundownTitle: {
    marginRight: 12,
  },
  payButton: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 6,
  },
});
