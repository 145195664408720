import { useEffect, useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  Image,
  StyleSheet,
  View,
} from "react-native";
import Toast from "react-native-toast-message";

import { AppButton } from "./ui/AppButton";
import { AppText } from "./ui/AppText";
import { Countdown } from "./ui/Countdown";
import { ImageBox } from "./ui/ImageBox";
import { PaymentButton } from "./ui/PaymentButton";
import { SearchInput } from "./ui/SearchInput";
import { ErrorScreen } from "../screens/ErrorScreen";
import { LoadingScreen } from "../screens/LoadingScreen";
import { useAppDispatch, useAppSelector } from "../store";
import esApi from "../store/esApi";
import { setLastBankUsed } from "../store/paymentSlice";
import { ThemeColor } from "../theme";
import { BankType } from "../types/common";
import { currencyFormat } from "../utils/currency-format";
import { sleep } from "../utils/helpers";
import { useHelper } from "../utils/useHelper";

const BANKS_AMOUNT_ON_INIT = 8;

type Props = {
  onClose: () => void;
};

export const PaymentTypeSBP = ({ onClose }: Props) => {
  const { getSearch } = useHelper();

  const dispatch = useAppDispatch();

  const {
    takeServiceFee,
    tipsPercentage,
    totalSum,
    tipsValue,
    useBonuses,
    guestAllUuid,
    found,
    lastBankUsed,
  } = useAppSelector((state) => state.payment);

  const { comment, rating, currentTransaction } = useAppSelector(
    (state) => state.order
  );

  const { section } = useAppSelector((state) => state.app);

  const [leftForBank, setLeftForBank] = useState(false);

  const { banks, bankListLoading } = esApi.useGetBanksQuery(undefined, {
    selectFromResult: (res) => ({
      banks: res.data?.dictionary,
      bankListLoading: res.isLoading,
    }),
  });

  const [
    payBySBP,
    {
      data: dataSBP,
      isLoading: loadingSBP,
      isError: erorrSBP,
      isSuccess: successSBP,
      originalArgs,
    },
  ] = esApi.usePayBySBPMutation();

  const [payTimeout] = esApi.usePayTimeoutMutation();
  const [sendComment] = esApi.useCommentMutation();
  const [processLoyalty] = esApi.useProcessLoyaltyMutation();

  const [visibleBanks, setVisibleBanks] = useState<BankType[]>([]);
  const [isOtherBanksVisible, setIsOtherBanksVisible] = useState(false);

  const handleSearch = (text: string) => {
    if (banks) {
      !isOtherBanksVisible && setIsOtherBanksVisible(true);
      if (text) {
        const filteredBanks = banks.filter((b: BankType) =>
          b.bankName.toLowerCase().includes(text.toLowerCase())
        );
        setVisibleBanks(filteredBanks);
      } else {
        setVisibleBanks(banks);
      }
    }
  };

  const handleBankChoose = async (item: BankType) => {
    if (successSBP && dataSBP) {
      console.log(item);
      console.log(dataSBP);
      window.location.href = item.schema + dataSBP.url.replace("https", "");
      if (item.webClientUrl) {
        window.location.href =
          item.webClientUrl + dataSBP.url.replace("https://qr.nspk.ru", "");
      }

      dispatch(setLastBankUsed(item.schema));
      setLeftForBank(true);

      await sleep(2000);
      Toast.show({
        type: "error",
        text1: "Ошибка",
        text2: "Не удалось открыть приложение",
      });
    }
  };

  const handleCancel = () => {
    payTimeout(getSearch())
      .unwrap()
      .then(() => {
        onClose();
      });
  };

  const handleBankChooseAnother = () => {
    setLeftForBank(false);
  };

  const handleRetry = () => {
    if (originalArgs) {
      payBySBP(originalArgs);
    }
  };

  const handlePayBySBP = async () => {
    if (useBonuses && found) {
      await processLoyalty({ order: getSearch(), loyalParam1: "uds" });
    }

    await payBySBP({
      order: getSearch(),
      takeServiceFee,
      tipsPercentage,
      tipsValue,
    });
    if (rating > 0) {
      sendComment({ order: getSearch(), comment, stars: rating });
    }
  };

  useEffect(() => {
    handlePayBySBP();
  }, []);

  useEffect(() => {
    if (banks) {
      if (isOtherBanksVisible) {
        setVisibleBanks(moveLastUsedBankToFirst(banks, lastBankUsed));
      } else {
        setVisibleBanks(
          moveLastUsedBankToFirst(
            banks.slice(0, BANKS_AMOUNT_ON_INIT),
            lastBankUsed
          )
        );
      }
    }
  }, [isOtherBanksVisible, banks, lastBankUsed]);

  useEffect(() => {
    currentTransaction.success && onClose();
  }, [currentTransaction, successSBP]);

  useEffect(() => {
    if (guestAllUuid === null && section === "PayAll") {
      onClose();
    }
  }, [guestAllUuid]);

  if (leftForBank) {
    return (
      <LoadingScreen
        onRetry={handleBankChooseAnother}
        onCancel={handleCancel}
      />
    );
  }

  if (!currentTransaction.success && erorrSBP) {
    return <ErrorScreen onRetry={handleRetry} onCancel={handleCancel} />;
  }

  return (
    <View style={styles.container}>
      <View style={styles.paddingContainer}>
        <View style={styles.header}>
          <ImageBox
            style={styles.headerImage}
            src={require("../../assets/img/coins.svg")}
          />
          <View>
            <AppText size={12} height={18} color={ThemeColor.GREY_4}>
              Итого к оплате
            </AppText>
            <AppText weight={700} size={24} height={29}>
              {currencyFormat(totalSum)}
            </AppText>
          </View>
        </View>

        <AppText style={styles.title} size={16} height={24}>
          Выберите банк
        </AppText>
        <AppText
          style={styles.description}
          weight={400}
          height={21}
          color={ThemeColor.GREY_4}
        >
          После выбора откроется ваше банковское приложение, где произойдёт
          оплата
        </AppText>

        <SearchInput
          style={styles.searchInput}
          placeholder="Поиск"
          onChangeText={handleSearch}
        />
      </View>

      {bankListLoading || loadingSBP ? (
        <ActivityIndicator size={64} color={ThemeColor.GREY_5} />
      ) : (
        <FlatList
          data={visibleBanks}
          numColumns={2}
          contentContainerStyle={styles.listContentContainer}
          columnWrapperStyle={styles.listColumnWrapper}
          keyExtractor={({ bankName }) => bankName}
          renderItem={({ item }) => (
            <PaymentButton
              style={styles.paymentBtn}
              onPress={async () => await handleBankChoose(item)}
            >
              <Image
                style={styles.paymentBtnImg}
                resizeMode="contain"
                source={{ uri: item.logoURL }}
              />
              <AppText weight={400} height={21}>
                {item.bankName}
              </AppText>
            </PaymentButton>
          )}
          ListEmptyComponent={() => (
            <View style={styles.emptyList}>
              <AppText>Ничего не найдено</AppText>
            </View>
          )}
          ListFooterComponent={() => (
            <View style={{ paddingHorizontal: 16 }}>
              {!isOtherBanksVisible && (
                <PaymentButton
                  style={styles.paymentBtn}
                  onPress={() => setIsOtherBanksVisible(true)}
                >
                  <Image
                    style={styles.paymentBtnImg}
                    resizeMode="contain"
                    source={require("../../assets/img/card.svg")}
                  />
                  <AppText weight={400} height={21}>
                    Другой банк
                  </AppText>
                </PaymentButton>
              )}
            </View>
          )}
        />
      )}

      <View style={styles.paddingContainer}>
        <View style={styles.countdownWrap}>
          <AppText style={styles.countdownTitle} weight={400} height={21}>
            Введите данные в течение
          </AppText>
          <Countdown onTimeout={handleCancel} />
        </View>

        <AppButton size="large" type="secondary" onPress={handleCancel}>
          Отменить
        </AppButton>
      </View>
    </View>
  );
};

function moveLastUsedBankToFirst(banks: BankType[], lastBankUsed?: string) {
  if (!banks) {
    return [];
  }

  if (!lastBankUsed) {
    return banks;
  }

  const targetBank = banks.find((bank) => bank.schema === lastBankUsed);
  const newBanks = targetBank
    ? [targetBank].concat(banks.filter((bank) => bank.schema !== lastBankUsed))
    : [...banks];

  return newBanks;
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    minHeight: "100%",
    paddingVertical: 20,
  },
  paddingContainer: {
    paddingHorizontal: 16,
  },
  // TODO: duplication
  header: {
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: 32,
  },
  headerImage: {
    marginRight: 16,
    width: 41,
    height: 48,
  },
  // end duplication
  title: {
    marginBottom: 4,
  },
  description: {
    marginBottom: 20,
  },
  searchInput: {
    marginBottom: 20,
  },
  listContentContainer: {
    flexGrow: 1,
  },
  listColumnWrapper: {
    paddingHorizontal: 16,
    justifyContent: "space-between",
  },
  emptyList: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  bankBtnsWrap: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    flex: 1,
    marginBottom: 47,
    paddingHorizontal: 16,
  },
  paymentBtn: {
    width: "calc((100% - 14px) / 2)",
    paddingVertical: 16,
    // borderRadius: 10,
    marginBottom: 14,
    backgroundColor: ThemeColor.WHITE,
  },
  paymentBtnImg: {
    height: 33,
    width: 33,
    marginBottom: 14,
    borderRadius: 4,
  },
  countdownWrap: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 16,
  },
  countdownTitle: {
    marginRight: 12,
  },
});
