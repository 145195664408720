import { Image, ImageSourcePropType, StyleSheet, View } from "react-native";

import { AppText } from "./AppText";
import { ThemeColor } from "../../theme";
import { currencyFormat } from "../../utils/currency-format";

type BannerType = "discount" | "tips";
type BannerColor = {
  color: ThemeColor;
  backgroundColor: ThemeColor;
};
type BannerTypeMap = Record<BannerType, BannerColor>;

type Props = {
  type: BannerType;
  image: ImageSourcePropType;
  title: string;
  amount: number;
};

const TYPE_MAP: BannerTypeMap = {
  discount: {
    color: ThemeColor.GREEN,
    backgroundColor: ThemeColor.GREEN_LIGHT,
  },
  tips: {
    color: ThemeColor.BLACK,
    backgroundColor: ThemeColor.GREY_2,
  },
};

export const CheckInfoBanner = ({ type, image, title, amount }: Props) => {
  const { color, backgroundColor } = TYPE_MAP[type];

  return (
    <View style={[styles.container, { backgroundColor }]}>
      <Image style={styles.image} source={image} />
      <AppText style={styles.text} size={16} height={24} color={color}>
        {title}
      </AppText>
      <AppText weight={600} size={18} height={27} color={color}>
        {currencyFormat(amount)}
      </AppText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    height: 43,
    borderRadius: 20,
    paddingHorizontal: 16,
    marginBottom: 8,
  },
  image: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
  text: {
    flex: 1,
  },
});
