import { PropsWithChildren, useState } from "react";
import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";

import { AppText } from "./AppText";
import { ThemeColor } from "../../theme";

type ButtonType = "filled" | "transparent";
type ButtonTypeMap = Record<ButtonType, ViewStyle>;

type Props = {
  type?: ButtonType;
  loading?: boolean;
  disabled?: boolean;
  style?: ViewStyle;
  onPress?: () => void;
};

const BTN_TYPE_MAP: ButtonTypeMap = {
  filled: {
    backgroundColor: ThemeColor.WHITE,
    borderColor: ThemeColor.GREY_3,
    borderWidth: 1,
    shadowColor: ThemeColor.SHADOW,
    shadowOpacity: 0.05,
    shadowRadius: 10,
    shadowOffset: {
      width: 2,
      height: 2,
    },
  },
  transparent: {
    backgroundColor: ThemeColor.TRANSPARENT,
  },
};

const BTN_ACTIVE_MAP: ButtonTypeMap = {
  filled: {
    backgroundColor: ThemeColor.GREY_5,
  },
  transparent: {
    backgroundColor: ThemeColor.GREY_2,
  },
};

const BTN_DISABLED_MAP: ButtonTypeMap = {
  filled: {
    opacity: 0.5,
  },
  transparent: {
    opacity: 0.5,
  },
};

export const PaymentButton = ({
  type = "filled",
  loading,
  disabled,
  style,
  onPress,
  children,
}: PropsWithChildren<Props>) => {
  const [pressed, setPressed] = useState(false);

  return (
    <Pressable
      style={[
        styles.buttonBody,
        BTN_TYPE_MAP[type],
        pressed && BTN_ACTIVE_MAP[type],
        disabled && BTN_DISABLED_MAP[type],
        style,
      ]}
      disabled={disabled || loading}
      onPressIn={() => setPressed(true)}
      onPressOut={() => setPressed(false)}
      onPress={onPress}
    >
      {children}
      {loading && (
        <View style={styles.loading}>
          <ActivityIndicator size={30} color={ThemeColor.BLACK} />
          <AppText style={styles.loadingText} size={12} height={18}>
            Загрузка
          </AppText>
        </View>
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  buttonBody: {
    width: 150,
    borderRadius: 20,
    padding: 20,
    marginBottom: 20,
    position: "relative",
  },
  loading: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: ThemeColor.GREY_5,
    borderWidth: 1,
    borderColor: ThemeColor.GREY_3,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  loadingText: {
    marginTop: 5,
  },
});
