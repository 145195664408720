import {
  StyleSheet,
  View,
  Image,
  ImageURISource,
  ScrollView,
} from "react-native";
import Modal from "react-native-modal";

import { AppText } from "./AppText";
import { CloseButton } from "./CloseButton";
import { ThemeColor } from "../../theme";
import { MenuProduct } from "../../types/common";
import { currencyFormat } from "../../utils/currency-format";
import { baseUrl } from "../../utils/helpers";

type Props = {
  item: MenuProduct;
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
};

export const MenuProductView = ({ item, visible, onVisibleChange }: Props) => {
  const strToUri = (url: string): ImageURISource => ({
    uri: baseUrl + `../menu/product-image/${url}`,
  });

  // bad
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const defaultProductImage = require("../../../assets/img/no-product-image.svg");

  const logo =
    (item.imageName ?? "") !== ""
      ? strToUri(
          (item.imageName ?? "") + (item.withBigPicture ?? false ? "-big" : "")
        )
      : (defaultProductImage as ImageURISource);

  const emptyLogo = (item.imageName ?? "") === "";

  const closeView = () => {
    onVisibleChange(false);
  };

  return (
    <Modal
      style={styles.modal}
      isVisible={visible}
      backdropColor={ThemeColor.DARK}
      backdropOpacity={0.2}
      onBackdropPress={closeView}
    >
      <View style={{ ...styles.content }}>
        <CloseButton size="large" style={styles.closeBtn} onPress={closeView} />
        <View style={styles.productView}>
          <View style={styles.imageView}>
            <Image
              style={{
                ...(emptyLogo ? styles.emptyImage : styles.image),
                ...styles.imageBorder,
              }}
              source={logo}
            />
          </View>
          <View style={styles.textView}>
            <View style={{ padding: 20 }}>
              <AppText
                style={styles.title}
                size={20}
                weight={600}
                numberOfLines={2}
                ellipsizeMode="tail"
              >
                {item.name}
              </AppText>
              {item.weight && (
                <View style={styles.weight}>
                  <AppText size={16} weight={400} color={ThemeColor.GREY_4}>
                    {item.weight}
                  </AppText>
                  <AppText weight={400} color={ThemeColor.GREY_4}>
                    Грамм
                  </AppText>
                </View>
              )}
            </View>
            <View style={styles.descriptionContainer}>
              <ScrollView contentContainerStyle={styles.description}>
                <AppText size={16} weight={400}>
                  {item.description}
                </AppText>
              </ScrollView>
              <AppText style={styles.priceStyle} size={16} weight={600}>
                {currencyFormat(item.price ?? 0, "\u00A0", false)}
              </AppText>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: {
    margin: 0,
    justifyContent: "flex-end",
  },
  content: {
    width: "100%",
    backgroundColor: ThemeColor.TRANSPARENT,
    bottom: 0,
  },
  productView: {
    display: "flex",
    flexDirection: "column",
  },
  closeBtn: {
    opacity: 1,
    color: ThemeColor.GREY_6,
    position: "absolute",
    top: 16,
    right: 16,
  },
  imageView: {
    height: 192,
    width: "100%",
    borderTopRightRadius: 42,
    borderTopLeftRadius: 42,
    backgroundColor: ThemeColor.GREY_6,
    justifyContent: "center",
    alignItems: "center",
  },
  imageBorder: {
    borderTopRightRadius: 42,
    borderTopLeftRadius: 42,
  },
  emptyImage: {
    width: 85.82,
    height: 81.25,
  },
  image: {
    resizeMode: "cover",
    width: "100%",
    height: "100%",
  },
  textView: {
    backgroundColor: ThemeColor.WHITE,
  },
  weight: {
    marginVertical: 20,
    paddingHorizontal: 16,
    paddingVertical: 4,
    borderColor: ThemeColor.GREY_3,
    borderWidth: 1,
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-start",
    alignItems: "center",
    borderRadius: 12,
  },
  title: {
    color: ThemeColor.BLACK_4,
  },
  descriptionContainer: {
    maxHeight: 200,
    width: "100%",
    borderTopLeftRadius: 42,
    borderTopRightRadius: 42,
    paddingVertical: 20,
    paddingHorizontal: 20,
    marginTop: 24,
    backgroundColor: ThemeColor.GREY,
    display: "flex",
    gap: 10,
    flexDirection: "row",
  },
  description: {
    color: ThemeColor.BLACK_4,
  },
  priceStyle: {
    color: ThemeColor.BLACK_4,
  },
});
