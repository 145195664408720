import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { Image, StyleSheet, View } from "react-native";

import { AppButton } from "./AppButton";
import { AppText } from "./AppText";
import { Countdown } from "./Countdown";
import { SpecialButton } from "./SpecialButton";
import { TotalCheckModal } from "./TotalCheckModal";
import { useAppDispatch, useAppSelector } from "../../store";
import esApi from "../../store/esApi";
import { setTipsSum } from "../../store/orderSlice";
import { ThemeColor } from "../../theme";
import { useHelper } from "../../utils/useHelper";
import { USER_MAP } from "../../utils/users";
import { ConfirmationModal } from "../ConfirmationModal";

interface OtherUserPayingProps {
  guestAllUuid: string;
}

export const OtherUserPaying: React.FC<OtherUserPayingProps> = ({
  guestAllUuid,
}) => {
  const dispatch = useAppDispatch();

  const { getSearch } = useHelper();

  const [resetGuestProducts, { isLoading }] =
    esApi.useResetGuestProductsMutation();

  const { guests, transactions } = useAppSelector((state) => state.order);
  const { tipsPercentage } = useAppSelector((state) => state.payment);

  const [showCheck, setShowCheck] = useState(false);
  const [openedModal, setOpenedModal] = useState(false);

  const transaction = transactions.find(
    ({ guestUuid }) => guestUuid === guestAllUuid
  );

  const diff = dayjs(Date.now()).diff(
    dayjs(transaction?.lastChangeDate),
    "seconds"
  );

  const guest = useMemo(
    () => guests.find(({ uuid }) => guestAllUuid === uuid)?.fullName,
    [guestAllUuid]
  );

  const handleResetConfirmation = () => {
    resetGuestProducts(getSearch())
      .unwrap()
      .then(() => {
        if (tipsPercentage) {
          dispatch(setTipsSum(0));
        }
        setOpenedModal(false);
      });
  };

  const handleResetCancel = () => {
    setOpenedModal(false);
  };

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 30,
      }}
    >
      <View style={styles.container}>
        {guest && <Image style={styles.image} source={USER_MAP[guest]} />}
        <View style={styles.textWrap}>
          <AppText size={14}>Другой гость оплачивает счет</AppText>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: -18,
          }}
        >
          {transaction?.inProgress ? (
            <View
              style={{
                backgroundColor: ThemeColor.WHITE,
                borderRadius: 20,
                paddingHorizontal: 6,
                paddingVertical: 2,
                width: 60,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Countdown
                shiftInSeconds={300 - diff}
                size={18}
                onTimeout={() => console.log("timeout")}
              />
            </View>
          ) : (
            <>
              <SpecialButton
                type="reset"
                onPress={() => setOpenedModal(true)}
              />
              <ConfirmationModal
                isLoading={isLoading}
                title="Вы подтверждаете сброс позиций?"
                subtitle="После подтверждения, все выбранные позиции сбросятся"
                showModal={openedModal}
                onConfirm={handleResetConfirmation}
                onCancel={handleResetCancel}
              />
            </>
          )}
        </View>
      </View>
      <AppButton
        size="small"
        type="secondary"
        onPress={() => setShowCheck(true)}
      >
        Смотреть чек
      </AppButton>

      <TotalCheckModal
        visible={showCheck}
        onClose={() => setShowCheck(false)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: ThemeColor.WHITE,
    borderRadius: 20,
    paddingVertical: 12,
    paddingHorizontal: 20,
    marginHorizontal: 8,
    shadowColor: ThemeColor.SHADOW,
    shadowOpacity: 0.05,
    shadowRadius: 10,
    shadowOffset: {
      width: 2,
      height: 2,
    },
  },
  image: {
    width: 40,
    height: 40,
    marginRight: 12,
  },
  textWrap: {
    flex: 1,
  },
});
