export const LogLevel = {
    Verbose: 5,
    Log: 4,
    Warnings: 3,
    Errors: 2,
    Silent: 0,
};
export default class Logger {
    constructor(debugLevel = LogLevel.Silent) {
        this.level = debugLevel;
    }
    shouldLog(level) {
        return this.level >= level;
    }
    log(...args) {
        if (this.shouldLog(LogLevel.Log)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            console.log(...args);
        }
    }
    warn(...args) {
        if (this.shouldLog(LogLevel.Warnings)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            console.warn(...args);
        }
    }
    error(...args) {
        if (this.shouldLog(LogLevel.Errors)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            console.error(...args);
        }
    }
}
