import * as React from "react";
import Svg, { SvgProps, Circle, Path } from "react-native-svg";
const GuestsIcon = (props: SvgProps) => (
  <Svg width={36} height={36} fill="none" {...props}>
    <Circle cx={18} cy={18} r={18} fill="#F7F7F7" />
    <Path
      stroke="#1E1E1E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18.095 14.143C17.978 15.716 16.785 17 15.476 17c-1.31 0-2.504-1.284-2.619-2.857-.119-1.637 1.042-2.857 2.62-2.857 1.576 0 2.737 1.25 2.618 2.857Z"
    />
    <Path
      stroke="#1E1E1E"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M15.476 18.904c-2.59 0-5.217 1.43-5.703 4.125-.059.325.125.637.465.637h10.476c.34 0 .524-.312.466-.637-.487-2.696-3.115-4.125-5.704-4.125Z"
    />
    <Path
      fill="#F7F7F7"
      stroke="#1E1E1E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M22.143 14.143c-.117 1.573-1.31 2.857-2.62 2.857-1.309 0-2.504-1.284-2.618-2.857-.12-1.637 1.041-2.857 2.619-2.857 1.577 0 2.738 1.25 2.619 2.857Z"
    />
    <Path
      fill="#F7F7F7"
      stroke="#1E1E1E"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M19.524 18.904c-2.59 0-5.217 1.43-5.704 4.125-.058.325.126.637.466.637h10.476c.34 0 .524-.312.465-.637-.486-2.696-3.114-4.125-5.703-4.125Z"
    />
  </Svg>
);
export default GuestsIcon;
