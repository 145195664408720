import { StyleSheet, View } from "react-native";
import Svg, { Path } from "react-native-svg";

type Props = {
  style?: StyleSheet.NamedStyles<unknown>;
};

export const ResetIcon = ({ style }: Props) => (
  <View style={style}>
    <Svg width="12" height="12" viewBox="0 0 12 12" fill="none">
      <Path
        d="M10.4444 3.77778C9.665 2.13333 7.965 1 6 1C5.34339 1 4.69321 1.12933 4.08658 1.3806C3.47996 1.63188 2.92876 2.00017 2.46447 2.46447C2.00017 2.92876 1.63188 3.47996 1.3806 4.08658C1.12933 4.69321 1 5.34339 1 6C1 6.65661 1.12933 7.30679 1.3806 7.91342C1.63188 8.52005 2.00017 9.07124 2.46447 9.53553C2.92876 9.99983 3.47996 10.3681 4.08658 10.6194C4.69321 10.8707 5.34339 11 6 11C7.32608 11 8.59785 10.4732 9.53553 9.53553C10.4732 8.59785 11 7.32608 11 6M11 1V4.33333H7.66667"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </Svg>
  </View>
);
