import { ActivityIndicator, StyleSheet, View } from "react-native";

import { AppButton } from "../components/ui/AppButton";
import { AppText } from "../components/ui/AppText";
import { ThemeColor } from "../theme";

interface LoadingScreenProps {
  onCancel?: VoidFunction;
  onRetry?: VoidFunction;
}

export const LoadingScreen: React.FC<LoadingScreenProps> = ({
  onCancel,
  onRetry,
}) => {
  return (
    <View style={styles.container}>
      <View style={styles.infoGroup}>
        {/* <ImageBackground
          style={styles.imageWrap}
          source={require("../../assets/img/loader-animated-bg.svg")}
        /> */}

        <ActivityIndicator
          style={styles.imageWrap}
          size={150}
          color={ThemeColor.PINK_STROKE}
        />

        <AppText style={styles.title} weight={600} size={20} height={30}>
          Ждем информацию от банка
        </AppText>
        <AppText
          style={styles.subtitle}
          size={16}
          height={24}
          color={ThemeColor.GREY_4}
        >
          Если перейти в приложение банка не удалось, выберите другой банк или
          способ оплаты
        </AppText>
      </View>

      <View style={styles.buttonGroup}>
        {onRetry && (
          <AppButton
            onPress={onRetry}
            style={styles.cancelBtn}
            type="link"
            size="large"
          >
            Выбрать другой банк
          </AppButton>
        )}
        {onCancel && (
          <AppButton
            onPress={onCancel}
            style={styles.cancelBtn}
            type="secondary"
            size="large"
          >
            Отменить
          </AppButton>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  infoGroup: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    paddingTop: 100,
  },
  imageWrap: {
    width: 165,
    height: 165,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
  },
  image: {
    width: 70,
    height: 70,
  },
  title: {
    marginBottom: 4,
  },
  subtitle: {
    maxWidth: 330,
    textAlign: "center",
  },
  buttonGroup: {
    alignItems: "center",
    margin: 20,
  },
  cancelBtn: {
    width: "100%",
  },
});
