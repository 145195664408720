import * as Font from "expo-font";

import { ThemeFont } from "./theme";

export async function bootstrap() {
  await Font.loadAsync({
    [ThemeFont.MEDIUM]: require("../assets/fonts/Golos-Text-medium.ttf"),
    [ThemeFont.REGULAR]: require("../assets/fonts/Golos-Text-regular.ttf"),
    [ThemeFont.SEMIBOLD]: require("../assets/fonts/Golos-Text-semibold.ttf"),
    [ThemeFont.BOLD]: require("../assets/fonts/Golos-Text-bold.ttf"),
    [ThemeFont.EXTRABOLD]: require("../assets/fonts/Golos-Text-extrabold.ttf"),
  });
}
