import * as React from "react"
import Svg, { SvgProps, Circle, Path } from "react-native-svg"
const SvgComponent = (props: SvgProps) => (
  <Svg
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <Circle cx={18} cy={18} r={18} fill="#F7F7F7" />
    <Path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18 15.5c0-.833.595-1.667 1.786-1.667a2.38 2.38 0 0 0 2.38-2.38v-.536m-7.5 4.583v-.417a2.5 2.5 0 0 1 2.5-2.5 1.667 1.667 0 0 0 1.667-1.666V10.5m3.334 7.167V20.5a5 5 0 0 1-5 5H15.5a5 5 0 0 1-5-5v-2.833a.5.5 0 0 1 .5-.5h10.667a.5.5 0 0 1 .5.5Z"
    />
    <Path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M21.333 17.167h2.084a2.083 2.083 0 0 1 0 4.166h-1.25"
    />
  </Svg>
)
export default SvgComponent
