import {
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import Constants from "expo-constants";
import Toast from "react-native-toast-message";

import store, { RootState } from ".";
import { setFeedbackSent } from "./appSlice";
import {
  addNewGuest,
  setTipsSum,
  updateGuestProducts,
  updateOrder,
  updateTransactions,
} from "./orderSlice";
import { resetLoyalty, setGuestAllUuid } from "./paymentSlice";
import {
  Comment,
  Guest,
  GuestProduct,
  NSPKResponse,
  OrderSession,
  PayByCardDTO,
  PayByCardResponse,
  PayBySBPResponse,
  Payments,
  Product,
  Transaction,
  checkLoyaltyResponse,
  GetMenuResponse,
} from "../types/common";
import { ApiEvents } from "../utils/Events";
import { setCookie, updateJWT } from "../utils/useHelper";

const events = new ApiEvents("order");

export const esApi = createApi({
  reducerPath: "esApi",
  tagTypes: ["Order", "Banks"],

  baseQuery: fetchBaseQuery({
    baseUrl: Constants.manifest?.extra?.baseUrl,
  }),
  endpoints: (builder) => ({
    getOrder: builder.query<OrderSession, { order: string; reset: boolean }>({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        const { order, reset } = arg;

        const result = await fetchWithBQ(`orders${order}&reset=${reset}`);

        const orderSession = result.data as OrderSession;

        events.disconnect();

        await events.connect(
          orderSession.order.uuid,
          () => {
            events.subscribeGuest((guest: Guest) => {
              const isGuestExist = orderSession.order.guests.some(
                (g) => g.uuid === guest.uuid
              );
              if (isGuestExist) {
                return;
              }
              store.dispatch(addNewGuest(guest));
            });

            events.subscribeSplit(
              (event: {
                equally: boolean;
                guestAllUuid?: string;
                orderUuid: string;
                productGuests: GuestProduct[];
              }) => {
                store.dispatch(updateGuestProducts(event.productGuests));
                store.dispatch(setGuestAllUuid(event.guestAllUuid as string));
              }
            );

            events.subscribePayment((transaction: Transaction) => {
              console.log(transaction);
              store.dispatch(updateTransactions(transaction));
            });

            events.subscribeUpdate(
              (update: {
                products: Product[];
                productGuests: GuestProduct[];
                total: number;
                discount: number;
              }) => {
                store.dispatch(updateOrder(update));
              }
            );
          },
          () => {
            console.log("disconnectCB run");
          }
        );

        updateJWT(orderSession.jwt, orderSession.order.uuid);

        if (orderSession !== undefined && global.tracker !== undefined) {
          global.tracker.setUserID(orderSession.currentGuest.uuid);
          global.tracker.setMetadata("order", orderSession.order.uuid);
        }

        return result.data
          ? { data: result.data as OrderSession }
          : { error: result.error as FetchBaseQueryError };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      providesTags: ["Order"],
    }),

    resetGuestProducts: builder.mutation<void, string>({
      query: (order) => ({
        url: `orders/reset${order}`,
        method: "POST",
      }),
    }),

    updateGuestProduct: builder.mutation<
      void,
      { product: string; parts: number; position?: number; order: string }
    >({
      query: ({ order, ...body }) => ({
        url: `orders/split${order}`,
        method: "POST",
        body,
      }),
    }),

    setGuestProductAll: builder.mutation<void, string>({
      query: (order) => ({
        url: `orders/split/all${order}`,
        method: "POST",
      }),
    }),

    comment: builder.mutation<
      Comment,
      { order: string; stars: number; comment: string }
    >({
      query: ({ order, ...body }) => ({
        url: `orders/comments${order}`,
        method: "POST",
        body,
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        const { data } = await queryFulfilled;

        if (data.comment && data.stars) {
          dispatch(setFeedbackSent(true));
        }
      },
    }),

    updateTips: builder.mutation<
      {
        percentage: boolean;
        value: number;
      },
      { percentage: boolean; value: number; order: string }
    >({
      query: ({ order, percentage, value }) => ({
        url: `payments/tips${order}&percentage=${percentage}&value=${value}`,
        method: "POST",
      }),
    }),

    getBanks: builder.query<NSPKResponse, void>({
      query: () => "https://qr.nspk.ru/proxyapp/c2bmembers.json",
      providesTags: ["Banks"],
    }),

    payStart: builder.mutation<
      string,
      {
        tipsPercentage: boolean;
        tipsValue: number;
        order: string;
        takeServiceFee: boolean;
      }
    >({
      query: ({ order, tipsPercentage, tipsValue, takeServiceFee }) => ({
        url: `payments${order}&isFee=${takeServiceFee}&percentage=${tipsPercentage}&value=${tipsValue}`,
        method: "POST",
      }),
    }),

    payByCardNew: builder.mutation<
      string,
      {
        tipsPercentage: boolean;
        tipsValue: number;
        order: string;
        takeServiceFee: boolean;
      }
    >({
      query: ({ order, tipsPercentage, tipsValue, takeServiceFee }) => ({
        url: `payments/new_card${order}&isFee=${takeServiceFee}&percentage=${tipsPercentage}&value=${tipsValue}`,
        method: "POST",
      }),
    }),

    payByCard: builder.mutation<
      PayByCardResponse,
      {
        order: string;
        body: PayByCardDTO;
      }
    >({
      query: ({ order, body }) => ({
        url: `payments/card${order}`,
        method: "POST",
        body,
      }),
      async onQueryStarted(arg, { queryFulfilled, getState }) {
        const { data } = await queryFulfilled;

        if (arg.body.saveCard && arg.body.card) {
          setCookie("crd_session", data.session, {
            secure: true,
            "max-age": data.max_age,
          });
          setCookie("crd_last", arg.body.card.split(" ")[3], {
            secure: true,
            "max-age": data.max_age,
          });
        }

        if ((getState() as RootState).order.restaurantName !== "Демо") {
          // window.open(data.url, "_blank");
          window.location.href = data.url;
        }
      },
    }),

    payBySBP: builder.mutation<
      PayBySBPResponse,
      {
        tipsPercentage: boolean;
        tipsValue: number;
        order: string;
        takeServiceFee: boolean;
      }
    >({
      query: ({ order, tipsPercentage, tipsValue, takeServiceFee }) => ({
        url: `payments/sbp${order}&isFee=${takeServiceFee}&percentage=${tipsPercentage}&value=${tipsValue}&email=`,
        method: "POST",
      }),
    }),

    payTimeout: builder.mutation<void, string>({
      query: (order) => ({
        url: `payments/timeout${order}`,
        method: "POST",
      }),
      async onQueryStarted(_, { queryFulfilled, getState, dispatch }) {
        await queryFulfilled;

        if (
          (getState() as RootState).payment.tipsPercentage &&
          (getState() as RootState).app.section !== "PayAll"
        ) {
          dispatch(setTipsSum(0));
        }

        dispatch(resetLoyalty());
      },
    }),

    cancelLoyalty: builder.mutation<void, string>({
      query: (order) => ({
        url: `loyalty/cancel${order}`,
        method: "POST",
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(resetLoyalty());
        } catch (err) {
          console.log(err);
        }
      },
    }),

    checkLoyalty: builder.mutation<
      checkLoyaltyResponse,
      | { order: string; loyalParam1: string; loyalParam2?: string }
      | { order: string; loyalParam1: "saved"; credential: string }
    >({
      query: ({ order, ...body }) => ({
        url: `loyalty/check${order}`,
        method: "POST",
        body,
      }),
    }),

    processLoyalty: builder.mutation<
      { processed: boolean; bonus: number },
      { order: string; loyalParam1: string; loyalParam2?: string }
    >({
      query: ({ order, ...body }) => ({
        url: `loyalty/process${order}`,
        method: "POST",
        body,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data.processed === false) {
            Toast.show({
              type: "error",
              text1: "Ошибка",
              text2: "Не удалось списать бонусы!",
            });
          }
        } catch (err) {
          console.log(err);
          Toast.show({
            type: "error",
            text1: "Ошибка",
            text2: "Не удалось списать бонусы!",
          });
        }
      },
    }),

    setBonus: builder.mutation<void, { order: string; value: number }>({
      query: ({ order, value }) => ({
        url: `payments/bonus${order}&value=${value}`,
        method: "POST",
      }),
    }),

    checkVPN: builder.query<{ ip: string; country: string }, void>({
      query: () => "https://api.country.is",
    }),

    getPayments: builder.query<Payments, string>({
      query: (order) => `payments${order}`,
    }),

    callWaiter: builder.mutation<void, { order: string; message: string }>({
      query: ({ order, ...body }) => ({
        url: `orders/waiter-call${order}`,
        method: "POST",
        body,
      }),
    }),

    getMenu: builder.query<GetMenuResponse, { restaurant: number }>({
      query: ({ restaurant }) => ({
        url: `../menu/guest?id=${restaurant}`,
      }),
    }),
  }),
});

export default esApi;
