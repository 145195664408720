import { PropsWithChildren } from "react";
import { StyleSheet, Text, TextProps } from "react-native";

import { ThemeColor, ThemeFont } from "../../theme";

type FontWeight = 400 | 500 | 600 | 700 | 800;
type FontWeightMap = Record<FontWeight, ThemeFont>;

interface Props extends TextProps {
  weight?: FontWeight;
  size?: number;
  color?: string | ThemeColor;
  height?: number;
  numberOfLines?: number;
  space?: number;
  style?: StyleSheet.NamedStyles<unknown>;
}

const FONT_WEIGHT_MAP: FontWeightMap = {
  400: ThemeFont.REGULAR,
  500: ThemeFont.MEDIUM,
  600: ThemeFont.SEMIBOLD,
  700: ThemeFont.BOLD,
  800: ThemeFont.EXTRABOLD,
};

export const AppText = ({
  weight = 500,
  size = 14,
  color = ThemeColor.BLACK,
  height,
  numberOfLines,
  space = 0,
  style,
  children,
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <Text
      {...props}
      style={[
        {
          fontFamily: FONT_WEIGHT_MAP[weight],
          fontSize: size,
          letterSpacing: space,
          color,
        },
        !!height && { lineHeight: height },
        style,
      ]}
      numberOfLines={numberOfLines}
    >
      {children}
    </Text>
  );
};
