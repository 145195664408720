import { useState } from "react";
import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  ViewStyle,
} from "react-native";

import { ThemeColor } from "../../theme";
import { CloseIcon } from "../icons/CloseIcon";

type ButtonSize = "small" | "large";
type ButtonSizeMap = Record<ButtonSize, ViewStyle>;

type Props = {
  style?: StyleSheet.NamedStyles<unknown>;
  size?: ButtonSize;
  onPress?: () => void;
  isLoading?: boolean;
};

const BTN_BACKDROP_SIZE: ButtonSizeMap = {
  small: { height: 30, width: 30 },
  large: { height: 40, width: 40 },
};

const BTN_SIZE_MAP: ButtonSizeMap = {
  small: { height: 20, width: 20 },
  large: { height: 28, width: 28 },
};

export const CloseButton = ({
  style,
  size = "small",
  onPress,
  isLoading,
}: Props) => {
  const [pressed, setPressed] = useState(false);

  const handlePressOut = () => {
    setPressed(false);
    onPress && onPress();
  };

  return (
    <Pressable
      style={[
        styles.button,
        BTN_BACKDROP_SIZE[size],
        pressed && styles.buttonActive,
        style,
      ]}
      onPressIn={() => setPressed(true)}
      onPress={handlePressOut}
    >
      {isLoading ? (
        <ActivityIndicator size={size} color={ThemeColor.GREY_5} />
      ) : (
        <CloseIcon style={BTN_SIZE_MAP[size]} />
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  button: {
    opacity: 0.2,
    color: ThemeColor.DARK,
    zIndex: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonActive: {
    opacity: 1,
    color: ThemeColor.BLACK_3,
  },
});
