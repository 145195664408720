import { Image, StyleSheet, View } from "react-native";

import { AppButton } from "../components/ui/AppButton";
import { AppText } from "../components/ui/AppText";

interface ErrorScreenProps {
  onCancel?: VoidFunction;
  onRetry?: VoidFunction;
  isLoading?: boolean;
}

export const ErrorScreen: React.FC<ErrorScreenProps> = ({
  onCancel,
  onRetry,
  isLoading,
}) => {
  return (
    <View style={styles.container}>
      <View style={styles.infoGroup}>
        <Image
          style={styles.img}
          source={require("../../assets/img/sad-apple.svg")}
        />
        <AppText style={styles.title} weight={700} size={32} height={48}>
          ОЙ..
        </AppText>
        <AppText size={16} height={24}>
          Что-то пошло не так
        </AppText>
      </View>

      <View style={styles.buttonGroup}>
        {onRetry && (
          <AppButton
            disabled={isLoading}
            onPress={onRetry}
            type="link"
            size="large"
          >
            Повторить
          </AppButton>
        )}
        {onCancel && (
          <AppButton
            onPress={onCancel}
            style={styles.cancelBtn}
            type="secondary"
            size="large"
          >
            Отменить
          </AppButton>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  infoGroup: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    paddingTop: 100,
  },
  img: {
    width: "100%",
    height: "60vw",
    marginBottom: 24,
  },
  title: {
    marginBottom: 16,
  },
  buttonGroup: {
    alignItems: "center",
    margin: 20,
  },
  cancelBtn: {
    width: "100%",
  },
});
