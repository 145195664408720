import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";
const BasketIcon = (props: SvgProps) => (
  <Svg width={21} height={20} fill="none" {...props}>
    <Path
      stroke="#AAA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M15.2 5.467 13.6 1.2M5.598 5.467l1.6-4.267M2.933 5.467h14.933a2.133 2.133 0 0 1 2.109 2.457l-1.339 7.63a3.2 3.2 0 0 1-3.163 2.713H5.325a3.2 3.2 0 0 1-3.163-2.713L.825 7.923a2.133 2.133 0 0 1 2.108-2.457Z"
    />
  </Svg>
);
export default BasketIcon;
