import { Image, StyleSheet, Text, View } from "react-native";

import { ThemeColor, ThemeFont } from "../../theme";

export const SuccessChip = () => {
  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        source={require("../../../assets/img/success.svg")}
      />
      <Text style={styles.text}>Оплачен</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 25,
    backgroundColor: ThemeColor.GREEN_LIGHT,
    borderRadius: 16,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 23,
    marginLeft: "auto",
  },
  image: {
    height: 13,
    width: 13,
    marginRight: 5,
  },
  text: {
    fontFamily: ThemeFont.MEDIUM,
    fontSize: 12,
    color: ThemeColor.GREEN,
  },
});
