import { Controller, useForm } from "react-hook-form";
import { StyleSheet, View } from "react-native";
import { unMask } from "react-native-mask-text";

import { useAppDispatch, useAppSelector } from "../../store";
import esApi from "../../store/esApi";
import {
  setLoyaltyCredentials,
  setLoyaltyLogin,
} from "../../store/paymentSlice";
import { ThemeColor } from "../../theme";
import { getSearch } from "../../utils/useHelper";
import { AppButton } from "../ui/AppButton";
import { AppInput } from "../ui/AppInput";
import { AppText } from "../ui/AppText";
import { UserNotFoundChip } from "../ui/UserNotFoundChip";

interface FormData {
  loyalParam1: string;
  loyalParam2: string;
}

interface PrimeHillProps {
  onVisibleChange: (visible: boolean) => void;
}

const PrimeHill: React.FC<PrimeHillProps> = ({ onVisibleChange }) => {
  const {
    control,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onTouched",
    defaultValues: {
      loyalParam1: "",
      loyalParam2: "",
    },
  });

  const { restaurantId } = useAppSelector((state) => state.order);

  const [checkLoyalty, { isLoading, data }] = esApi.useCheckLoyaltyMutation();

  const dispatch = useAppDispatch();

  return (
    <View style={styles.form}>
      <AppText
        style={{ alignSelf: "start" }}
        weight={600}
        size={20}
        height={30}
      >
        Войти
      </AppText>
      {data?.found === false && <UserNotFoundChip />}
      <Controller
        control={control}
        rules={{
          required: "Укажите номер телефона",
        }}
        render={({ field: { onChange, onBlur, value, name } }) => (
          <AppInput
            key="input-loyalty-code"
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            label="Телефон"
            mask="+7 (999) 999 99 99"
            placeholder="+7 (999) 999 99 99"
            style={{ width: "100%" }}
            error={errors?.loyalParam1?.message}
            reset={() => resetField(name)}
          />
        )}
        name="loyalParam1"
      />
      <Controller
        control={control}
        rules={{
          required: "Укажите номер карты",
        }}
        render={({ field: { onChange, onBlur, value, name } }) => (
          <AppInput
            key="input-loyalty-code"
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            label="Номер карты"
            mask="99999999"
            placeholder="12312312"
            style={{ width: "100%" }}
            error={errors?.loyalParam2?.message}
            reset={() => resetField(name)}
          />
        )}
        name="loyalParam2"
      />
      <AppButton
        disabled={isLoading}
        onPress={handleSubmit(({ loyalParam1, loyalParam2 }) =>
          checkLoyalty({
            order: getSearch(),
            loyalParam1: unMask(loyalParam1),
            loyalParam2,
          })
            .unwrap()
            .then(({ found, intId }) => {
              if (found) {
                dispatch(setLoyaltyLogin(true));
                dispatch(
                  setLoyaltyCredentials({
                    loyalty: "PrimeHill",
                    id: intId,
                    rest: restaurantId,
                  })
                );
                onVisibleChange(false);
              }
            })
        )}
        size="large"
        style={{ width: "100%" }}
      >
        Далее
      </AppButton>
    </View>
  );
};

export default PrimeHill;

const styles = StyleSheet.create({
  modal: {
    margin: 0,
    justifyContent: "flex-end",
  },
  content: {
    width: "100%",
    maxHeight: "100%",
    borderTopLeftRadius: 42,
    borderTopRightRadius: 42,
    padding: 16,
    paddingTop: 32,
    position: "relative",
    backgroundColor: ThemeColor.WHITE,
  },
  closeBtn: {
    position: "absolute",
    top: 16,
    right: 16,
  },
  form: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: 14,
  },
  cardImg: {
    overflow: "hidden",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    height: 100,
    width: 400,
    position: "absolute",
    top: -100,
    zIndex: 100,
  },
});
