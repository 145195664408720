import { useNavigation } from "@react-navigation/native";
import { useState, useEffect } from "react";
import {
  KeyboardAvoidingView,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import Toast from "react-native-toast-message";

import { PayAllBillScreen } from "./PayAllBillScreen";
import { ShareBillScreen } from "./ShareBillScreen";
import { TipsScreen } from "./TipsScreen";
import { Header } from "../components/Header";
import { NavigationPanel } from "../components/NavigationPanel";
import { Payment } from "../components/Payment";
import Tapbar from "../components/Tapbar";
import WaiterCallButton from "../components/WaiterCallButton";
import { StackNavigation } from "../navigation/AppNavigation";
import { useAppDispatch, useAppSelector } from "../store";
import { setSection } from "../store/appSlice";
import esApi from "../store/esApi";
import { setTotal } from "../store/orderSlice";
import { ThemeColor } from "../theme";
import { redirectToUUID } from "../utils/helpers";
import { useHelper } from "../utils/useHelper";

export const MainScreen = () => {
  const navigation = useNavigation<StackNavigation>();
  const dispatch = useAppDispatch();

  const { getSearch, getObjectSearch, retrieveJWT } = useHelper();
  const [getOrder, { data: orderSession, isSuccess }] =
    esApi.useLazyGetOrderQuery();
  const { total, currentTransaction, discount } = useAppSelector(
    (state) => state.order
  );

  const [checkVPN] = esApi.useLazyCheckVPNQuery();

  const { section } = useAppSelector((state) => state.app);

  const [scrollActive] = useState(true);

  useEffect(() => {
    const queryTotal = getObjectSearch().get("s");

    if (queryTotal) {
      dispatch(setTotal(parseInt(queryTotal, 10)));
    }

    retrieveJWT();
    getOrder({ order: getSearch(), reset: true });

    checkVPN()
      .unwrap()
      .then(({ country }) => {
        if (country !== "RU") {
          Toast.show({
            type: "vpn",
          });
        }
      });
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        getOrder({ order: getSearch(), reset: false });
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (isSuccess && currentTransaction.success) {
      navigation.navigate("PostPayment");
    }
  }, [currentTransaction]);

  useEffect(() => {
    if (orderSession?.order.fake || orderSession?.modelService === "Tips") {
      dispatch(setSection("Tips"));
    }
  }, [orderSession]);

  useEffect(() => {
    if (orderSession) {
      if (orderSession.order.redirectToUUID) {
        redirectToUUID(orderSession);
      }
    }
  }, [orderSession]);

  useEffect(() => {
    if (
      // orderSession?.tableOrders
      orderSession?.tableOrders &&
      orderSession.tableOrders.filter(
        ({ total: orderTotal }) => orderTotal !== 0
      ).length > 1
    ) {
      navigation.navigate("SelectOrder");
    }
  }, [orderSession]);

  const showWaiterButton =
    orderSession?.waiterCallType && orderSession?.waiterCallType !== "NONE";

  const showTapbar =
    orderSession?.menuType && orderSession?.menuType !== "NONE";

  return (
    <KeyboardAvoidingView behavior="padding" style={styles.container}>
      <ScrollView
        contentContainerStyle={{ minHeight: "100%" }}
        scrollEnabled={scrollActive}
      >
        <Header
          background={orderSession?.order.restaurantBackground}
          logo={orderSession?.order.restaurantLogo}
          name={orderSession?.order.restaurantName}
        />
        <View style={styles.navigation}>
          {!(
            orderSession?.order.fake || orderSession?.modelService === "Tips"
          ) && <NavigationPanel totalAmount={total - discount} />}
          {orderSession &&
            getObjectSearch().get("jwt") &&
            !currentTransaction.success && (
              <>
                {section === "Tips" && <TipsScreen />}
                {section === "PayAll" && <PayAllBillScreen />}
                {section === "Split" && <ShareBillScreen />}
              </>
            )}
        </View>
        {section && <Payment />}
      </ScrollView>
      {showWaiterButton && (
        <WaiterCallButton style={{ bottom: showTapbar ? 100 : 16 }} />
      )}
      {showTapbar && <Tapbar />}
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: "100vw",
    maxHeight: "100svh",
    backgroundColor: ThemeColor.GREY,
  },
  navigation: {
    flexGrow: 1,
    paddingHorizontal: 16,
    paddingBottom: 20,
  },
});
