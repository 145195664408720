import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import esApi from "./esApi";

export type NavSection = "Tips" | "PayAll" | "Split" | null;

interface AppSlice {
  userId?: string;
  section: NavSection;
  feedbackSent: boolean;
  waiterMessages: string[];
}

export const initialState: AppSlice = {
  section: null,
  feedbackSent: false,
  waiterMessages: [],
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setSection: (state, { payload }: PayloadAction<NavSection>) => {
      state.section = payload;
    },
    setFeedbackSent: (state, { payload }: PayloadAction<boolean>) => {
      state.feedbackSent = payload;
    },
    addWaiterMessage: (state, { payload }: PayloadAction<string>) => {
      state.waiterMessages.push(payload);
    },
  },

  extraReducers(builder) {
    builder.addMatcher(
      esApi.endpoints.getOrder.matchFulfilled,
      (state, { payload }) => {
        if (payload.rating && payload.comment) {
          state.feedbackSent = true;
        }
      }
    );
  },
});

export const { setSection, setFeedbackSent, addWaiterMessage } =
  appSlice.actions;

export default appSlice.reducer;
