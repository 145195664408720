import { RefObject } from "react";
import { ScrollView, StyleSheet, View } from "react-native";

import { MenuCategoryInHeader } from "./MenuCategoryInHeader";
import { ThemeColor } from "../../theme";
import { MenuCategory } from "../../types/common";

type Props = {
  items: MenuCategory[];
  onCategoryPress: (id: number) => void;
  scrollRef: RefObject<ScrollView>;
  onLayout: (categoryId: number | undefined, y: number) => void | undefined;
};

export const MenuCategoriesHeader = ({
  items,
  onCategoryPress,
  scrollRef,
  onLayout,
}: Props) => {
  return (
    <ScrollView
      horizontal
      style={styles.container}
      showsHorizontalScrollIndicator={false}
      ref={scrollRef}
    >
      <View style={styles.firstEmpty} />
      {items.map((item, i) => {
        return (
          <MenuCategoryInHeader
            item={item}
            key={"cat-h-" + i}
            onCategoryPress={onCategoryPress}
            onLayout={onLayout}
          />
        );
      })}
      <View style={styles.lastEmpty} />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 68,
    paddingTop: 28,
    display: "flex",
    flexDirection: "column",
    backgroundColor: ThemeColor.GREY,
  },
  firstEmpty: {
    width: 16,
  },
  lastEmpty: {
    flexGrow: 1,
    borderBottomWidth: 1,
    borderBottomColor: ThemeColor.GREY_3,
  },
});
