import { StyleSheet, View, ViewStyle } from "react-native";

import { AppText } from "./AppText";
import { ThemeColor } from "../../theme";
import { currencyFormat } from "../../utils/currency-format";

type Props = {
  amount: number;
  title: string;
  price: number;
  style?: ViewStyle;
};

export const ProductItem = ({ amount, title, price, style }: Props) => {
  return (
    <View style={[styles.container, style]}>
      <View style={styles.amountBox}>
        <AppText size={12} height={18}>
          {amount}
        </AppText>
      </View>
      <AppText style={styles.title} size={16} height={24}>
        {title}:
      </AppText>
      <AppText style={styles.price} size={18} height={27} weight={600}>
        {currencyFormat(price)}
      </AppText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    marginBottom: 28,
  },
  amountBox: {
    width: 32,
    height: 21,
    borderRadius: 4,
    backgroundColor: ThemeColor.GREY_2,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 8,
    marginTop: 2,
  },
  title: {
    paddingRight: 8,
  },
  price: {
    marginLeft: "auto",
    whiteSpace: "nowrap",
  },
});
