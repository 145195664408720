import { useRef } from "react";
import { FlatList, StyleSheet, View } from "react-native";

import { AppText } from "./AppText";
import { MenuProductPreview } from "./MenuProductPreview";
import { MenuCategory, MenuProduct } from "../../types/common";

type Props = {
  item: MenuCategory;
  productChoosen: (product: MenuProduct) => void;
  onLayout: (
    categoryId: number | undefined,
    y: number,
    height: number
  ) => void | undefined;
};

export const MenuCategoryInList = ({
  item,
  productChoosen,
  onLayout,
}: Props) => {
  const topRef = useRef<null | View>(null);

  return (
    <View
      ref={topRef}
      onLayout={(event) => {
        topRef.current?.measureInWindow((x, y, w, h) => {
          onLayout(item.id, y, h);
        });
      }}
      style={styles.container}
    >
      <AppText size={20} weight={600} style={{ paddingBottom: 12 }}>
        {item.name}
      </AppText>
      <FlatList
        data={item.products}
        keyExtractor={(product) => product.id.toString()}
        contentContainerStyle={{ gap: 12 }}
        columnWrapperStyle={{ gap: 12 }}
        renderItem={({ item: productItem }) => {
          return (
            <MenuProductPreview
              item={productItem}
              onProductSelected={productChoosen}
            />
          );
        }}
        numColumns={2}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: { paddingTop: 40 },
});
