import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import esApi from "./esApi";
import { mapProducts, mapSharedProducts } from "./orderHelpers";
import { Rating } from "../components/ui/FeedbackBanner";
import {
  Comment,
  Guest,
  GuestProduct,
  LoyalSystem,
  MenuType,
  OrderSession,
  Payments,
  Product,
  SharedProduct,
  Transaction,
} from "../types/common";

interface OrderSlice {
  modelService: "Pays" | "Tips" | "PaysTips" | "TipsInPays";
  paymentModel: "AllAndPartial" | "OnlyPartial" | "OnlyAll";
  discount: number;
  disableDiscount: boolean;
  guests: Guest[];
  guestProducts: GuestProduct[];
  transactions: Transaction[];
  currentTransaction: Transaction;
  products: Product[];
  sharedProducts: SharedProduct[];
  total: number;
  tips: number[];
  tipsValues: number[];
  tipsFee: number;
  waiterName?: string;
  waiterAvatar?: string;
  waiterWishName?: string;
  waiterWishText?: string;
  currentGuest: Guest;
  productsSum: number;
  tipsSum: number;
  fee: number;
  rating: Rating;
  comment: string;
  uuid: string;
  restaurantName: string;
  fake: boolean;
  maxService: number;
  payDisabled: boolean;
  closed?: string;
  loyalSystem?: LoyalSystem;
  loyalUrl?: string;
  menuType?: MenuType;
  mapLinks: {
    yandex?: string;
    "2gis"?: string;
    google?: string;
  };
  restaurantId: number;
}

export const initialState: OrderSlice = {
  mapLinks: {},
  modelService: "PaysTips",
  paymentModel: "AllAndPartial",
  guests: [],
  guestProducts: [],
  transactions: [],
  products: [],
  sharedProducts: [],
  total: 0,
  tips: [7, 10, 15, 20],
  tipsValues: [50, 100, 200, 500],
  currentGuest: {
    fullName: "Манго",
    uuid: "1",
  },
  productsSum: 0,
  tipsSum: 0,
  disableDiscount: false,
  discount: 0,
  fee: 0,
  maxService: 10000000000000000000000000000,
  tipsFee: 0,
  currentTransaction: {
    guestFullName: "",
    guestUuid: "",
    total: 0,
    id: 0,
    products: 0,
    alcohol: 0,
    success: false,
    totalAll: 0,
    inProgress: false,
    tips: 0,
    bonus: 0,
  },
  rating: Rating.NONE,
  comment: "",
  uuid: "",
  restaurantName: "",
  fake: false,
  payDisabled: false,
  restaurantId: 0,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setTotal: (state, { payload }: PayloadAction<number>) => {
      state.total = payload;
    },

    setComment: (state, { payload }: PayloadAction<string>) => {
      state.comment = payload;
    },

    setRating: (state, { payload }: PayloadAction<number>) => {
      state.rating = payload;
    },

    setProductsSum: (state, { payload }: PayloadAction<number>) => {
      state.productsSum = payload;
    },

    setTipsSum: (state, { payload }: PayloadAction<number>) => {
      state.tipsSum = payload;
    },

    updateTransactions: (state, { payload }: PayloadAction<Transaction>) => {
      if (payload.guestUuid === state.currentTransaction.guestUuid) {
        state.currentTransaction = payload;
      }

      const index = state.transactions.findIndex(
        (t) => t.guestUuid === payload.guestUuid
      );

      if (index !== -1) {
        state.transactions[index] = payload;
      } else {
        state.transactions.push(payload);
      }
    },

    addNewGuest: (state, { payload }: PayloadAction<Guest>) => {
      state.guests.push(payload);
    },

    updateGuestProducts: (
      state,
      { payload }: PayloadAction<GuestProduct[]>
    ) => {
      const newGuestProducts = state.guestProducts;

      payload.forEach((product) => {
        const foundProductIndex = newGuestProducts.findIndex(
          (gp) =>
            product.productUuid === gp.productUuid &&
            product.guestUuid === gp.guestUuid &&
            product.position === gp.position
        );

        if (foundProductIndex >= 0) {
          newGuestProducts[foundProductIndex] = product;
        } else {
          newGuestProducts.push(product);
        }
      });

      state.guestProducts = newGuestProducts;
    },

    updateOrder: (
      state,
      {
        payload,
      }: PayloadAction<{
        products: Product[];
        productGuests: GuestProduct[];
        total: number;
        discount: number;
      }>
    ) => {
      state.products = mapProducts(payload.products, state.disableDiscount);

      state.sharedProducts = mapSharedProducts(
        payload.products,
        state.disableDiscount
      );

      state.guestProducts = payload.productGuests;
      state.total = payload.total;
      state.discount = payload.discount;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      esApi.endpoints.getOrder.matchFulfilled,
      (state, { payload }: PayloadAction<OrderSession>) => {
        state.total = payload.order.total;

        const { tips1, tips2, tips3, tips4 } = payload.order;
        const { tipsValue1, tipsValue2, tipsValue3, tipsValue4 } =
          payload.order;

        state.tips = [tips1, tips2, tips3, tips4];
        state.tipsValues = [tipsValue1, tipsValue2, tipsValue3, tipsValue4];

        state.restaurantName = payload.order.restaurantName;
        state.uuid = payload.order.uuid;

        state.discount = payload.order.discount;
        state.fee = payload.order.fee;
        state.tipsFee = payload.order.tipsFee;

        state.waiterAvatar = payload.order?.userAvatarURI;
        state.waiterName = payload.order?.waiterName;
        state.waiterWishName = payload.order?.waiterWishName;
        state.waiterWishText = payload.order?.waiterWishText;

        state.currentGuest = payload.currentGuest;
        state.guests = payload.order.guests;

        state.guestProducts = payload.guestProducts;

        state.transactions = payload.transactions;
        state.currentTransaction = payload.currentTransaction;

        state.disableDiscount = payload.disableDiscount;

        state.products = mapProducts(
          payload.order.products,
          payload.disableDiscount
        );

        state.sharedProducts = mapSharedProducts(
          payload.order.products,
          payload.disableDiscount
        );

        state.fake = payload.order.fake;

        state.modelService = payload.modelService;
        state.paymentModel = payload.paymentModel;

        state.maxService = payload.maxService;

        state.payDisabled = payload.order.payDisabled;

        state.closed = payload?.order.closed;

        state.mapLinks = payload.mapLinks;

        state.loyalSystem = payload.loyalSystem;
        state.loyalUrl = payload.loyalUrl;
        state.menuType = payload.menuType;

        if (payload.rating) {
          state.rating = payload.rating;
        }

        if (payload.comment) {
          state.comment = payload.comment;
        }
        state.restaurantId = payload.order.restaurantId;
      }
    );
    builder.addMatcher(
      esApi.endpoints.getPayments.matchFulfilled,
      (state, { payload }: PayloadAction<Payments>) => {
        payload.payments.forEach((t) => updateTransactions(t));

        if (payload.rating) {
          state.rating = payload.rating;
        }

        if (payload.comment) {
          state.comment = payload.comment;
        }
      }
    );
    builder.addMatcher(
      esApi.endpoints.comment.matchFulfilled,
      (state, { payload }: PayloadAction<Comment>) => {
        if (payload.stars) {
          state.rating = payload.stars;
        }

        if (payload.comment) {
          state.comment = payload.comment;
        }
      }
    );
  },
});

export const {
  setProductsSum,
  setTipsSum,
  setTotal,
  addNewGuest,
  updateGuestProducts,
  updateTransactions,
  updateOrder,
  setRating,
  setComment,
} = orderSlice.actions;

export default orderSlice.reducer;
