import Svg, { SvgProps, Path, G, Defs, ClipPath } from "react-native-svg";
export const YaMapsIcon = (props: SvgProps) => (
  <Svg width={79} height={27} fill="none" {...props}>
    <Path
      fill="#F43"
      d="M13 3.833a8.25 8.25 0 0 0-5.835 14.083c1.493 1.494 5.01 3.655 5.216 5.924.031.34.277.619.619.619.341 0 .588-.28.619-.62.206-2.268 3.723-4.43 5.216-5.923A8.25 8.25 0 0 0 13 3.834Z"
    />
    <Path
      fill="#fff"
      d="M13 14.971a2.887 2.887 0 1 0 0-5.775 2.887 2.887 0 0 0 0 5.775Z"
    />
    <G clipPath="url(#a)">
      <Path
        fill="#000"
        d="M74.564 18.794c.942 0 1.61-.162 2.106-.51v-1.582c-.51.336-1.125.548-1.988.548-1.452 0-2.053-1.071-2.053-2.765 0-1.78.732-2.69 2.066-2.69.785 0 1.557.262 1.962.511v-1.644c-.431-.224-1.177-.386-2.197-.386-2.603 0-3.95 1.781-3.95 4.272.013 2.727 1.334 4.246 4.054 4.246Zm-12.725-.722V16.49c-.628.411-1.7.772-2.682.772-1.49 0-2.053-.672-2.145-2.042h4.918v-1.021c0-2.852-1.32-3.923-3.348-3.923-2.485 0-3.662 1.806-3.662 4.284 0 2.852 1.478 4.234 4.067 4.234 1.295 0 2.25-.336 2.852-.722Zm-18.664-7.646v3.237h-2.72v-3.237h-2.04v8.206h2.04v-3.424h2.72v3.424h2.04v-8.206h-2.04Zm11.235 6.662h-.903v-6.662H47.57v.697c0 2.005-.13 4.595-.85 5.965h-.654V20.5h1.884v-1.868h4.564V20.5h1.884v-3.412h.013Zm13.929 1.544h2.315l-3.27-4.42 2.877-3.786h-2.053l-2.877 3.785v-3.785h-2.04v8.206h2.04v-4.035l3.008 4.035Zm-9.81-6.837c1.008 0 1.322.797 1.322 1.831v.162h-2.839c.053-1.308.55-1.992 1.518-1.992Zm-7.062 5.293H48.59c.562-1.233.719-3.474.719-4.894v-.25h2.158v5.144Z"
      />
      <Path
        fill="#FC3F1D"
        d="M36.714 18.632h-2.08v-9.8h-.929c-1.7 0-2.59.81-2.59 2.017 0 1.37.616 2.005 1.884 2.814l1.046.673-3.008 4.296h-2.236l2.707-3.836c-1.556-1.058-2.433-2.091-2.433-3.835 0-2.18 1.596-3.661 4.617-3.661H36.7l.014 11.332Z"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M28.8 6.9h48v13.2h-48z" />
      </ClipPath>
    </Defs>
  </Svg>
);
