import Tracker from "@openreplay/tracker";
import { NavigationContainer } from "@react-navigation/native";
import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { useEffect, useState } from "react";
import { Platform } from "react-native";
import Toast from "react-native-toast-message";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import { bootstrap } from "./src/bootstrap";
import { HintBanner } from "./src/components/ui/HintBanner";
import NotificationToast from "./src/components/ui/NotificationToast";
import { AppNavigation } from "./src/navigation/AppNavigation";
import { SplashScreen } from "./src/screens/SplashScreen";
import store from "./src/store";
import { closeSplashScreen } from "./src/utils/helpers";

// Should I really disable sentry on localhost?
if (window.location.host.indexOf("localhost") < 0) {
  Sentry.init({
    dsn: "https://9fa905af3fd14d779848dba91e57902b@sentry.cpx.ru/5",
    integrations: [
      new BrowserTracing({
        tracingOrigins: ["6db3e1795ff146d38685a1ed2725f9ad@sentry.cpx.ru"],
      }),
      new CaptureConsole({
        levels: ["error"],
      }),
    ],

    tracesSampleRate: 1.0,
  });
}

// eslint-disable-next-line no-var, prettier/prettier
declare global { var tracker: Tracker }

try {
  global.tracker = new Tracker({
    projectKey: "ywFZwec82hLZstMPWdKX",
    network: {
      sessionTokenHeader: false,
      failuresOnly: false,
      ignoreHeaders: false,
      captureInIframes: true,
      capturePayload: true,
    },
    ingestPoint: "https://orpl.eatandsplit.ru/ingest",
    __DISABLE_SECURE_MODE: true,
  });
  global.tracker.start();
} catch (e) {
  console.log(e);
}

export default function App() {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    async function prepare() {
      try {
        await bootstrap();
      } catch (e) {
        console.warn(e);
      } finally {
        setIsReady(true);
      }
    }
    prepare();
    closeSplashScreen();
  }, []);

  if (!isReady) {
    if (Platform.OS === "web") {
      return <SplashScreen />;
    } else {
      return null;
    }
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistStore(store)}>
        <NavigationContainer>
          <AppNavigation />
        </NavigationContainer>
        <Toast
          config={{
            vpn: () => (
              <NotificationToast
                type="vpn"
                title="Включен VPN"
                content="Для успешной оплаты отключите VPN"
              />
            ),
            error: ({ text1, text2 }) => (
              <NotificationToast type="error" title={text1} content={text2} />
            ),
            feedback: () => (
              <NotificationToast
                type="feedback"
                title="Ваш отзыв скопирован!"
                content="Переходим на страницу заведения"
              />
            ),
            timer: ({ props }) => (
              <NotificationToast
                type="timer"
                title="Блюдо в процессе оплаты"
                {...props}
              />
            ),
            hint: ({ hide }) => <HintBanner onClose={() => hide()} />,
          }}
        />
      </PersistGate>
    </Provider>
  );
}
