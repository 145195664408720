import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { StyleSheet, View } from "react-native";
import { unMask } from "react-native-mask-text";

import { useAppDispatch, useAppSelector } from "../../store";
import esApi from "../../store/esApi";
import {
  setLoyaltyCredentials,
  setLoyaltyLogin,
} from "../../store/paymentSlice";
import { ThemeColor } from "../../theme";
import { getSearch } from "../../utils/useHelper";
import { AppButton } from "../ui/AppButton";
import { AppInput } from "../ui/AppInput";
import { AppText } from "../ui/AppText";
import { UserNotFoundChip } from "../ui/UserNotFoundChip";

interface FormData {
  loyalParam1: string;
  loyalParam2?: string;
}

type Step = "phone" | "code";

interface PremiumBonusProps {
  onVisibleChange: (visible: boolean) => void;
}

const PremiumBonus: React.FC<PremiumBonusProps> = ({ onVisibleChange }) => {
  const {
    control,
    handleSubmit,
    resetField,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onTouched",
    defaultValues: {
      loyalParam1: "",
      loyalParam2: "",
    },
  });

  const [step, setStep] = useState<Step>("phone");

  const [checkLoyalty, { isLoading, data, isError }] =
    esApi.useCheckLoyaltyMutation();

  return (
    <View style={styles.form}>
      <AppText
        style={{ alignSelf: "start" }}
        weight={600}
        size={20}
        height={30}
      >
        Войти
      </AppText>
      {step === "phone" ? (
        <>
          {(data?.found === false || isError) && <UserNotFoundChip />}
          <Controller
            control={control}
            rules={{
              required: "Укажите номер телефона",
            }}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <AppInput
                key="input-loyalty-code"
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                label="Телефон"
                mask="+7 (999) 999 99 99"
                placeholder="+7 (999) 999 99 99"
                style={{ width: "100%" }}
                error={errors?.loyalParam1?.message}
                reset={() => resetField(name)}
              />
            )}
            name="loyalParam1"
          />
          <AppButton
            disabled={isLoading}
            onPress={handleSubmit(({ loyalParam1 }) =>
              checkLoyalty({
                order: getSearch(),
                loyalParam1: unMask(loyalParam1),
              })
                .unwrap()
                .then(({ found }) => {
                  if (found) {
                    setStep("code");
                  }
                })
            )}
            size="large"
            style={{ width: "100%" }}
          >
            Получить код
          </AppButton>
        </>
      ) : (
        <CodeForm
          onVisibleChange={onVisibleChange}
          phone={getValues("loyalParam1")}
        />
      )}
    </View>
  );
};

export default PremiumBonus;

interface CodeFormProps {
  phone: string;
  onVisibleChange: (visible: boolean) => void;
}

const CodeForm = ({ phone, onVisibleChange }: CodeFormProps) => {
  const {
    control,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onTouched",
    defaultValues: {
      loyalParam2: "",
    },
  });

  const [checkLoyalty, { isLoading, data, isError }] =
    esApi.useCheckLoyaltyMutation();

  const { restaurantId } = useAppSelector((state) => state.order);

  const dispatch = useAppDispatch();

  return (
    <>
      {(data?.found === false || isError) && <UserNotFoundChip />}
      <Controller
        control={control}
        rules={{
          required: "Укажите код из смс",
        }}
        render={({ field: { onChange, onBlur, value, name } }) => (
          <AppInput
            key="input-loyalty-code"
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            label="Код из смс"
            mask="99999999"
            placeholder="xxx"
            style={{ width: "100%" }}
            error={errors?.loyalParam2?.message}
            reset={() => resetField(name)}
          />
        )}
        name="loyalParam2"
      />
      <AppButton
        disabled={isLoading}
        onPress={handleSubmit(({ loyalParam2 }) =>
          checkLoyalty({
            order: getSearch(),
            loyalParam1: unMask(phone),
            loyalParam2,
          })
            .unwrap()
            .then(({ found, intId }) => {
              if (found) {
                dispatch(
                  setLoyaltyCredentials({
                    loyalty: "PremiumBonus",
                    id: intId,
                    rest: restaurantId,
                  })
                );
                dispatch(setLoyaltyLogin(true));
                onVisibleChange(false);
              }
            })
        )}
        size="large"
        style={{ width: "100%" }}
      >
        Далее
      </AppButton>
    </>
  );
};

const styles = StyleSheet.create({
  modal: {
    margin: 0,
    justifyContent: "flex-end",
  },
  content: {
    width: "100%",
    maxHeight: "100%",
    borderTopLeftRadius: 42,
    borderTopRightRadius: 42,
    padding: 16,
    paddingTop: 32,
    position: "relative",
    backgroundColor: ThemeColor.WHITE,
  },
  closeBtn: {
    position: "absolute",
    top: 16,
    right: 16,
  },
  form: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: 14,
  },
  cardImg: {
    overflow: "hidden",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    height: 100,
    width: 400,
    position: "absolute",
    top: -100,
    zIndex: 100,
  },
});
