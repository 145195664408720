import { Image, StyleSheet, View } from "react-native";

export const SplashScreen = () => (
  <View style={styles.container}>
    <Image
      style={styles.logo}
      source={require("../../assets/img/logo-splash.svg")}
    />
    <Image
      style={styles.background}
      source={require("../../assets/img/bg-splash.svg")}
    />
    <View style={styles.textWrap}>
      <Image
        style={styles.text}
        source={require("../../assets/img/eat-and-split.svg")}
      />
    </View>
  </View>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  logo: {
    width: 120,
    height: 111,
  },
  background: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "55vw",
  },
  textWrap: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: 100,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    width: 104,
    height: 21,
  },
});
