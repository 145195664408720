import {
  Image,
  ImageSourcePropType,
  StyleSheet,
  Text,
  View,
} from "react-native";

import { ThemeColor, ThemeFont } from "../../theme";

type Props = {
  src: ImageSourcePropType;
  title: string;
  text?: string | JSX.Element;
  extra?: string | JSX.Element;
  style?: StyleSheet.NamedStyles<unknown>;
};

export const SectionHeader = ({ src, title, text, extra, style }: Props) => {
  return (
    <View style={[styles.container, style]}>
      <View style={styles.imageBox}>
        <Image style={styles.image} source={src} />
      </View>
      <View style={styles.infoBox}>
        <Text style={styles.title}>{title}</Text>
        {text && <Text style={styles.subtitle}>{text}</Text>}
      </View>
      {extra && (
        <View style={styles.extra}>
          <Text style={styles.extraText}>{extra}</Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  imageBox: {
    width: 36,
    height: 42,
    borderRadius: 12,
    backgroundColor: ThemeColor.PINK_WHITE,
    marginRight: 12,
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: 17.71,
    height: 17.08,
  },
  infoBox: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
  title: {
    color: ThemeColor.BLACK,
    fontFamily: ThemeFont.SEMIBOLD,
    fontSize: 16,
    lineHeight: 20,
    marginBottom: 3,
    letterSpacing: 0.5,
  },
  subtitle: {
    color: ThemeColor.BLACK,
    fontFamily: ThemeFont.REGULAR,
    fontSize: 14,
    lineHeight: 21,
    letterSpacing: 0.5,
  },
  extra: {
    justifyContent: "flex-end",
  },
  extraText: {
    fontFamily: ThemeFont.SEMIBOLD,
    fontSize: 18,
  },
});
