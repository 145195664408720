import {
  Image,
  ImageBackground,
  ImageURISource,
  StyleSheet,
  View,
} from "react-native";

import { AppText } from "./ui/AppText";
import { BlurButton } from "./ui/BlurButton";
import { ThemeColor } from "../theme";
import { baseUrl, baseStaticUrl } from "../utils/helpers";

type Props = {
  background?: string;
  logo?: string;
  name?: string;
};

const strToUri = (url: string): ImageURISource => ({
  uri: url.startsWith("/image")
    ? baseStaticUrl + url
    : baseUrl + `../restaurants/${url}`,
});

export const Header = ({ background, logo, name }: Props) => {
  const sourceBg = background
    ? strToUri(
        background.startsWith("/image")
          ? background
          : "get-background/" + background
      )
    : require("../../assets/img/default-restaurant-background.svg");

  const sourceLogo = logo
    ? strToUri(logo.startsWith("/image") ? logo : "get-logo/" + logo)
    : strToUri("");

  return (
    <ImageBackground style={styles.container} source={sourceBg}>
      <View style={styles.topPanel}>
        <BlurButton>
          <Image
            style={styles.logoImg}
            source={require("../../assets/img/logo.svg")}
          />
        </BlurButton>
        {/*<BlurButton>*/}
        {/*  <Image style={styles.userImg} source={require('../../assets/img/user.svg')} />*/}
        {/*</BlurButton>*/}
      </View>
      <View style={styles.bottomPanel}>
        <View style={styles.restaurant}>
          {logo ? (
            <Image
              style={styles.restaurantImg}
              resizeMode="contain"
              source={sourceLogo}
            />
          ) : (
            <AppText height={21}>{name}</AppText>
          )}
        </View>
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 228,
    width: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: 12,
  },
  topPanel: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingRight: 24,
    paddingLeft: 16,
  },
  bottomPanel: {
    backgroundColor: ThemeColor.GREY,
    height: 38,
    borderTopLeftRadius: 38,
    borderTopRightRadius: 38,
    marginBottom: -1, // TODO: why it so?
    alignItems: "center",
  },
  logoImg: {
    height: 23,
    width: 95,
    marginHorizontal: 20,
  },
  userImg: {
    height: 15,
    width: 15,
  },
  restaurant: {
    backgroundColor: ThemeColor.WHITE,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: ThemeColor.GREY_3,
    borderRadius: 20,
    paddingHorizontal: 22,
    paddingTop: 8,
    paddingBottom: 12,
    marginTop: -32,
    minWidth: 129,
    minHeight: 62,
    justifyContent: "center",
    alignItems: "center",
  },
  restaurantImg: {
    width: "100%",
    height: "100%",
  },
});
