import { BlurView } from "expo-blur";
import { PropsWithChildren } from "react";
import { StyleSheet } from "react-native";

import { ThemeColor } from "../../theme";

export const BlurButton = ({ children }: PropsWithChildren) => (
  <BlurView style={styles.container} intensity={50} tint="light">
    {children}
  </BlurView>
);

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    minWidth: 40,
    borderRadius: 20,
    backgroundColor: ThemeColor.WHITE,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: ThemeColor.GREY_3,
  },
});
