import { useState, useEffect, useRef } from "react";
import {
  Dimensions,
  KeyboardAvoidingView,
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";

import { Header } from "../components/Header";
import Tapbar from "../components/Tapbar";
import WaiterCallButton from "../components/WaiterCallButton";
import { MenuCategoriesHeader } from "../components/ui/MenuCategoriesHeader";
import { MenuCategoryInList } from "../components/ui/MenuCategoryInList";
import { MenuProductView } from "../components/ui/MenuProductView";
import { MenuSearch } from "../components/ui/MenuSearch";
import { useAppDispatch, useAppSelector } from "../store";
import esApi from "../store/esApi";
import { setCategories } from "../store/menuSlice";
import { ThemeColor } from "../theme";
import { MenuCategory, MenuProduct } from "../types/common";
import { useHelper } from "../utils/useHelper";

interface CategoryShift {
  [key: number]: number;
}

export const MenuScreen = () => {
  const dispatch = useAppDispatch();

  const { getSearch } = useHelper();
  const { searchText, categories } = useAppSelector((state) => state.menu);

  const { data: orderSession } = esApi.endpoints.getOrder.useQueryState({
    order: getSearch(),
    reset: true,
  });

  const { data } = esApi.useGetMenuQuery({
    restaurant: orderSession?.order.restaurantId as number,
  });

  const [scrollActive] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(
    null as unknown as MenuProduct
  );
  const [showProductInfo, setShowProductInfo] = useState(false);
  const [categoryShifts, setCategoryShifts] = useState({} as CategoryShift);
  const [categorySelfShifts, setCategorySelfShifts] = useState(
    {} as CategoryShift
  );
  const [categorySelfShiftsSorted, setCategorySelfShiftsSorted] = useState(
    [] as number[]
  );
  const [activeCategoryId, setActiveCategoryId] = useState(0);
  const [scrolling, setScrolling] = useState(false);
  const [scrollToPosition, setScrollToPosition] = useState(0);

  const [heightOfEmptyView, setHeightOfEmptyView] = useState(0);
  const [firstElementShift, setFirstElementShift] = useState(0);
  const [filteredCategories, setFilteredCategories] = useState(
    [] as MenuCategory[]
  );
  const [filterRevision, setFilterRevision] = useState(0);

  const categoriesScrollViewRef = useRef<ScrollView>(null);
  const itemsScrollView = useRef<ScrollView>(null);

  const catScrollStart = 40;
  // it will eventually break
  const categoriesHeight = 68;

  const listRef = useRef<null | View>(null);

  const scrollBeforeMove = firstElementShift - categoriesHeight;

  const filterProducts = () => {
    const search = searchText ?? "";
    const fCat = [] as MenuCategory[];
    if ((categories ?? []).length > 0) {
      categories.map((c) => {
        const prod = [] as MenuProduct[];
        const allProd = c.name
          .toLowerCase()
          .includes(search.toLocaleLowerCase());
        if (c.products.length > 0) {
          c.products.map((p) => {
            if (
              allProd ||
              p.name.toLowerCase().includes(search.toLocaleLowerCase()) ||
              p.description.toLowerCase().includes(search.toLocaleLowerCase())
            ) {
              prod.push(p);
            }
          });
        }
        if (prod.length > 0) {
          const cc = JSON.parse(JSON.stringify(c)) as MenuCategory;
          cc.products = prod;
          fCat.push(cc);
        }
      });
    }

    setFilteredCategories(fCat);
    setFilterRevision(filterRevision + 1);
  };

  useEffect(() => {
    setCategoryShifts({} as CategoryShift);
    setCategorySelfShifts({} as CategoryShift);
  }, [searchText]);

  useEffect(() => {
    filterProducts();
  }, [categories, searchText]);

  const productChoosen = (prod: MenuProduct) => {
    setSelectedProduct(prod);
    setShowProductInfo(true);
  };

  const onRenderedCatFirstProductItem = (
    categoryId: number | undefined,
    y: number
  ) => {
    if (categoryId !== undefined) {
      const newShifts = categoryShifts;
      newShifts[categoryId] = y - firstElementShift;
      setCategoryShifts(newShifts);
    }
  };

  const onRenderedCatItem = (categoryId: number | undefined, y: number) => {
    if (categoryId !== undefined) {
      const newShifts = categorySelfShifts;
      newShifts[categoryId] = y;
      setCategorySelfShifts(newShifts);

      const sortedIndexes = Object.keys(newShifts).sort((a, b) => {
        const ai = parseInt(a, 10),
          bi = parseInt(b, 10);
        return newShifts[ai] > newShifts[bi]
          ? 1
          : newShifts[ai] < newShifts[bi]
          ? -1
          : 0;
      });
      const numberIndexes = sortedIndexes.map((a) => parseInt(a, 10));
      setCategorySelfShiftsSorted(numberIndexes);
    }
  };

  const onCategoryPress = (id: number) => {
    if (!scrolling) {
      markCategoryAsActive(id);

      setScrollToPosition(categoryShifts[id] + scrollBeforeMove);
      setScrolling(true);
      // workaround - timer to setScrolling(false)
      setTimeout(() => {
        setScrolling(false);
      }, 700);
    }
    // itemsScrollView.current?.scrollTo({x:0, y: categoryShifts[id] + scrollBeforeMove, animated: true});
  };

  useEffect(() => {
    if (scrolling) {
      itemsScrollView.current?.scrollTo({
        x: 0,
        y: scrollToPosition,
        animated: true,
      });
    }
  }, [scrolling]);

  const markCategoryAsActive = (id: number) => {
    let newCats = JSON.parse(JSON.stringify(filteredCategories));
    newCats = newCats.map((i: MenuCategory) => {
      i.isActive = i.id === id;
      return i;
    });
    setFilteredCategories(newCats);

    let newCats2 = JSON.parse(JSON.stringify(categories));
    newCats2 = newCats2.map((i: MenuCategory) => {
      i.isActive = i.id === id;
      return i;
    });
    setCategories(newCats2);
  };

  const setActiveCategory = (id: number, andScroll: boolean) => {
    setActiveCategoryId(id);
    markCategoryAsActive(id);

    if (andScroll) {
      categoriesScrollViewRef.current?.scrollTo({
        x: categorySelfShifts[id] - catScrollStart,
        y: 0,
        animated: true,
      });
    }
  };

  const onScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    const yScroll = e.nativeEvent.contentOffset.y;

    if (!scrolling || Math.floor(yScroll) === Math.floor(scrollToPosition)) {
      setScrollToPosition(-1000);
      setScrolling(false);

      if (yScroll <= scrollBeforeMove) {
        const catId = filteredCategories[0].id;
        if (activeCategoryId !== catId) {
          setActiveCategory(catId, true);
        }
      } else {
        for (let i = categorySelfShiftsSorted.length - 1; i >= 0; i--) {
          const catId = categorySelfShiftsSorted[i];
          if (yScroll - scrollBeforeMove >= categoryShifts[catId]) {
            if (activeCategoryId !== catId) {
              setActiveCategory(catId, true);
            }
            break;
          }
        }
      }
    }
  };

  return (
    <KeyboardAvoidingView behavior="padding" style={styles.container}>
      <ScrollView
        ref={itemsScrollView}
        contentContainerStyle={{ minHeight: "100%" }}
        scrollEnabled={scrollActive}
        stickyHeaderIndices={[3]}
        onScroll={onScroll}
        scrollEventThrottle={16}
      >
        <Header
          background={orderSession?.order.restaurantBackground}
          logo={orderSession?.order.restaurantLogo}
          name={orderSession?.order.restaurantName}
        />
        <MenuSearch />
        <View style={{ height: 12, backgroundColor: ThemeColor.GREY }} />
        <MenuCategoriesHeader
          items={filteredCategories ?? []}
          onCategoryPress={onCategoryPress}
          scrollRef={categoriesScrollViewRef}
          onLayout={(cat, y) => {
            onRenderedCatItem(cat, y);
          }}
          key={"hcat-" + filterRevision}
        />
        <View
          ref={listRef}
          style={styles.productsViewContainer}
          onLayout={(event) => {
            listRef.current?.measureInWindow((x, y, w, h) => {
              setFirstElementShift(y);
            });
          }}
        >
          {filteredCategories.map((cat, index) => {
            return (
              <MenuCategoryInList
                key={"cat-list-" + cat.id + "-rnd-" + filterRevision}
                item={cat}
                productChoosen={productChoosen}
                onLayout={(catEventId, y, h) => {
                  if (index === 0) {
                    onRenderedCatFirstProductItem(catEventId, 0);
                  } else {
                    onRenderedCatFirstProductItem(catEventId, y);
                    if (index === filteredCategories.length - 1) {
                      const lastFillingViewHeight =
                        Dimensions.get("window").height -
                        h -
                        categoriesHeight +
                        5;
                      setHeightOfEmptyView(
                        lastFillingViewHeight > 0 ? lastFillingViewHeight : 0
                      );
                    }
                  }
                }}
              />
            );
          })}
          <View style={{ height: heightOfEmptyView }} />
        </View>
      </ScrollView>
      {showProductInfo && (
        <MenuProductView
          item={selectedProduct}
          visible={showProductInfo}
          onVisibleChange={setShowProductInfo}
        />
      )}
      {orderSession?.waiterCallType &&
        orderSession?.waiterCallType !== "NONE" && (
          <WaiterCallButton style={{ bottom: 100 }} />
        )}
      <Tapbar />
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: "100vw",
    maxHeight: "100svh",
    backgroundColor: "trasparent",
  },
  navigation: {
    flexGrow: 1,
    paddingBottom: 20,
  },
  productsViewContainer: {
    backgroundColor: ThemeColor.GREY,
    paddingHorizontal: 16,
    display: "flex",
    flexDirection: "column",
  },
});
