import {
  KeyboardTypeOptions,
  StyleSheet,
  TextInput,
  View,
  ViewStyle,
  TextInputProps,
} from "react-native";
import { MaskedTextInput } from "react-native-mask-text";

import { AppText } from "./AppText";
import { CloseButton } from "./CloseButton";
import { ThemeColor, ThemeFont } from "../../theme";
import { AutoComplete } from "../../types/common";

interface Props extends TextInputProps {
  style?: ViewStyle;
  styleInput?: ViewStyle;
  label?: string;
  initValue?: string;
  mask?: string;
  placeholder?: string;
  error?: string;
  keyboardType?: KeyboardTypeOptions;
  onChangeText: (text: string) => void;
  reset?: () => void;
  autocomplete?: AutoComplete;
}

export const AppInput = ({
  style,
  styleInput,
  label,
  mask,
  value,
  onBlur,
  onChangeText,
  error,
  placeholder,
  keyboardType = "numeric",
  reset,
  autocomplete,
  secureTextEntry,
  editable,
}: Props) => {
  return (
    <View
      style={[
        styles.container,
        style,
        !!error && { borderColor: ThemeColor.RED_2 },
      ]}
    >
      {label && !error && (
        <AppText
          style={styles.label}
          size={12}
          height={18}
          color={ThemeColor.GREY_4}
        >
          {label}
        </AppText>
      )}
      {!!error && (
        <AppText
          style={styles.label}
          size={12}
          height={18}
          color={ThemeColor.RED_2}
        >
          {error}
        </AppText>
      )}
      {mask ? (
        <MaskedTextInput
          editable={editable}
          autoComplete={autocomplete}
          style={styles.input}
          value={value}
          onChangeText={onChangeText}
          onBlur={onBlur}
          mask={mask}
          placeholder={placeholder}
          placeholderTextColor={ThemeColor.GREY_4}
          keyboardType={keyboardType}
          secureTextEntry={secureTextEntry}
        />
      ) : (
        <TextInput
          editable={editable}
          secureTextEntry={secureTextEntry}
          autoComplete={autocomplete}
          style={[styles.input, styleInput]}
          value={value}
          onChangeText={onChangeText}
          onBlur={onBlur}
          placeholder={placeholder}
          placeholderTextColor={ThemeColor.GREY_4}
          keyboardType={keyboardType}
        />
      )}

      {!!error && reset && (
        <CloseButton style={styles.clearBtn} onPress={() => reset()} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 16,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: ThemeColor.GREY_3,
    position: "relative",
  },
  label: {
    marginBottom: 4,
  },
  input: {
    height: 19,
    fontFamily: ThemeFont.MEDIUM,
    color: ThemeColor.BLACK,
    fontSize: 16,
    lineHeight: 19,
    outlineStyle: "none",
  },
  clearBtn: {
    position: "absolute",
    right: 20,
    bottom: 15,
  },
});
