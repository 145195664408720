import * as React from "react";
import Svg, { SvgProps, Circle, Path } from "react-native-svg";
const GuestIcon = (props: SvgProps) => (
  <Svg width={36} height={36} fill="none" {...props}>
    <Circle cx={18} cy={18} r={18} fill="#F7F7F7" />
    <Path
      stroke="#1E1E1E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M20.75 14.5c-.123 1.652-1.375 3-2.75 3s-2.63-1.348-2.75-3c-.125-1.719 1.094-3 2.75-3s2.875 1.313 2.75 3Z"
    />
    <Path
      stroke="#1E1E1E"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M18 19.5c-2.719 0-5.478 1.5-5.989 4.331-.061.341.132.669.489.669h11c.358 0 .55-.328.489-.669C23.479 21 20.719 19.5 18 19.5Z"
    />
  </Svg>
);
export default GuestIcon;
