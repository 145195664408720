import { Image, StyleSheet, View, ViewStyle } from "react-native";

import { AppText } from "./AppText";
import { UserCheck } from "../../screens/PostPaymentScreen";
import { useAppSelector } from "../../store";
import { ThemeColor } from "../../theme";
import { currencyFormat } from "../../utils/currency-format";

export type PaymentStatus = "paid" | "inProgress";
type StatusProps = {
  color: ThemeColor;
  text: string;
};
type PaymentStatusMap = Record<PaymentStatus, StatusProps>;

interface PostPayUserCardProps {
  user: UserCheck | null;
  owner?: boolean;
  style?: ViewStyle;
  isTipsOnly?: boolean;
}

const STATUS_MAP: PaymentStatusMap = {
  paid: {
    color: ThemeColor.GREEN,
    text: "Оплачено",
  },
  inProgress: {
    color: ThemeColor.BROWN,
    text: "Оплачивает",
  },
};

export const PostPayUserCard: React.FC<PostPayUserCardProps> = ({
  user,
  owner = false,
  style,
  isTipsOnly,
}) => {
  const { tips } = useAppSelector((state) => state.order.currentTransaction);

  if (!user) {
    return <></>;
  }

  return (
    <View style={[styles.container, style]}>
      <Image style={styles.image} source={user.image} />
      <View style={styles.textWrap}>
        {/* <AppText height={21}>{owner ? `Вы (${user.name})` : user.name}</AppText> */}
        <AppText size={14} height={18} color={STATUS_MAP[user.status].color}>
          {STATUS_MAP[user.status].text}
        </AppText>
      </View>
      <AppText weight={600} height={17}>
        {currencyFormat(
          owner && isTipsOnly ? tips : owner ? user.sum : user.sum + user.bonus
        )}{" "}
        {user.bonus > 0 && owner && `+ ${user.bonus} Бонусов`}
      </AppText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: ThemeColor.WHITE,
    borderRadius: 20,
    paddingVertical: 12,
    paddingHorizontal: 20,
    marginHorizontal: 8,
    shadowColor: ThemeColor.SHADOW,
    shadowOpacity: 0.05,
    shadowRadius: 10,
    shadowOffset: {
      width: 2,
      height: 2,
    },
  },
  image: {
    width: 40,
    height: 40,
    marginRight: 12,
  },
  textWrap: {
    flex: 1,
  },
});
