import { useState, useEffect } from "react";
import { Image, StyleSheet, Text, TextInput, View } from "react-native";

import { useAppDispatch, useAppSelector } from "../../store";
import { setComment } from "../../store/orderSlice";
import { ThemeColor, ThemeFont } from "../../theme";

const MAX_TEXT_LENGTH = 150;

interface Props {
  editable?: boolean;
}

export const FeedbackComment = ({ editable = true }: Props) => {
  const [active, setActive] = useState(false);
  const [text, setText] = useState("");

  const dispatch = useAppDispatch();
  const { comment } = useAppSelector((state) => state.order);

  const handleFocus = () => setActive(true);
  const handleBlur = () => {
    if (text.length <= 0) {
      setActive(false);
    }
    dispatch(setComment(text));
  };

  const handleChangeText = (value: string) => {
    setText(value);
  };

  useEffect(() => {
    setText(comment);
    setActive(true);
  }, []);

  return (
    <View>
      <View style={styles.container}>
        <TextInput
          editable={editable}
          style={styles.input}
          keyboardType="default"
          multiline
          maxLength={MAX_TEXT_LENGTH}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={text}
          onChangeText={handleChangeText}
        />
        {!active && (
          <View style={styles.placeholder}>
            <Image
              style={styles.placeholderImg}
              source={require("../../../assets/img/pen_grey.svg")}
            />
            <Text style={styles.placeholderText}>Оставьте Ваш отзыв</Text>
          </View>
        )}

        {editable && (
          <Text style={styles.counter}>
            {text.length}/{MAX_TEXT_LENGTH}
          </Text>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: ThemeColor.GREY_2,
    borderRadius: 20,
    minHeight: 140,
    padding: 16,
    position: "relative",
  },
  placeholder: {
    flex: 1,
    flexDirection: "row",
    position: "absolute",
    top: 18,
    pointerEvents: "none",
  },
  placeholderImg: {
    width: 12,
    height: 12,
    marginRight: 6,
    marginTop: 2,
  },
  placeholderText: {
    fontFamily: ThemeFont.MEDIUM,
    color: ThemeColor.GREY_4,
  },
  input: {
    flex: 1,
    marginBottom: 12,
    outlineStyle: "none",
    fontFamily: ThemeFont.REGULAR,
    color: ThemeColor.BLACK,
    lineHeight: 21,
  },
  counter: {
    fontFamily: ThemeFont.MEDIUM,
    fontSize: 12,
    color: ThemeColor.BLACK,
    textAlign: "right",
  },
});
