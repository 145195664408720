import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";
const MenuIcon = (props: SvgProps) => (
  <Svg width={22} height={19} fill="none" {...props}>
    <Path
      stroke={props.stroke || "#fff"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11 5.714c.686-2.707 3.275-4.089 8.914-4.114a.683.683 0 0 1 .686.686v12.343a.686.686 0 0 1-.686.685c-5.486 0-7.605 1.106-8.914 2.743m0-12.343C10.314 3.007 7.724 1.625 2.086 1.6a.683.683 0 0 0-.686.686v12.26c0 .423.262.768.686.768 5.485 0 7.612 1.114 8.914 2.743m0-12.343v12.343"
    />
  </Svg>
);
export default MenuIcon;
