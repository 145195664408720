import { PropsWithChildren } from "react";
import { StyleSheet, View } from "react-native";
import Modal from "react-native-modal";
import Toast from "react-native-toast-message";

import { BlockState } from "./Payment";
import NotificationToast from "./ui/NotificationToast";
import { ThemeColor } from "../theme";

type Props = {
  visible: boolean;
  block: BlockState;
};

export const PaymentFullScreenModal = ({
  visible,
  children,
  block,
}: PropsWithChildren<Props>) => {
  return (
    <Modal style={styles.modal} isVisible={visible}>
      <View style={styles.content}>{children}</View>
      <Toast
        config={{
          vpn: () => (
            <NotificationToast
              type="vpn"
              title="Включен VPN"
              content="Для успешной оплаты отключите VPN"
            />
          ),
          error: ({ text1, text2 }) => (
            <NotificationToast type="error" title={text1} content={text2} />
          ),
        }}
      />
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: {
    margin: 0,
    padding: 0,
  },
  content: {
    width: "100%",
    height: "100%",
    backgroundColor: ThemeColor.WHITE,
  },
});
