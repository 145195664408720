import Constants from "expo-constants";

import { Transaction } from "../types/common";

export const paid = (transactions: Transaction[]) =>
  transactions
    .filter((t: Transaction) => t.success)
    .map(
      (t: Transaction) =>
        parseFloat(t.products.toFixed(2)) + parseFloat(t.bonus.toFixed(2))
    )
    .reduce((sum: number, cur: number) => sum + cur, 0);

const start = new Date().getDate();

export function closeSplashScreen() {
  setTimeout(() => {
    const pathWrapper = document.getElementById("path-wrapper");
    const path = document.getElementById("path");

    if (pathWrapper && path) {
      pathWrapper.style.opacity = "0";
      path.style.opacity = "0";
      setTimeout(() => {
        pathWrapper.remove();
      }, 1000);
    }
  }, 1500 - (new Date().getDate() - start));
}

export function luhnCheck(cardNumber: string): boolean {
  const cleanedCardNumber = cardNumber.replace(/\D/g, "");

  const digits = cleanedCardNumber.split("").map(Number);

  if (digits.length <= 1) {
    return false;
  }

  for (let i = digits.length - 2; i >= 0; i -= 2) {
    digits[i] *= 2;

    if (digits[i] > 9) {
      digits[i] -= 9;
    }
  }

  const sum = digits.reduce((acc, digit) => acc + digit, 0);
  return sum % 10 === 0;
}

export const redirectToUUID = ({
  jwt,
  order,
}: {
  jwt: string;
  order: { uuid: string; restaurantId: number; waiterId: number };
}) => {
  let newUrl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    "?u=" +
    order.uuid +
    "&r=" +
    order.restaurantId +
    "&w=" +
    order.waiterId;
  // window.location.href = newurl;
  if (newUrl.indexOf("jwt=") < 0) {
    newUrl += "&jwt=" + jwt;
  }
  window.history.replaceState("", "", newUrl);
};

export const redirectToTableUUID = ({
  jwt,
  order,
}: {
  jwt: string;
  order: { uuid: string; restaurant: { id: number } };
}) => {
  let newUrl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    "?u=" +
    order.uuid +
    "&r=" +
    order.restaurant.id;

  // window.location.href = newurl;
  if (newUrl.indexOf("jwt=") < 0) {
    newUrl += "&jwt=" + jwt;
  }
  console.log({ newUrl });
  window.location.replace(newUrl);
};

export const sleep = (ms: number) =>
  new Promise<void>((resolve) => setTimeout(resolve, ms));

export const baseUrl = Constants.manifest?.extra?.baseUrl;

export const baseStaticUrl = "https://eatandsplit.com";
