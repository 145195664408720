import { useRoute } from "@react-navigation/native";
import { PropsWithChildren } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import Toast from "react-native-toast-message";

import { TwoGisIcon } from "../components/icons/2GisIcon";
import { GMapsIcon } from "../components/icons/GMapsIcon";
import { StarFillIcon } from "../components/icons/StarFillIcon";
import { StarIcon } from "../components/icons/StarIcon";
import { YaMapsIcon } from "../components/icons/YaMapsIcon";
import { AppButton } from "../components/ui/AppButton";
import { AppText } from "../components/ui/AppText";
import { Rating } from "../components/ui/FeedbackBanner";
import { FeedbackComment } from "../components/ui/FeedbackComment";
import { Section } from "../components/ui/Section";
import { SectionHeader } from "../components/ui/SectionHeader";
import { useAppDispatch, useAppSelector } from "../store";
import esApi from "../store/esApi";
import { setRating } from "../store/orderSlice";
import { ThemeColor } from "../theme";
import { sleep } from "../utils/helpers";
import { useHelper } from "../utils/useHelper";

type Props = {
  title: string;
  withSendButton?: boolean;
  readonly?: boolean;
};

export const FeedbackBlock = ({ title, withSendButton, readonly }: Props) => {
  const dispatch = useAppDispatch();

  const route = useRoute();

  const { getSearch } = useHelper();

  const [sendComment, { isLoading, isSuccess }] = esApi.useCommentMutation();

  const { rating, comment, mapLinks } = useAppSelector((state) => state.order);

  return (
    <Section style={styles.section}>
      <SectionHeader
        src={require("../../assets/img/star.svg")}
        title={title}
        text={
          <AppText size={12} space={0.3} color={ThemeColor.GREY_4}>
            Оставьте отзыв и оценку
          </AppText>
        }
      />

      <View style={styles.starsContainer}>
        {[...Array(5)].map((_, index) => (
          <Pressable
            style={styles.starContainer}
            key={index}
            onPress={() => {
              if (!readonly) {
                dispatch(setRating(index + 1 === rating ? 0 : index + 1));
              }
            }}
          >
            {index < rating ? <StarFillIcon /> : <StarIcon />}
          </Pressable>
        ))}
      </View>

      {rating !== Rating.NONE && (
        <View>
          <View style={styles.titleBox}>
            <AppText size={16} weight={600}>
              Спасибо за оценку!
            </AppText>
          </View>
          <FeedbackComment editable={!readonly} />
          {withSendButton && !readonly && (
            <View style={styles.buttonContainer}>
              <AppButton
                disabled={isLoading || isSuccess}
                onPress={() =>
                  sendComment({ order: getSearch(), comment, stars: rating })
                }
              >
                {isSuccess ? "Отправлено" : "Отправить"}
              </AppButton>
            </View>
          )}
          <>
            {rating === 5 &&
              route.name === "PostPayment" &&
              Object.keys(mapLinks).length !== 0 && (
                <View style={styles.titleBox}>
                  <AppText size={16} weight={600}>
                    Поделитесь вашим отзывом
                  </AppText>
                  <View style={styles.mapsContainer}>
                    {mapLinks?.yandex && (
                      <FeedbackLink link={mapLinks?.yandex}>
                        <YaMapsIcon />
                      </FeedbackLink>
                    )}
                    {mapLinks?.["2gis"] && (
                      <FeedbackLink link={mapLinks?.["2gis"]}>
                        <TwoGisIcon />
                      </FeedbackLink>
                    )}
                    {mapLinks?.google && (
                      <FeedbackLink link={mapLinks?.google}>
                        <GMapsIcon />
                      </FeedbackLink>
                    )}
                  </View>
                </View>
              )}
          </>
        </View>
      )}
    </Section>
  );
};

interface FeedbackLinkProps {
  link: string;
}

const FeedbackLink: React.FC<PropsWithChildren<FeedbackLinkProps>> = ({
  link,
  children,
}) => {
  const { comment } = useAppSelector((state) => state.order);

  return (
    <Pressable
      onPress={async () => {
        if (window) {
          Toast.show({
            type: "feedback",
          });
          try {
            await navigator.clipboard.writeText(comment);
          } catch (error) {
            console.error(error);
          }
          await sleep(2000);
          window.open(link, "_self");
        }
      }}
      style={styles.buttonBody}
    >
      {children}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  section: {
    marginBottom: 15,
  },
  titleBox: {
    marginTop: 32,
    marginBottom: 12,
    alignItems: "center",
  },
  subtitle: {
    marginTop: 8,
  },
  starsContainer: {
    marginTop: 24,
    display: "flex",
    flexDirection: "row",
    gap: 22,
    justifyContent: "space-around",
    alignItems: "center",
  },
  starContainer: {
    height: 24,
    flexBasis: 22,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonContainer: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mapsContainer: {
    marginTop: 12,
    display: "flex",
    flexDirection: "row",
    gap: 16,
    alignItems: "center",
  },
  buttonBody: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: ThemeColor.GREY_3,
    borderRadius: 16,
    padding: 10,
    height: 50,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: ThemeColor.SHADOW,
    shadowOpacity: 0.02,
    shadowRadius: 10,
    shadowOffset: {
      width: 2,
      height: 2,
    },
  },
});
